import blueGraphBackgroundImage from "src/assets/svgs/BlueGraphBackground.svg";
import orangeGraphBackgroundImage from "src/assets/svgs/OrangeGraphBackground.svg";
import { Theme } from "src/theme";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(5, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  bullishBackground: {
    backgroundImage: `url(${blueGraphBackgroundImage})`,
  },
  bearishBackground: {
    backgroundImage: `url(${orangeGraphBackgroundImage})`,
  },
  widgetButton: {
    position: "fixed",
    top: "60px",
    zIndex: 1,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("sm")]: {
      left: "50%",
      marginLeft: "-125px",
      marginBottom: "-20px",
    },
    [theme.breakpoints.up("md")]: {
      right: "60px",
    },
    backgroundColor: "#fff",
    color: "#005E87",
  },
  widgetContainer: {
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.up("md")]: {
      maxWidth: "95%",
      position: "fixed",
      top: "60px",
      right: "60px",
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: { marginLeft: theme.spacing(13) },
  },
  titleContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
  },

  divider: { color: "#DBD7D2" },
  topDivider: { marginBottom: theme.spacing(1) },
  bottomDivider: { marginTop: theme.spacing(1) },
  tab: {
    minWidth: 100, // a number of your choice
    width: 100, // a number of your choice
    fontWeight: "bold",
  },
  tabsContainer: {
    paddingTop: theme.spacing(0.5),
    backgroundColor: "#fff",
    marginTop: theme.spacing(2),
    borderRadius: 4,
    overflow: "auto",
  },
  watchListButton: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0.5),
    height: "30px",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
}));

export default useStyles;
