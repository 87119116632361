const cardElementStyle = {
  style: {
    base: {
      fontSize: "16px",
      color: "##808080",
      "::placeholder": {
        color: "#808080",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const containerStyle = {
  border: "1px solid rgba(0, 0, 0, 0.23)",
  padding: "15px 14px",
  marginTop: "4px",
  borderRadius: "4px"
}

export { containerStyle }
export default cardElementStyle;
