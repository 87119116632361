import { FC, useEffect } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import { Theme } from "src/theme";
import { RootState, useDispatch } from "src/store";
import Header from "src/components/Header";
import List from "./List";
import { useSelector } from "src/store";
import useBoolean from "src/hooks/useBoolean";
import { fetchPredictions } from "src/slices/predictions";
import { config } from "src/config";
import JoyrideEntry from "src/components/JoyRide";
import { forexSteps } from "src/constants/joy-ride";
import useForexPredictions from "src/hooks/useForexPredictions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttons: {
    marginRight: 16,
    "& > * + *": {
      marginLeft: 10,
    },
  },
}));

const Forex: FC = () => {
  const classes = useStyles();
  const predictions = useSelector(
    (reduxState: RootState) => reduxState.predictions
  );
  const isListViewSelected = useBoolean(false);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    const baseUrl = config.APP_BASE_API_URL;
    const eventSource = new EventSource(`${baseUrl}/forex-sync/ping`);
    //@ts-ignore
    eventSource.onmessage = (event, data) => {
      dispatchRedux(fetchPredictions());
      return null;
    };
    eventSource.onerror = function () {
      eventSource.close();
    };
  }, [dispatchRedux]);

  const mergedPredictionsWithStocks = useForexPredictions();

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header
          title="Forex"
          crumbs={[
            { crumb: "Deep Sky", link: "/dashboard" },
            { crumb: "Forex" },
          ]}
        />
        <List
          title="Major Currency Pairs"
          isLoading={predictions.forexShortPredictions.isLoading}
          data={mergedPredictionsWithStocks
            .filter((item) => item.type === "major")
            .sort((a, b) => (a.symbol > b.symbol ? 1 : -1))}
          isListViewSelected={isListViewSelected}
        />
        <List
          title="Other Currency Pairs"
          isLoading={predictions.forexShortPredictions.isLoading}
          data={mergedPredictionsWithStocks
            .filter((item) => item.type === "minor")
            .sort((a, b) => (a.symbol > b.symbol ? 1 : -1))}
          isListViewSelected={isListViewSelected}
          onlyList
        />
      </Container>
      <JoyrideEntry steps={forexSteps} id="forex" />
    </Page>
  );
};

export default Forex;
