import React from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import logo from "src/assets/ds-logo-coloured.svg";
import containerStyle from "./helpers/common.style";

import { Grid, makeStyles, Paper, Hidden, Divider } from "@material-ui/core";
interface CrestProps {
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    ...containerStyle,
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  paper: {
    width: 560,
    minHeight: 0,
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "auto",
    background: "#fff",
    padding: theme.spacing(2),
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  logoContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
  logo: {
    width: "100%",
  },
  gridContainer: {
    height: "100%",
  },
  footer: {
    fontSize: 11,
    lineHeight: "16px",
    color: "#808080",
    padding: theme.spacing(1, 0, 0, 0),
  },
}));

const Crest: FC<CrestProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid
          container
          alignItems="stretch"
          direction="row"
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={2} className={classes.logoContainer}>
            <img
              src={logo}
              alt="bv logo"
              className={classes.logo}
              width="71"
              height="88"
            />
          </Grid>
          <Hidden smDown>
            <Grid item md={1}>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
          </Hidden>
          <Grid item md xs={12}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

Crest.propTypes = {
  children: PropTypes.node,
};

export default Crest;
