function CautionIcon(props) {
  return (
    <svg
      width={27}
      height={24}
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.824 24h21.252c2.173 0 3.528-2.357 2.441-4.233L15.891 1.407c-1.086-1.876-3.796-1.876-4.882 0L.383 19.768C-.704 21.643.65 24 2.824 24z"
        fill="#FF8500"
      />
      <rect
        x={12.039}
        y={7.061}
        width={2.822}
        height={9.172}
        rx={1.411}
        fill="#fff"
      />
      <rect
        x={12.039}
        y={17.645}
        width={2.822}
        height={2.822}
        rx={1.411}
        fill="#fff"
      />
    </svg>
  );
}

export default CautionIcon;
