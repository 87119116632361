import logo from "src/assets/svgs/tsu-logo.svg";
export interface TSULogoProps {
  [key: string]: any;
}

const TSULogo = (props) => {
  return <img alt="Trade Smart University Logo" src={logo} {...props} />;
};

export default TSULogo;
