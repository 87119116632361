import Prediction from "src/models/Prediction";

export interface IMarketsData {
  SPY?: Prediction[];
  DIA?: Prediction[];
  QQQ?: Prediction[];
  IJH?: Prediction[];
  IWM?: Prediction[];
  SOXX?: Prediction[];
}

const getMarketsData = (marketPredictions: Prediction[]): IMarketsData => {
  const uniqueSymbols = [
    ...new Set(marketPredictions.map((item) => item.symbol)),
  ];
  const result = {};

  uniqueSymbols.forEach((symbol) => {
    result[symbol] = marketPredictions.filter(
      (prediction) => prediction.symbol === symbol
    );
  });

  return result;
};

export default getMarketsData
