const currencyPairs = [
  "AUD/CAD",
  "AUD/CHF",
  "AUD/CHF",
  "AUD/JPY",
  "AUD/USD",
  "CAD/CHF",
  "CAD/JPY",
  "CHF/JPY",
  "EUR/AUD",
  "EUR/CAD",
  "EUR/CHF",
  "EUR/GBP",
  "EUR/JPY",
  "EUR/NZD",
  "EUR/USD",
  "GBP/AUD",
  "GBP/CAD",
  "GBP/CHF",
  "GBP/JPY",
  "GBP/NZD",
  "GBP/USD",
  "NZD/CAD",
  "NZD/CHF",
  "NZD/JPY",
  "NZD/USD",
  "USD/CAD",
  "USD/CHF",
  "USD/JPY",
];

export default currencyPairs;
