import React from "react";
import type { FC } from "react";
import TSULogin from "./TSULogin";
import Crest from "../Crest";

const TSULoginView: FC = () => {
  return (
    <Crest>
      <TSULogin />
    </Crest>
  );
};

export default TSULoginView;
