import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Radio, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    marginLeft: "30px",
  },
}));
const RadioButtonWithDetails = ({
  title = "",
  label = "",
  checked,
  onChange,
  value = "",
}) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          checked={checked}
          onChange={onChange}
          value={value}
          control={<Radio />}
          label={label}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default RadioButtonWithDetails;
