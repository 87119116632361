import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
}));

const ForexIcon = (props) => {
  const classes = useStyles();
  return (
    <SvgIcon className={classes.icon}>
      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15 6.5V9H11V11H15V13.5L18.5 10L15 6.5ZM9 10.5L5.5 14L9 17.5V15H13V13H9V10.5Z" />
    </SvgIcon>
  );
};

export default ForexIcon;
