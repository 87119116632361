import { FC, useCallback, useEffect, useState } from "react";
import {
  Box,
  Container,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import { Theme } from "src/theme";

import Header from "src/components/Header";
import Axios from "src/utils/axios";
import currencyPairs from "./currencyPairs";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttons: {
    marginRight: 16,
    "& > * + *": {
      marginLeft: 10,
    },
  },
  select: {
    width: "200px",
    height: "22px",
    backgroundColor: "#fff",
  },
  datePicker: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    width: "200px",
    height: "22px",
    color: "#808080;",
    border: "1px solid #808080;",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
}));

const Accuracy: FC = () => {
  const classes = useStyles();
  const [symbol, setSymbol] = useState("AUD/CAD");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [data, setData] = useState();
  const [numberOfAccurate, setNumberOfAccurate] = useState(0);
  const [numberOfInaccurate, setNumberOfInaccurate] = useState(0);
  const [total, setTotal] = useState(0);

  const handleSymbolChange = (e) => {
    setSymbol(e.target.value);
  };

  const handleChangeFromDate = (e) => {
    setFromDate(e.target.value);
  };

  const handleChangeToDate = (e) => {
    setToDate(e.target.value);
  };

  const countAccuracy = (data) => {
    let accurate = 0;
    let inaccurate = 0;
    let time = moment();

    data?.forEach((item) => {
      if (item.predictedFor <= time.toISOString()) {
        item.isAccurate
          ? setNumberOfAccurate(++accurate)
          : setNumberOfInaccurate(++inaccurate);
      }
    });
    setTotal(accurate + inaccurate);
  };

  const fetchData = useCallback(async () => {
    if (
      symbol !== undefined &&
      fromDate !== undefined &&
      toDate !== undefined
    ) {
      const response = await Axios.get(
        `/currencies/short-predictions?symbol=${symbol}&fromDate=${fromDate}&toDate=${toDate}`
      );
      setData(response.data);
    }
  }, [symbol, fromDate, toDate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    countAccuracy(data!);
  }, [data]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header
          title="Accuracy"
          crumbs={[
            { crumb: "Deep Sky", link: "/dashboard" },
            { crumb: "Accuracy" },
          ]}
        />
        <Box pb={1}>
          <Typography variant="body2">Currency Pair</Typography>{" "}
          <Select
            className={classes.select}
            variant="outlined"
            labelId="currency-pairs-label"
            id="currency-pairs"
            value={symbol}
            label="Age"
            onChange={handleSymbolChange}
            defaultValue={"AUD/CAD"}
          >
            {currencyPairs.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </Box>
        <Box pb={1}>
          <Typography variant="body2">From Date</Typography>{" "}
          <input
            className={classes.datePicker}
            type="date"
            id="from"
            name="from-date"
            value={fromDate}
            min="2021-10-18"
            max={toDate}
            onChange={(e) => handleChangeFromDate(e)}
          ></input>
        </Box>
        <Box pb={1}>
          <Typography variant="body2">To Date</Typography>{" "}
          <input
            className={classes.datePicker}
            type="date"
            id="to"
            name="to-date"
            value={toDate}
            min={fromDate}
            onChange={(e) => handleChangeToDate(e)}
          ></input>
        </Box>
        <Box>
          <Typography variant="body2">
            <span style={{ color: "green" }}>Accurate:</span> {numberOfAccurate}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">
            <span style={{ color: "red" }}>Inaccurate:</span>{" "}
            {numberOfInaccurate}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2">Total: {total ? total : 0}</Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default Accuracy;
