import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

export interface UpLotsIconProps {
  [key: string]: any;
}

const UpLotsIcon: FC<UpLotsIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <path d="M8.45713 9.37565L13.6203 16.5H16.4004L8.40039 5.5L0.400391 16.5H3.18055L8.45713 9.37565Z" />
      <path d="M8.45713 4.37565L13.6203 11.5H16.4004L8.40039 0.5L0.400391 11.5H3.18055L8.45713 4.37565Z" />
    </SvgIcon>
  );
};

export default UpLotsIcon;
