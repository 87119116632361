import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Box,
  Card,
  Tooltip,
  Button,
  Theme,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {
  ArrowForward,
  Close,
  Done,
  Star,
  StarOutline,
} from "@material-ui/icons";
import CautionIcon from "src/assets/icons/Caution";
import UpLotsIcon from "src/assets/icons/UpLotsIcon";
import UpIcon from "src/assets/icons/UpIcon";
import DownIcon from "src/assets/icons/DownIcon";
import DownLotsIcon from "src/assets/icons/DownLotsIcon";
import hasNearEarningsReportDate from "src/utils/hasNearEarningsReportDate";
import getFormattedScore from "src/utils/prediction/getFormattedScore";
import { Sentiments } from "src/types/Sentiments";
import { StockItem } from "src/slices/stocks";
import moneyFormatter from "src/utils/monetFormatter";
import { Skeleton } from "@material-ui/lab";
import useWatchList from "src/hooks/useWatchList";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "350px",
    display: "flex",
    flexDirection: "column",
  },
  rootPadding: {
    padding: theme.spacing(0.5, 2.5, 0.5, 2.5),
  },
  sectorName: {
    color: "#707680",
  },
  stockInfo: {
    padding: theme.spacing(1, 2.5),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderBottom: `1px solid #DBD7D2`,
  },
  listItemContainer: {
    flexGrow: 1,
  },
  squareBox: {
    height: 24,
    width: 140,
    borderRadius: 2,
    padding: "5px 10px 3px 10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
  },
  buttonPickScore: {
    width: 140,
    paddingTop: 0,
    paddingBottom: 0,
  },
  squareBoxTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },
  squareBoxText: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    alignItems: "center",
  },
  buttonViewBriefing: {
    padding: theme.spacing(0.5, 0),
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  viewBriefingWrapper: {
    borderTop: `1px solid #DBD7D2`,
  },
  skeletonWrapper: {
    height: 350,
    width: 360,
  },
  iconStyle: {
    fontSize: 14,
    marginLeft: 5,
  },
  watchListButton: {
    padding: theme.spacing(0.5),
  },
  label: {
    minWidth: "142px",
    paddingRight: theme.spacing(1),
  },
}));

const OrangeStar = withStyles({
  root: {
    color: "#FF8500",
  },
})(Star);

const PredictionBox = ({ direction, text, hideIcon, color }) => {
  const classes = useStyles();
  let icon;
  if (direction === Sentiments.BULLISH) {
    icon = <UpIcon className={classes.iconStyle} />;
  }

  if (direction === Sentiments.VERY_BULLISH) {
    icon = <UpLotsIcon className={classes.iconStyle} />;
  }

  if (direction === Sentiments.BEARISH) {
    icon = <DownIcon className={classes.iconStyle} />;
  }

  if (direction === Sentiments.VERY_BEARISH) {
    icon = <DownLotsIcon className={classes.iconStyle} />;
  }

  return (
    <Box
      className={classes.squareBox}
      style={{
        backgroundColor: color,
        // minWidth: "102px",
      }}
    >
      <Box className={classes.squareBoxTextContainer}>
        <Typography className={classes.squareBoxText}>
          {text} {!hideIcon ? icon : ""}
        </Typography>
      </Box>
    </Box>
  );
};

PredictionBox.propTypes = {
  direction: PropTypes.string,
  hideIcon: PropTypes.bool,
  text: PropTypes.string,
};

const ListItem = ({ label, children }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.rootPadding}
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string,
};

interface IStockCard {
  stockData: StockItem;
  isLoading?: boolean;
}

const StockCard = ({ stockData, isLoading }: IStockCard) => {
  const classes = useStyles();
  const { addToWatchList, removeFromWatchList, isInWatchList } = useWatchList();
  const watchListed = isInWatchList(stockData?.symbol);
  const toggleWatchListed = () => {
    if (watchListed) {
      removeFromWatchList(stockData?.symbol);
    } else {
      addToWatchList(stockData?.symbol);
    }
  };
  const hasNearEarningsDays = hasNearEarningsReportDate(stockData.symbol);
  const sentiment = stockData?.sentiment;

  if (isLoading) {
    return (
      <Card className={classes.skeletonWrapper}>
        <Skeleton
          animation="wave"
          width="80%"
          style={{ margin: 6, marginLeft: 50 }}
        />
        <Skeleton animation="wave" variant="rect" width={435} height={280} />
        <Skeleton
          animation="wave"
          width="80%"
          style={{ margin: 6, marginLeft: 50 }}
        />
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <Box className={classes.stockInfo}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h2" style={{ display: "flex" }}>
            {stockData.symbol}: {stockData.name}
            {hasNearEarningsDays && (
              <Tooltip title="Warning - Volatile Markets">
                <Box display="flex" alignItems="center" ml={1}>
                  <CautionIcon width={20} height={20} />
                </Box>
              </Tooltip>
            )}
          </Typography>
          {!watchListed ? (
            <IconButton
              className={classes.watchListButton}
              onClick={toggleWatchListed}
            >
              <StarOutline color="secondary" />
            </IconButton>
          ) : (
            <IconButton
              className={classes.watchListButton}
              onClick={toggleWatchListed}
            >
              <OrangeStar />
            </IconButton>
          )}
        </Box>
        <Typography variant="body1" className={classes.sectorName}>
          {stockData.sectorName}
        </Typography>
      </Box>

      <Box pt={1} pb={1} className={classes.listItemContainer}>
        {stockData?.previousClose && (
          <ListItem label="Yesterday’s Close">
            <Button
              variant="outlined"
              className={classes.buttonPickScore}
              fullWidth
            >
              {moneyFormatter(String(stockData?.previousClose))}
            </Button>
          </ListItem>
        )}

        <ListItem label="Stock Direction (1p)">
          <PredictionBox
            direction={sentiment?.displayDirection}
            text={sentiment?.label}
            color={sentiment?.color}
          />
        </ListItem>

        <ListItem label="Range Prediction (1p)">
          <PredictionBox
            direction={stockData.predictedDisplayDirection}
            text={stockData?.rangePrediction}
            color={sentiment?.color}
            hideIcon
          />
        </ListItem>

        <ListItem label="Sector Direction">
          <PredictionBox
            direction={stockData?.sectorSentiment?.displayDirection}
            text={stockData?.sectorSentiment?.label}
            color={stockData?.sectorSentiment?.color}
          />
        </ListItem>

        {stockData?.averageScore && (
          <ListItem label="Sky Score">
            <Button
              variant="outlined"
              className={classes.buttonPickScore}
              fullWidth
            >
              {getFormattedScore(stockData?.averageScore)}
            </Button>
          </ListItem>
        )}

        <ListItem label="Technical Indicators">
          <Button
            variant="outlined"
            className={classes.buttonPickScore}
            fullWidth
          >
            <Done fontSize="inherit" />{" "}
            {stockData?.combinedDirection?.supporting}{" "}
            <Box
              pl={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Close fontSize="inherit" />
              {stockData?.combinedDirection?.conflicting}
            </Box>
          </Button>
        </ListItem>
      </Box>

      <Box className={`${classes.rootPadding} ${classes.viewBriefingWrapper}`}>
        <Button
          color="primary"
          className={classes.buttonViewBriefing}
          component={RouterLink}
          to={`/dashboard/briefing/${stockData.symbol}`}
          target="_blank"
        >
          VIEW BRIEFING&nbsp;&nbsp;
          <ArrowForward />
        </Button>
      </Box>
    </Card>
  );
};

StockCard.defaultValues = {
  data: {
    modelPredictedSectorDirection: "",
    predictedDirection: "",
    combinedDirection: {},
  },
};

StockCard.propTypes = {
  data: PropTypes.shape({
    modelPredictedSectorDirection: PropTypes.string,
    predictedDirection: PropTypes.string,
  }),
  score: PropTypes.number,
  stockData: PropTypes.shape({}),
};

export default StockCard;
