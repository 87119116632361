import React from "react";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Clock from "react-live-clock";
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import clsx from "clsx";
import { Theme } from "src/theme";

export interface Crumb {
  link?: string;
  crumb?: string;
}

export interface HeaderProps {
  className?: string;
  title?: string;
  titleVariant?: Variant;
  subtitle?: string;
  titleButton?: string;
  crumbs?: Crumb[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    marginTop: 5,
  },
  titleButton: {
    padding: 0,
    marginTop: 5,
  },
  buttons: {
    "& > * + *": {
      marginLeft: 10,
    },
  },
}));

type Variant =
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "caption"
  | "button"
  | "overline"
  | "srOnly"
  | "inherit";

const Header: FC<HeaderProps> = ({
  className,
  title = "",
  subtitle = "",
  titleButton = "",
  crumbs = [],
  children,
  titleVariant = "h3",
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems="center"
      container
      justify="space-between"
      spacing={3}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        {crumbs.length > 0 && (
          <Breadcrumbs
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb"
          >
            {crumbs.map((x) => (
              <div key={x.crumb}>
                {x.link ? (
                  <Link
                    variant="body1"
                    color="inherit"
                    to={x.link}
                    component={RouterLink}
                  >
                    {x.crumb}
                  </Link>
                ) : (
                  <Typography variant="body1" color="textPrimary">
                    {x.crumb}
                  </Typography>
                )}
              </div>
            ))}
          </Breadcrumbs>
        )}
        {title && (
          <Typography
            variant={titleVariant ? titleVariant : "h3"}
            color="textPrimary"
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            className={classes.subtitle}
            color="textPrimary"
            style={{ color: "#808080" }}
          >
            {subtitle}
          </Typography>
        )}
        <Typography variant="body1">
          Today's Date: <Clock format="LLL" timezone="Canada/Eastern" ticking />{" "}
          EST
        </Typography>
        {titleButton && (
          <Button
            className={classes.titleButton}
            variant="text"
            style={{ color: "#009900" }}
          >
            {titleButton}
          </Button>
        )}
      </Grid>

      {children && (
        <Grid item className={classes.buttons}>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
