import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  Divider
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { submitError } from 'src/components/Forms/FormikHelper';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200
  }
}));

const Account: FC = () => {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const { user, logout } = useAuth();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
    } catch (err) {
      submitError(err);
    }
  };

  return (
    <>
      <Box
        display='flex'
        alignItems='center'
        component={ButtonBase}
        onClick={handleOpen}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt='User' className={classes.avatar} src={user?.avatar}>
          {!user?.avatar && user?.initials}
        </Avatar>
        <Hidden smDown>
          <Typography variant='h6' color='inherit'>
            {user?.fullName}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          // to={`/dashboard/users/${user?.id}/edit`}
          to={`/dashboard/users/edit`}
        >
          Profile
        </MenuItem>

        <MenuItem onClick={handleLogout}>Logout</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose} component={RouterLink} to={`/terms`}>
          Terms &amp; Conditions
        </MenuItem>
        <MenuItem onClick={handleClose} component={RouterLink} to={`/privacy`}>
          Privacy Policy
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;
