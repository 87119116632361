import { Sentiments } from "src/types/Sentiments";

const parsePrediction = (item) => {
  let originalDirection;
  let predictedDirection;

  if (item.adjustedClose) {
    if (item.adjustedClose > item.previousClose) {
      originalDirection = Sentiments.BULLISH;
    }

    if (item.adjustedClose < item.previousClose) {
      originalDirection = Sentiments.BEARISH;
    }

    if (item.adjustedClose === item.previousClose) {
      originalDirection = Sentiments.NEUTRAL;
    }
  }

  if (item.predictedCloseValue) {
    if (item.predictedCloseValue > item.previousClose) {
      predictedDirection = Sentiments.BULLISH;
    }

    if (item.predictedCloseValue < item.previousClose) {
      predictedDirection = Sentiments.BEARISH;
    }

    if (item.predictedCloseValue === item.previousClose) {
      predictedDirection = Sentiments.NEUTRAL;
    }
  }

  return {
    originalDirection,
    predictedDirection,
  };
};

const isPredictionBullish = (predictions) => {
  const data  = predictions || [];
  const currentDay = data[0] || {};

  return (
    currentDay && currentDay.predictedCloseValue > currentDay.previousClose
  );
};

export { parsePrediction, isPredictionBullish };
