import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import Page from "src/components/Page";
import Header from "src/components/Header";
import { Sentiments } from "src/types/Sentiments";
import DeepSkyAnalysis from "./components/DeepSkyAnalysis";
import TradingViewWidget from "react-tradingview-widget";
import Indicators from "src/constants/indicators";
import TechnicalAnalysis from "./components/TechnicalAnalysis";
import IndicatorCard from "./components/IndicatorCard";
import StockInfoHeader from "./components/StockInfoHeader";
import useStyles from "./styles";
import usePrediction from "src/hooks/usePrediction";
import { isPredictionBullish } from "src/utils/prediction/predictionParser";
import getIndicatorList, {
  getConflictingString,
  getSupportiveString,
  getTechnicalAnalysis,
} from "./helpers/getIndicatorList";
import Loader from "src/components/Loader";
import { fetchStocksList } from "src/slices/stocks";
import { useDispatch } from "react-redux";
import FreeTrialCTA from "src/components/FreeTrialCTA";
import useRunAfterDOMLoaded from "src/hooks/useRunAfterDomLoaded";
import JoyrideEntry from "src/components/JoyRide";
import { brochureSteps } from "src/constants/joy-ride";

const Brochure: FC = () => {
  const classes = useStyles();
  const symbol = "SPY";
  const [state] = usePrediction(symbol);
  const [indicatorTab, setIndicatorTab] = useState("DEFAULT");
  const [open, setOpen] = useState(false);
  const handleIndicatorTabChange = (event, value) => setIndicatorTab(value);
  const { currentDayPrediction, predictions, topPickObject, stock } = state;
  const isOverallBullish = isPredictionBullish(predictions);
  const indicators = getIndicatorList(currentDayPrediction);
  const stockHeaderRef = useRef<HTMLDivElement>(null);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    dispatchRedux(fetchStocksList());
  }, [dispatchRedux]);

  useRunAfterDOMLoaded(() => {
    const elements = document.querySelectorAll(".brochure");
    elements.forEach((element) => {
      element.addEventListener("click", (e) => {
        handleOpen();
        e.preventDefault();
        e.stopPropagation();
      });
    });

    document.addEventListener("click", (event) => {
      //@ts-ignore
      const classes = [...event?.target?.classList];
      if (classes.includes("brochure")) {
        event.preventDefault();
        event.stopPropagation();
        handleOpen();
      }
    });
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (state?.isLoading) {
    return (
      <>
        <Loader style={{ height: "85vh" }} />
      </>
    );
  }

  return (
    <Page
      className={`${classes.root} ${
        isOverallBullish ? classes.bullishBackground : classes.bearishBackground
      }`}
      title={`${symbol} Briefing`}
    >
      <Container maxWidth={false}>
        <Header title="Stock Briefing" />
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.titleContainer}>
            <StockInfoHeader
              stock={{
                symbol: stock.symbol,
                label: stock.name,
                price: currentDayPrediction.previousClose ?? 0,
              }}
            />
          </Grid>

          <FreeTrialCTA open={open} handleClose={() => handleClose} />

          <Grid item xs={12}>
            <Divider className={`${classes.divider} ${classes.topDivider}`} />
            <DeepSkyAnalysis
              score={topPickObject?.averageScore}
              scoreText={topPickObject?.averageScoreText}
              sentiment={
                isOverallBullish ? Sentiments.BULLISH : Sentiments.BEARISH
              }
              periodForecast={[1, 2, 5, 10, 15]}
              predictions={predictions}
            />
            <Divider
              className={`${classes.divider} ${classes.bottomDivider}`}
            />
          </Grid>
          <Grid item xs={12}>
            <TechnicalAnalysis
              supportingString={getSupportiveString(indicators)}
              conflictingString={getConflictingString(indicators)}
              description={getTechnicalAnalysis(indicators, isOverallBullish)}
              onJumpToFullAnalysis={() =>
                // @ts-ignore
                stockHeaderRef?.current?.scrollIntoView()
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">Chart View</Typography>
            {/* @ts-ignore */}
            <Box ref={stockHeaderRef} className={classes.tabsContainer}>
              <Tabs
                value={indicatorTab}
                onChange={handleIndicatorTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Default"
                  value="DEFAULT"
                  classes={{ root: classes.tab }}
                />
                <Tab label="SMA" value="sma" classes={{ root: classes.tab }} />
                <Tab label="EMA" value="ema" classes={{ root: classes.tab }} />
                <Tab
                  label="Stochastic"
                  value="stochastic"
                  classes={{ root: classes.tab }}
                />
                <Tab label="RSI" value="rsi" classes={{ root: classes.tab }} />
                <Tab
                  label="MACD"
                  value="macd"
                  classes={{ root: classes.tab }}
                />
                <Tab
                  label="Bollinger"
                  value="bb"
                  classes={{ root: classes.tab }}
                />
              </Tabs>
            </Box>

            <Box mb={2} style={{ height: 500, width: "100%" }}>
              <TradingViewWidget
                autosize
                symbol={symbol}
                // eslint-disable-next-line react/style-prop-object
                style="9"
                hide_side_toolbar={false}
                {...(Indicators[indicatorTab] || {}).tradingViewConfig}
              />
            </Box>
          </Grid>
          {indicators.map((indicator) => (
            <Grid key={indicator.title} item xs={12} md={6} lg={4}>
              <IndicatorCard {...indicator} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <JoyrideEntry
        steps={brochureSteps}
        id="brochure"
        disabledByDefault
        shouldRunUnauthenticated
      />
    </Page>
  );
};

export default Brochure;
