import { Table } from "@devexpress/dx-react-grid-material-ui";
import { Button, Box } from "@material-ui/core";

const Cell = (props) => {
  const { name } = props?.column;
  const { row, handleUserInvite, handlePasswordReset } = props ?? {};

  if (name === "action") {
    return (
      <Table.Cell {...props} data-display-name="Cell">
        <Box>
          <Button color="primary" onClick={() => handleUserInvite(row.email)}>
            Send Invite
          </Button>
        </Box>
        <Box>
          <Button
            color="primary"
            onClick={() => handlePasswordReset(row.email)}
          >
            Send Reset Password
          </Button>
        </Box>
      </Table.Cell>
    );
  }

  return <Table.Cell {...props} data-display-name="Cell" />;
};

export default Cell;
