import { createContext, useCallback, useEffect, useReducer } from "react";
import type { FC, ReactNode } from "react";
import jwtDecode from "jwt-decode";
import Loader from "src/components/Loader";
import store, { useDispatch } from "src/store";
import User from "src/models/User";
import { authenicate, getMyAccountInfo } from "src/apis/user";
import { fetchStocksList } from "src/slices/stocks";
import { fetchPredictions } from "src/slices/predictions";

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  redirect?: string;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string, redirect?: string) => void;
  logout: () => void;
  updateUser: (user: User) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: "INITIALISE";
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    user: User;
    redirect?: string;
  };
};

type LogoutAction = {
  type: "LOGOUT";
};

type UpdateUserAction = {
  type: "UPDATE_USER";
  payload: {
    user: User;
  };
};

type Action = InitialiseAction | LoginAction | LogoutAction | UpdateUserAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  redirect: "",
  user: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken?: string, refreshToken?: string): void => {
  if (accessToken && refreshToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        redirect: "",
        user,
      };
    }
    case "LOGIN": {
      const { user, redirect } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        redirect,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        redirect: "",
        user: null,
      };
    }
    case "UPDATE_USER": {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => {},
  logout: () => {},
  updateUser: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const dispatchRedux = useDispatch();

  const fetchData = useCallback(() => {
    dispatchRedux(fetchStocksList());
    dispatchRedux(fetchPredictions());
  }, [dispatchRedux]);

  const login = async (email: string, password: string, redirect?: string) => {
    const { data } = await authenicate(email, password);
    setSession(data?.access?.token, data?.refresh?.token);
    const { data: userData } = await getMyAccountInfo();
    fetchData();

    dispatch({
      type: "LOGIN",
      payload: {
        user: new User(userData),
        redirect,
      },
    });
  };

  const logout = async () => {
    setSession();
    dispatch({ type: "LOGOUT" });
    store.dispatch({ type: "USER_LOGOUT" });
  };

  const updateUser = async (user: User) => {
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const refreshToken = window.localStorage.getItem("refreshToken");
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && refreshToken && isValidToken(refreshToken)) {
          const { data: userData } = await getMyAccountInfo();
          fetchData();
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user: new User(userData),
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, [fetchData]);

  if (!state.isInitialised) {
    return (
      <Loader
        style={{
          height: "100vh",
          zIndex: 100000,
          left: "50%",
          marginLeft: "-50px",
          position: "absolute",
        }}
      />
    );
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
