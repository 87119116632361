import { SvgIcon } from "@material-ui/core";
import { FC } from "react";

export interface NeutralIconProps {
  [key: string]: any;
}

const NeutralIcon: FC<NeutralIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.45993 6L7.92038 3.87565L6.347 6H3.5L7.86364 0L12.2273 6H9.45993Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.26761 10L7.80716 12.1244L9.38054 10L12.2275 10L7.8639 16L3.50027 10L6.26761 10Z"
      />
    </SvgIcon>
  );
};

export default NeutralIcon;
