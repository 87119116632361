import React from 'react';
import type { FC } from 'react';
import {
  Card,
  Typography,
  Box,
  Avatar,
  Fade
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import type { Theme } from 'src/theme';
import User from 'src/models/User';
import CommonSkeleton from '../Skeleton';
import CommonEmail from 'src/components/Common/Email';
interface Props {
  [key: string]: any;
  user?: User;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& strong': {
      color: '#000',
      fontWeight: 'normal'
    }
  },
  avatar: {
    color: theme.palette.secondary.contrastText,
    height: 64,
    width: 64
  },
  icons: {
    color: theme.palette.text.secondary
  },
  listItemAvatar: {
    minWidth: 40,
    marginTop: 6
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0
  }
}));

const UserDetailCard: FC<Props> = ({ user }) => {
  const classes = useStyles();

  if (!user)
    return (
      <>
        <Card className={classes.root}>
          <CommonSkeleton />
        </Card>
      </>
    );

  return (
    <Card className={classes.root}>
      <Box flexGrow={1}>
        <Box display='flex' justifyContent='center' paddingBottom='12px'>
          <Fade in>
            <Avatar
              alt='User'
              className={classes.avatar}
              variant='rounded'
              src={user?.avatar}
            >
              {!user?.avatar && user.initials}
            </Avatar>
          </Fade>
        </Box>

        <Box mt={2} mb={2} textAlign='center'>
          <Typography
            variant='h3'
            color='textPrimary'
            align='center'
            gutterBottom
          >
            {user?.fullName}
          </Typography>
          <Typography variant='caption' color='textSecondary' align='center'>
            <CommonEmail email={user?.email} />
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default UserDetailCard;
