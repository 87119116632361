import React from 'react';
import type { FC } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Box } from '@material-ui/core';

interface Props {
  [key: string]: any;
}

const CommonSkeleton: FC<Props> = (props) => {
  return (
    <Box width='100%'>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Skeleton variant='circle' width={40} height={40} />
        <br />
        <Skeleton variant='text' height='40px' width='60%' />
        <Skeleton variant='text' width='60%' />
        <Skeleton variant='text' width='60%' />
      </Box>
    </Box>
  );
};

export default CommonSkeleton;
