import { Sentiments, SentimentColours } from "src/types/Sentiments";

const getSentimentColour = (sentiment: Sentiments | undefined): string => {
  switch (sentiment) {
    case Sentiments.VERY_BULLISH: {
      return SentimentColours.VERY_BULLISH;
    }
    case Sentiments.BULLISH: {
      return SentimentColours.BULLISH;
    }
    case Sentiments.NEUTRAL: {
      return SentimentColours.NEUTRAL;
    }
    case Sentiments.BEARISH: {
      return SentimentColours.BEARISH;
    }
    case Sentiments.VERY_BEARISH: {
      return SentimentColours.VERY_BEARISH;
    }
    default: {
      return "#FFF";
    }
  }
};

export default getSentimentColour;
