import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import Prediction from "src/models/Prediction";
import Axios from "src/utils/axios";
import getCurrentDate from "src/utils/prediction/getCurrentDate";
import { Sentiment } from "src/utils/prediction/getSentiment";
export interface StockItem {
  type: string;
  symbol: string;
  sectorName: string;
  averageScore?: number;
  averageShortScore?: number;
  previousClose?: number;
  previousShortClose?: number;
  combinedDirection?: any;
  combinedShortDirection?: any;
  sectorSentiment?: Sentiment;
  itemType?: string;
  sentiment?: Sentiment;
  shortSentiment?: Sentiment;
  predictedCloseValue?: number;
  predictedShortCloseValue?: number;
  rangePrediction?: string;
  predictedDirection?: string;
  predictedDisplayDirection?: string;
  modelPredictedSectorDirection?: string;
  sectorSymbol: string;
  name: string;
  description: string;
}
interface StocksState {
  data: StockItem[];
  marketsData: Prediction[];
  sectorsData: Prediction[];
  isLoading: boolean;
}

const initialState: StocksState = {
  data: [],
  marketsData: [],
  sectorsData: [],
  isLoading: false,
};

const slice = createSlice({
  name: "stocks",
  initialState,
  reducers: {
    isLoading(state: StocksState) {
      state.isLoading = true;
    },
    setStocks(
      state: StocksState,
      action: PayloadAction<{ data: StockItem[] }>
    ) {
      const { data } = action.payload;
      state.data = data;
      state.isLoading = false;
    },
    setMarketsData(
      state: StocksState,
      action: PayloadAction<{ data: Prediction[] }>
    ) {
      const { data } = action.payload;
      state.marketsData = data;
    },
    setSectorsData(
      state: StocksState,
      action: PayloadAction<{ data: Prediction[] }>
    ) {
      const { data } = action.payload;
      state.sectorsData = data;
    },
  },
});

export const reducer = slice.reducer;

export const fetchStocksList = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.isLoading());
  const { data = [] } = await Axios.get(`/stocks`);
  const { data: forexStocks = [] } = await Axios.get(`/currencies`);

  const activeStocks = [
    ...data.map((item) => ({ ...item, itemType: "stock" })),
    ...forexStocks.map((item) => ({ ...item, itemType: "currency" })),
  ].filter((stock) => stock.active);

  dispatch(slice.actions.setStocks({ data: activeStocks }));

  const sectorsList = activeStocks
    .filter((item) => item.type === "sector")
    .map((item) => item.symbol);
  const marketsList = activeStocks
    .filter((item) => item.type === "market")
    .map((item) => item.symbol);

  const { data: sectors } = await Axios.get(
    `/briefings/${sectorsList.join(",")}`,
    {
      params: {
        date: getCurrentDate(),
      },
    }
  );

  const { data: markets } = await Axios.get(
    `/briefings/${marketsList.join(",")}`,
    {
      params: {
        date: getCurrentDate(),
      },
    }
  );

  dispatch(
    slice.actions.setMarketsData({
      data: markets.map((item) => new Prediction(item)),
    })
  );
  dispatch(
    slice.actions.setSectorsData({
      data: sectors.map((item) => new Prediction(item)),
    })
  );
};

export default slice;
