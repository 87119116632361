const getPlans = () => [
  {
    id: 0,
    color: "#FF8500",
    title: "Special Offer",
    price: "$10",
    description: "For 10 days, $125 per month after initial 10 days",
    texts: ["Upgrade any time!", "Try Deep Sky out with no obligations"],
  },
  {
    id: 1,
    title: "Special Offer",
    price: "$599",
    stickerText: "20% Savings!",
    stickerColor: "#03CAFF",
    description: `<del style="color: red;">Was $750</del> <span style="color: green;">Save $150!</span> <br />6 month subscription Expires December 6, 2021`,
    texts: ["$99.83 per month"],
  },
  {
    id: 2,
    title: "Special Offer",
    price: "$599",
    stickerText: "33% Savings!",
    stickerColor: "#03CAFF",
    description: `<del style="color: red;">Was $1500</del> <span style="color: green;">Save $500!</span> <br />6 month subscription Expires December 6, 2021`,
    texts: ["$85.99 per month"],
  },
];

export default getPlans;
