import { Sentiments } from "../../types/Sentiments";

/**
 * This function will return Supportive or Conflicting of deepsky direction
 * @param {*} indicatorDirection
 * @param {*} modelDirection
 */
const isSupporting = (indicatorDirection, modelDirection) => {
  if (!modelDirection) {
    return Sentiments.NEUTRAL;
  }

  if (indicatorDirection === modelDirection) {
    return Sentiments.SUPPORTIVE;
  }

  return Sentiments.CONFLICTING;
};

/**
 * This function will return combine state of indicators and deep sky
 * @param {*} prediction
 */
const getCombineState = (prediction, predictedDirection) => {
  const {
    smaDirection,
    emaDirection,
    stochasticDirection,
    rsiDirection,
    macdDirection,
    bbDirection,
  } = prediction;

  const initialDirection = {
    supporting: 0,
    conflicting: 0,
    neutral: 0,
  };

  const directions = [
    smaDirection,
    emaDirection,
    stochasticDirection,
    rsiDirection,
    macdDirection,
    bbDirection,
  ].reduce((acc, currentValue) => {
    const value = isSupporting(currentValue, predictedDirection);
    if (value === Sentiments.SUPPORTIVE) {
      acc.supporting += 1;
    }

    // AI model has no Neutral
    if (
      currentValue === Sentiments.NEUTRAL ||
      currentValue === Sentiments.UNKNOWN
    ) {
      acc.neutral += 1;
    } else if (value === Sentiments.CONFLICTING) {
      acc.conflicting += 1;
    }

    return acc;
  }, initialDirection);

  return directions || initialDirection;
};

export { isSupporting, getCombineState };
