import { FC, ReactNode } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import NeutralIcon from "src/assets/icons/NeutralIcon";
import UpLotsIcon from "src/assets/icons/UpLotsIcon";
import UpIcon from "src/assets/icons/UpIcon";
import DownIcon from "src/assets/icons/DownIcon";
import DownLotsIcon from "src/assets/icons/DownLotsIcon";
import { Sentiment } from "src/utils/prediction/getSentiment";
import { Sentiments } from "src/types/Sentiments";

export interface PriceBlockProps {
  className?: string;
  sentiment?: Sentiment;
  date?: string;
  empty?: boolean;
  style?: any;
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    fontSize: "1rem",
    // filter: "drop-shadow(0px 1px 1px #333)",
    [theme.breakpoints.up("md")]: {
      display: ({ empty }: PriceBlockProps) => {
        return empty ? "none" : null;
      },
    },
  },
  date: {
    [theme.breakpoints.up("md")]: {
      display: ({ empty }: PriceBlockProps) => {
        return empty ? "none" : null;
      },
    },
  },
  priceBlock: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    borderRadius: "2px",
    height: "24px",
    width: "100%",
  },

  priceBlockWrapper: {
    display: "flex",

    [theme.breakpoints.up("xs")]: {
      width: "17.6%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "18.7%",
    },
    [theme.breakpoints.up("md")]: {
      width: "30%",
      marginRight: "4px",
      minWidth: ({ empty }: PriceBlockProps) => {
        return !empty ? "36px" : null;
      },
    },
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: theme.spacing(0),
    },
  },
  sentimentColour: {
    backgroundColor: ({ sentiment }: PriceBlockProps) => sentiment?.color,
  },
}));

const icon = (sentiment, classes) => {
  switch (sentiment?.displayDirection) {
    case Sentiments.VERY_BULLISH: {
      return <UpLotsIcon className={classes.icon} />;
    }
    case Sentiments.BULLISH: {
      return <UpIcon className={classes.icon} />;
    }
    case Sentiments.NEUTRAL: {
      return <NeutralIcon className={classes.icon} />;
    }
    case Sentiments.BEARISH: {
      return <DownIcon className={classes.icon} />;
    }
    case Sentiments.VERY_BEARISH: {
      return <DownLotsIcon className={classes.icon} />;
    }
  }
};

const PriceBlock: FC<PriceBlockProps> = ({
  className,
  sentiment,
  date,
  empty,
}) => {
  const classes = useStyles({ sentiment, empty });

  return (
    <Box className={`${classes.priceBlockWrapper} ${className}`}>
      <Box className={`${classes.priceBlock} ${classes.sentimentColour}`}>
        {icon(sentiment, classes)}
      </Box>
      <Typography variant="caption" className={classes.date}>
        {date}
      </Typography>
    </Box>
  );
};

export default PriceBlock;
