import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    margin: theme.spacing(2, 0),
  },
  title: {
    padding: theme.spacing(1),
    textAlign: "center",
  },
  link: {
    color: "#FF8500",
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    color: "#fff",
    backgroundColor: theme.palette.primary.light,
    width: 200,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const FreeTrialCTA = ({ open, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose()}
      maxWidth={"xs"}
    >
      <DialogTitle className={classes.title}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h3">Get Full Access for $10</Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose()} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body1">
          Enjoy this complimentary, fully functioning briefing for the S&P 500.
          Access to additional features requires a paid account.
        </Typography>
        <Button
          className={classes.button}
          href="https://www.joindeepsky.com/sign-up"
        >
          Get Full Access Now
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FreeTrialCTA;
