import PropTypes from "prop-types";
import React from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { ArrowForward } from "@material-ui/icons";

const BodyCell = (props) => {
  const { column, row, navigate } = props;

  if (column.name === "arrow") {
    return (
      <Table.Cell
        {...props}
        onClick={() =>
          navigate(`/dashboard/forex/short-briefing/${row.symbol}`)
        }
      >
        <ArrowForward color="primary" style={{ cursor: "pointer" }} />
      </Table.Cell>
    );
  }

  return (
    <Table.Cell
      onClick={() => navigate(`/dashboard/forex/short-briefing/${row.symbol}`)}
      {...props}
      style={{
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        textAlign: column.name === "previousClose" ? "center" : "left",
      }}
    />
  );
};

BodyCell.propTypes = {
  column: PropTypes.shape({
    name: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  row: PropTypes.shape({
    symbol: PropTypes.string,
  }),
};

export default React.memo(BodyCell);
