import React, { FC } from 'react';
import {
  Avatar,
  makeStyles,
  StepConnector,
  Theme,
  withStyles
} from '@material-ui/core';
import clsx from 'clsx';

export const CustomStepConnector = withStyles((theme: Theme) => ({
  vertical: {
    marginLeft: 24,
    padding: 0
  },
  line: {
    borderColor: theme.palette.divider,
    minHeight: 64
  }
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 48,
    height: 48,
    cursor: 'pointer',
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[10],
    color: theme.palette.secondary.contrastText
  },
  completed: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText
  }
}));

export interface IStep {
  label: string;
  icon: any;
}

interface CustomStepIconProps {
  active?: boolean;
  completed?: boolean;
  icon: number;
  steps: IStep[];
}

export const CustomStepIcon: FC<CustomStepIconProps> = ({
  active,
  completed,
  icon,
  steps
}) => {
  const classes = useCustomStepIconStyles();

  const Icon = steps[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      <Icon />
    </Avatar>
  );
};

