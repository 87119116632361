import bg from "src/assets/images/arrow-background.jpg"

const containerStyle = {
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundAttachment: "fixed",
  background: `url(${bg})`,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
};

export default containerStyle
