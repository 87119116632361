import { useSelector } from "react-redux";
import { RootState } from "src/store";

const useStocks = () => {
  const availableStocks = useSelector((reduxState: RootState) =>
    reduxState.stocks.data.map((item) => ({
      ...item,
      name: `${item.symbol}: ${item.name}`,
    }))
  );

  return {
    availableStocks,
  };
};

export default useStocks
