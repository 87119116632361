import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import ForexPrediction from "src/models/ForexPrediction";
import Prediction from "src/models/Prediction";
import { RootState } from "src/store";
import Axios from "src/utils/axios";
import getCurrentDate from "src/utils/prediction/getCurrentDate";

interface IUseForexPrediction {
  predictions: ForexPrediction[];
  currentDayPrediction: ForexPrediction;
  previousPredictions: ForexPrediction[];
  marketsData: Prediction[];
  isLoading: boolean;
  stock: {
    symbol?: string;
    name?: string;
    label?: string;
    sectorName?: string;
    sectorSymbol?: string;
  };
}

const useForexPrediction = (symbol: string): [IUseForexPrediction, any] => {
  const [state, setState] = useState<IUseForexPrediction>({
    predictions: [],
    currentDayPrediction: new ForexPrediction({}),
    previousPredictions: [],
    marketsData: [],
    stock: {},
    isLoading: false,
  });

  const stocks = useSelector((reduxState: RootState) => reduxState.stocks.data);
  const marketsData = useSelector(
    (reduxState: RootState) => reduxState.stocks.marketsData
  );

  const getPredictions = useCallback(async (stockSymbol, periods = 16) => {
    const params = {
      periods: periods || 16,
      symbol: stockSymbol,
      date: getCurrentDate(),
    };
    try {
      const { data } = await Axios(`/currencies/long-predictions/query`, {
        params,
      });
      return (data || []).map((item) => new ForexPrediction(item));
    } catch (e) {
      console.log("Error while fetching data");
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = await getPredictions(symbol);
      const previousPredictions = await getPredictions(symbol, -16);
      const stock = stocks.find((stock) => stock.symbol === symbol) ?? {
        sectorSymbol: "",
      };

      setState({
        currentDayPrediction: data[0] || new ForexPrediction({}),
        predictions: data,
        previousPredictions,
        marketsData: [],
        stock,
        isLoading: false,
      });
    };

    if (symbol) {
      fetchData();
    }
  }, [symbol, getPredictions, stocks]);

  return [{ ...state, marketsData }, setState];
};

export default useForexPrediction;
