import { FC } from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Box,
  Divider,
} from "@material-ui/core";
import { Sentiments } from "src/types/Sentiments";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import UpIcon from "src/assets/icons/UpIcon";

export interface IndicatorCardProps {
  className?: string;
  title?: string;
  directionKey?: string;
  sentiment?: Sentiments;
  support?: Sentiments;
  description?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  paper: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    background: "#fff",
    color: "#40434A",
  },
  container: {
    padding: theme.spacing(3),
    minHeight: "420px",
  },
  badge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 12px 2px 8px",
    marginTop: 20,
  },
  icon: {
    marginRight: "5px",
    fontSize: "0.8rem",
  },
  bullish: {
    color: "#fff",
    backgroundColor: "#03CAFF",
    marginRight: theme.spacing(2),
  },
  bearish: {
    color: "#fff",
    backgroundColor: "#FF3801",
    marginRight: theme.spacing(2),
  },
  neutral: {
    backgroundColor: "#fafafa",
    marginRight: theme.spacing(2),
  },
  supporting: {
    color: "#40434A",
    backgroundColor: "#fff",
    border: "1px solid #40434A",
  },
  conflicting: {
    backgroundColor: "#40434A",
    color: "#fff",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const IndicatorCard: FC<IndicatorCardProps> = ({
  className,
  title,
  sentiment,
  support,
  description,
}) => {
  const classes = useStyles();

  if (!description || !support) {
    return (
      <Paper>
        <Box p={4} m={2}>
          <Typography variant="body1">No Information Found</Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h2">{title}</Typography>
            <Box display="flex">
              {sentiment === Sentiments.NEUTRAL && (
                <Box className={`${classes.badge} ${classes.neutral}`}>
                  <Typography variant="body2">NEUTRAL</Typography>
                </Box>
              )}
              {(sentiment === Sentiments.BULLISH ||
                sentiment === Sentiments.VERY_BULLISH) && (
                <Box className={`${classes.badge} ${classes.bullish}`}>
                  <UpIcon className={classes.icon} />
                  <Typography variant="body2">BULLISH</Typography>
                </Box>
              )}
              {(sentiment === Sentiments.BEARISH ||
                sentiment === Sentiments.VERY_BEARISH) && (
                <Box className={`${classes.badge} ${classes.bearish}`}>
                  <KeyboardArrowDownIcon className={classes.icon} />
                  <Typography variant="body2">BEARISH</Typography>
                </Box>
              )}
              {support === Sentiments.SUPPORTIVE && (
                <Box className={`${classes.badge} ${classes.supporting}`}>
                  <CheckIcon fontSize="small" className={classes.icon} />
                  <Typography variant="body2">SUPPORTING</Typography>
                </Box>
              )}
              {support === Sentiments.CONFLICTING &&
                sentiment !== Sentiments.NEUTRAL && (
                  <Box className={`${classes.badge} ${classes.conflicting}`}>
                    <CloseIcon fontSize="small" className={classes.icon} />
                    <Typography variant="body2">CONFLICTING</Typography>
                  </Box>
                )}
            </Box>
            <Divider variant="fullWidth" className={classes.divider} />
            <Box>
              <Typography variant="body1">{description}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default IndicatorCard;
