import React from "react";
import type { FC } from "react";
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
  Paper,
} from "@material-ui/core";
import type { Theme } from "src/theme";
import Page from "src/components/Page";
import bg from "src/assets/images/500-bg.gif";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundAttachment: "fixed",
    backgroundColor: theme.palette.background.dark,
    background: `url(${bg})`,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    minHeight: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 313,
    minHeight: 250,
    maxHeight: "100%",
    overflowY: "scroll",
    overflowX: "auto",
    background: "#fff",
    padding: theme.spacing(2),
  },
}));

const NotFound: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Page className={classes.root} title="404: Not found">
      <Paper className={classes.paper}>
        <Box mb={3}>
          <Typography
            align="center"
            variant={mobileDevice ? "h4" : "h1"}
            style={{
              fontSize: 70,
              fontFamily: "roboto",
              fontStyle: "normal",
              fontWeight: 200,
            }}
            color="primary"
          >
            Whoops.
          </Typography>
          <Typography align="center" variant="h5" color="textPrimary">
            Something has gone wrong.
          </Typography>
        </Box>
        <Box px={1}>
          <Typography align="center" variant="body1" color="textPrimary">
            Artificial Intelligence isn’t perfect, and we’re learning all the time. We’ll try to fix this problem asap.{" "}
            <a href="/dashboard" style={{ color: theme.palette.primary.main }}>
              click here{" "}
            </a>
            to refresh.
          </Typography>
        </Box>
      </Paper>
    </Page>
  );
};

export default NotFound;
