import {
  Box,
  Grid,
  Typography,
  IconButton,
  Button,
  Divider,
  Hidden,
  makeStyles,
} from "@material-ui/core";
import MarketForecast from "../../components/MarketForecast";
import GridIcon from "src/assets/icons/GridIcon";
import { List as ListIcon } from "@material-ui/icons";
import usePredictions from "src/hooks/usePredictions";
import useBoolean, { UseBooleanActions } from "src/hooks/useBoolean";
import Prediction from "src/models/Prediction";
import EmptyWatchList from "src/components/EmptyWatchlist";
import ForexCard from "src/components/ForexCard/ForexCard";
import ForexPrediction from "src/models/ForexPrediction";
import ForexGrid from "src/components/ForexGrid";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    [theme.breakpoints.up(700)]: {
      maxWidth: "50%",
      flexBasis: "50%",
    },
    [theme.breakpoints.up(1650)]: {
      maxWidth: "33.33%",
      flexBasis: "33.33%",
    },
  },
}));

interface IList {
  title: string;
  isLoading: boolean;
  data: (Prediction | ForexPrediction)[];
  isListViewSelected?: UseBooleanActions;
  onlyList?: boolean;
  isMyWatchlist?: boolean;
  showLimit?: number;
  showMoreButtonLabel?: string;
  onShowMoreClick?: () => void;
}

const marketNames = [
  "S&P 500 (SPY)",
  "DOW 30 (DJI)",
  "NASDAQ (QQQ)",
  "Crude Oil (OIL)",
  "Gold (GLD)",
  "USD Index (UUP)",
];

const List = ({
  title,
  isLoading,
  data,
  isListViewSelected,
  onlyList = false,
  isMyWatchlist = false,
  showLimit = 9,
  showMoreButtonLabel = "",
  onShowMoreClick,
}: IList) => {
  const classes = useStyles();
  const { predictions } = usePredictions(data);
  const isMoreShowing = useBoolean(false);
  const maxLimit = isMoreShowing.value ? 50 : showLimit;
  const predictionsWithLoader = isLoading
    ? new Array(showLimit).fill(1)
    : predictions;

  return (
    <Grid container>
      {!onlyList && (
        <Hidden mdUp>
          <Grid container item md={3}>
            <Box width={1} mb={3}>
              <MarketForecast
                isLoading={isLoading}
                marketNames={marketNames}
                isForex
              />
            </Box>
          </Grid>
        </Hidden>
      )}

      <Grid container spacing={2}>
        <Grid item md={9} style={{ width: "100%" }}>
          <Box
            width={1}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={onlyList ? 2 : 1}
          >
            <Typography variant="h2">{title}</Typography>

            {!onlyList && (
              <Box>
                <IconButton onClick={isListViewSelected?.setFalse}>
                  <GridIcon
                    color={!isListViewSelected?.value ? "#005E87" : "grey"}
                  />
                </IconButton>
                <Hidden smDown>
                  <IconButton onClick={isListViewSelected?.setTrue}>
                    <ListIcon
                      style={
                        isListViewSelected?.value
                          ? { fill: "#005E87" }
                          : { fill: "grey" }
                      }
                    />
                  </IconButton>
                </Hidden>
              </Box>
            )}
          </Box>
          {isListViewSelected?.value ? (
            <>
              <ForexGrid data={predictions} />
            </>
          ) : (
            <>
              <Grid container item spacing={2}>
                {predictionsWithLoader
                  .slice(0, maxLimit)
                  .map((forexData, index) => (
                    <Grid key={index} item xs={12} className={classes.card}>
                      <ForexCard forexData={forexData} isLoading={isLoading} />
                    </Grid>
                  ))}
              </Grid>
              {isMyWatchlist && data?.length === 0 && (
                <Grid item>
                  <Box mt={2} flex={1}>
                    <EmptyWatchList />
                  </Box>
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Box mt={3} mb={3}>
                    <Button
                      onClick={onShowMoreClick ?? isMoreShowing.toggle}
                      color="primary"
                    >
                      {showMoreButtonLabel ||
                        (isMoreShowing.value ? "Show Less" : "Show More")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              <Box width={1} height={30}>
                <Divider />
              </Box>
            </>
          )}
        </Grid>

        {!onlyList && (
          <Hidden smDown>
            <Grid item md={3}>
              <Box width={1}>
                <MarketForecast
                  isForex
                  isLoading={isLoading}
                  marketNames={marketNames}
                />
              </Box>
            </Grid>
          </Hidden>
        )}
      </Grid>
    </Grid>
  );
};

export default List;
