import { FC } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { Sentiments } from "src/types/Sentiments";
import RemoveIcon from "@material-ui/icons/Remove";
import UpLotsIcon from "src/assets/icons/UpLotsIcon";
import UpIcon from "src/assets/icons/UpIcon";
import DownIcon from "src/assets/icons/DownIcon";
import DownLotsIcon from "src/assets/icons/DownLotsIcon";
import getSentimentColour from "src/utils/prediction/getSentimentColour";

export interface SentimentTextProps {
  sentiment?: Sentiments;
  headerText?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  inlineFlex: {
    display: "inline-flex",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  sentimentColour: {
    color: ({ sentiment }: SentimentTextProps) => getSentimentColour(sentiment),
  },
}));

const SentimentText: FC<SentimentTextProps> = ({
  sentiment,
  headerText,
}) => {
  const classes = useStyles({ sentiment });

  return (
    <>
      {headerText && <Typography variant="h4">{headerText}</Typography>}
      {(() => {
        switch (sentiment) {
          case Sentiments.VERY_BULLISH:
            return (
              <Box
                className={`${classes.inlineFlex} ${classes.sentimentColour}`}
              >
                <UpLotsIcon className={classes.icon} fontSize="small" />
                <Typography variant="h4">Very Bullish</Typography>
              </Box>
            );
          case Sentiments.BULLISH:
            return (
              <Box
                className={`${classes.inlineFlex} ${classes.sentimentColour}`}
              >
                <UpIcon className={classes.icon} fontSize="small" />
                <Typography variant="h4">Bullish</Typography>
              </Box>
            );
          case Sentiments.NEUTRAL:
            return (
              <Box
                className={`${classes.inlineFlex} ${classes.sentimentColour}`}
              >
                <RemoveIcon fontSize="large" />
                <Typography variant="h4">Neutral</Typography>
              </Box>
            );
          case Sentiments.BEARISH:
            return (
              <Box
                className={`${classes.inlineFlex} ${classes.sentimentColour}`}
              >
                <DownIcon className={classes.icon} fontSize="small" />
                <Typography variant="h4">Bearish</Typography>
              </Box>
            );
          case Sentiments.VERY_BEARISH:
            return (
              <Box
                className={`${classes.inlineFlex} ${classes.sentimentColour}`}
              >
                <DownLotsIcon className={classes.icon} fontSize="small" />
                <Typography variant="h4">Very Bearish</Typography>
              </Box>
            );
          default:
            return;
        }
      })()}
    </>
  );
};

export default SentimentText;
