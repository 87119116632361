import React, { useState } from "react";
import type { FC } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { validationMessage } from "src/utils/validations";
import { Link as RouterLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { Button, Box, TextField, Typography, Grid } from "@material-ui/core";
import Axios from "src/utils/axios";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import { submitError } from "src/components/Forms/FormikHelper";
import Page from "src/components/Page";

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  links: {
    marginTop: theme.spacing(2),
    "& > * + * ": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const Forgot: FC<Props> = ({ className, ...rest }) => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  return (
    <Page className={classes.root} title="Reset Password">
      <Typography variant="h2">Recover your password</Typography>
      <Typography>
        Please enter your email below and we will send you a new password.
      </Typography>
      {!completed ? (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            email: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required(validationMessage("Email Address")),
          })}
          onSubmit={async (values, actions) => {
            try {
              await Axios.post<any>("password/send-forgot-email", {
                email: values?.email?.trim()?.toLowerCase(),
              });

              setSuccessMessage(
                "We have sent a recovery link to your email address. Be sure to check your spam folder if you can't find the email."
              );
              actions.setStatus({ success: true });
              actions.setSubmitting(false);
              setCompleted(true);
            } catch (err) {
              submitError(err, actions);
              setCompleted(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={clsx(classes.root, className)}
              {...rest}
            >
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      autoFocus
                      helperText={touched.email && errors.email}
                      label="Email Address"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ErrorPanel errors={errors} />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Button
                      fullWidth
                      color="primary"
                      to="/login"
                      component={RouterLink}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Box mt={3}>
          <Alert severity="info">{successMessage}</Alert>
          <Box mt={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              to="/login"
              component={RouterLink}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      )}
    </Page>
  );
};

Forgot.propTypes = {
  className: PropTypes.string,
};

export default Forgot;
