import React from "react";
import type { FC } from "react";
import { Box, Link, Typography } from "@material-ui/core";

interface TermsProps {
  [key: string]: any;
}

const Terms: FC<TermsProps> = (props) => {
  return (
    <Box lineHeight={1}>
      <Typography variant="h2">Terms & Conditions</Typography>
      <br />
      <Typography>
        The Deep Sky Trading Assistant products are owned and operated by
        ​TradeSmart University LLC in conjunction with others pursuant to
        contractual arrangements. Deep Sky Trading Assistant products are
        intended for use solely by the individual that accesses our website or,
        in the case of Deep Sky Trading Assistant products offered for sale, by
        the individual that has purchased the product unless otherwise duly
        noted. You may access and use the materials provided by Deep Sky Trading
        Assistant, and download and/or print out a copy of the materials, solely
        for your personal use. You may not allow another person to use your
        access codes and you may not allow or facilitate multiple users using
        the same access code to obtain Deep Sky Trading Assistant products.
      </Typography>
      <br />
      <Typography>
        Deep Sky Trading Assistant products include the information provided on
        this website, written materials that you may receive from us, and
        courses and presentations obtained from us, whether in-person or through
        electronic access (including through the website, webinars, online video
        training, DVDs, or any other similar product). You are granted a
        limited, non-exclusive, personal, non-assignable and non-transferable
        license to use the Deep Sky Trading Assistant products in exchange for
        your agreement to these Terms of Use and other applicable terms provided
        to you when you obtain Deep Sky Trading Assistant products. We can
        revoke your access to Deep Sky Trading Assistant products if you violate
        applicable terms, without limiting our other legal and equitable
        remedies. To provide you with Deep Sky Trading Assistant products we may
        permit you to access our computer systems, certain databases and
        services, documentation, software, and data which also are owned by us,
        but to which we are not granting you any rights. You do not acquire any
        ownership rights by using any Deep Sky Trading Assistant products.
        Ownership of DeepSkyTrading.com website and related websites and other
        Deep Sky Trading Assistant products remain property of ​TradeSmart
        University LLC.
      </Typography>
      <br />
      <Typography variant="h3">Intellectual Property Rights</Typography>
      <br />
      <Typography>
        Deep Sky Trading Assistant, the Deep Sky Trading Assistant logo and
        other trademarks used with Deep Sky Trading Assistant products,
        including the DeepSkyTrading.com website, are owned by ​TradeSmart
        University LLC, its Affiliates or third parties. You may not use or
        reproduce such trademarks without written permission from TradeSmart
        University or other applicable trademark holder. You may not use any
        metatags or other hidden text which incorporates Deep Sky Trading
        Assistant trademarks or Deep Sky Trading Assistant products. The design,
        text, graphics, pictures, information, data, sound files, video files
        and other materials and content, and the selection and arrangement
        thereof in the Deep Sky Trading Assistant products are the proprietary
        property of ​TradeSmart University LLC.
      </Typography>
      <br />
      <Typography>
        DeepSkyTrading.com and other websites owned by ​TradeSmart
        UniversityLLC., including the look and feel of such sites may not be
        copied or imitated. You may not reproduce, store, sell, distribute,
        publish, broadcast, circulate, market, license, adapt, commercially
        exploit or in any way transfer the Deep Sky Trading Assistant products
        or allow or facilitate third persons attempting to do so. You may not
        republish, publicly display, upload, post, transmit, distribute or
        time-share Deep Sky Trading Assistant products. You may not modify,
        adapt, translate or create any derivative works from Deep Sky Trading
        Assistant products. You may not use any data mining, crawlers, spiders,
        robots or similar data gathering or extraction methods. You may not
        download, index or in any non-transitory manner store or cache any
        portion of the Deep Sky Trading Assistant products. You may not remove,
        deface, obscure or alter any copyright, trademark or other proprietary
        rights notices affixed to or provided with the Deep Sky Trading
        Assistant products. You may not create a substitute or similar service
        or product through the use of or access to the Deep Sky Trading
        Assistant product. Our rights, including intellectual property rights,
        are protected by the United States and international trademark,
        copyright and other laws. We reserve all rights and remedies available
        to us and nothing in our Terms of Use is intended to limit our rights
        and remedies.
      </Typography>
      <br />
      <Typography variant="h3">Security</Typography>
      <br />
      <Typography>
        To access some of the Deep Sky Trading Assistant products, we require
        that you provide us with information about you. We are providing you
        with access to those Deep Sky Trading Assistant products, in part,
        because you agree that the information you provide is accurate. In some
        instances you will also be given account numbers and access codes, such
        as a unique Username and password. You are responsible for all activity
        conducted using your account numbers and access codes. If you have
        reason to believe that your account numbers or access codes are no
        longer secure, promptly change these numbers and codes by updating your
        account information, and immediately notify our customer service
        department by email at info@TradeSmart U.com or by telephone.
        Information that we collect from you, such as registration and credit
        card information, is subject to our privacy policy.
      </Typography>
      <br />
      <Typography>
        Disclaimer of Warranties: ​​TRADESMART UNIVERSITY LLC. AND ITS
        AFFILIATES PROVIDE THE MATERIALS AVAILABLE AT DEEPSKYTRADING.COM AND
        OTHER DEEP SKY TRADING ASSISTANT PRODUCTS, "AS IS" AND WITHOUT
        REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED.
        TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW,
        ​TRADESMART UNIVERSITY LLC AND ITS AFFILIATES AND THEIR RESPECTIVE
        OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, SPONSORS AND
        ADVERTISERS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY,
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE,
        AND ALL WARRANTIES RELATING TO THE ADEQUACY, ACCURACY OR COMPLETENESS OF
        ANY INFORMATION, PRODUCT OR SERVICES. ​TRADESMART UNIVERSITY LLC. AND
        ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
        SUPPLIERS, SPONSORS AND ADVERTISERS DO NOT WARRANT THAT YOUR USE OF ANY
        DEEP SKY TRADING ASSISTANT PRODUCTS WILL BE UNINTERRUPTED, ERROR-FREE,
        OR SECURE, THAT DEFECTS WILL BE CORRECTED, OR THAT DEEP SKY TRADING
        ASSISTANT PRODUCTS, INCLUDING THE SERVERS ON WHICH WEBSITES ARE HOSTED
        ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. APPLICABLE LAW MAY NOT
        ALLOW THE EXCLUSION OF IMPLIED OR OTHER WARRANTIES, SO THE ABOVE
        EXCLUSIONS MAY NOT APPLY TO YOU. YOU ACKNOWLEDGE THAT YOU ARE
        RESPONSIBLE FOR OBTAINING AND MAINTAINING ALL TELEPHONE, COMPUTER
        HARDWARE AND OTHER EQUIPMENT NEEDED TO ACCESS AND USE INFORMATION,
        PRODUCTS AND SERVICES, INCLUDING WEB SITES, AND ALL CHARGES RELATED
        THERETO. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE OF AND
        RELIANCE ONTRADESMART UNIVERSITY PRODUCTS AND SERVICES. OPINION, ADVICE,
        OR STATEMENT OF ​TRADESMART UNIVERSITY LLC, ITS AFFILIATES OR THEIR
        RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, SPONSORS
        AND ADVERTISERS, WHETHER MADE ON A WEBSITE OR OTHERWISE, SHALL NOT
        CREATE ANY WARRANTY.{" "}
        <b>
          YOUR USE OF DEEP SKY TRADING ASSISTANT PRODUCTS IS ENTIRELY AT YOUR
          OWN RISK.
        </b>
      </Typography>
      <br />
      <Typography variant="h3">Limitation of Liability</Typography>
      <br />
      <Typography>
        NEITHER ​TRADESMART UNIVERSITY LLC. NOR ITS AFFILIATES OR THEIR
        RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, SPONSORS
        OR ADVERTISERS ARE RESPONSIBLE OR LIABLE FOR ANY INDIRECT, INCIDENTAL,
        CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES UNDER ANY
        CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY ARISING OUT OF OR
        RELATING IN ANY WAY TO ANY DEEP SKY TRADING ASSISTANT PRODUCT. YOUR SOLE
        REMEDY FOR DISSATISFACTION WITH ANY TRADESMART UNIVERSITY PRODUCTS IS TO
        STOP USING THEM. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY TO YOU FOR ALL
        DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT,
        TORT—INCLUDING, WITHOUT LIMITATION—NEGLIGENCE, OR OTHERWISE) SHALL BE
        THE TOTAL AMOUNT PAID BY YOU, IF ANY, FOR THE DEEP SKY TRADING ASSISTANT
        PRODUCT. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OF LIABILITY, SO
        THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      </Typography>
      <br />
      <Typography variant="h3">Indemnification</Typography>
      <br />
      <Typography>
        You agree to indemnify, defend and hold ​TRADESMART UNIVERSITY LLC., its
        Affiliates and their respective officers, directors, employees and
        agents harmless from and against any and all claims, damages, losses,
        costs (including reasonable attorneys' fees), or other expenses that
        arise directly or indirectly out of or from (a) your breach of these
        Terms of Use or other terms applicable to your use of TradeSmart
        University products; (b) any allegation that any materials that you
        submit to us or transmit to us infringe or otherwise violate the
        copyright, trademark, trade secret or other intellectual property or
        other rights of any third party; and/or (c) your activities in
        connection with any Deep Sky Trading Assistant product.
      </Typography>
      <br />
      <Typography variant="h3">Termination</Typography>
      <br />
      <Typography>
        Each of us can terminate our relationship with the other by
        discontinuing business with the other. For example, we may discontinue
        your access to Deep Sky Trading Assistant products in the event you are
        violating the Terms of Use. However, our rights and remedies under these
        Terms of Use, including our intellectual property rights,
        indemnification and limitation of liability, and any other agreement
        will continue to apply and survive the termination of the relationship.
        Upon termination, we may disable your access to some or all Deep Sky
        Trading Assistant products, including if you have violated these Terms
        of Use. Upon termination, you will not have access to your account or
        files or other content accessible through your account or a Deep Sky
        Trading Assistant product.
      </Typography>
      <br />
      <Typography variant="h3">Miscellaneous</Typography>
      <br />
      <Typography>
        These Terms of Use and any other agreement between Deep Sky Trading
        Assistant and you is governed by and construed in accordance with the
        laws of the State of Tennessee, United States of America, without regard
        to principles of conflicts of law. You agree to personal jurisdiction by
        the federal and state courts located in Tennessee, United States of
        America, and waive any jurisdictional, venue, or inconvenient forum
        objection to such courts. If any provision of our agreements is found to
        be unlawful, void, or for any reason unenforceable, then that provision
        shall be deemed severable and shall not affect the validity and
        enforceability of any remaining provisions. These Terms of Use and any
        other agreements are not assignable, transferable or sub-licensable by
        you.
      </Typography>
      <br />
      <Typography>
        If ​TRADESMART UNIVERSITY LLC. takes any action to enforce the Terms of
        Use or any other agreement with you, without limiting any of its other
        legal or equitable rights and remedies, you shall pay the costs incurred
        by it in connection with such action, including reasonable attorneys'
        fees.
      </Typography>
      <br />
      <Typography variant="h3">Disclosures</Typography>
      <br />
      <Typography>
        From time to time, we make information available to you regarding our
        business and the trading industry. Remember,{" "}
        <b>ALL TRADING AND INVESTMENT ACTIVITY INVOLVES RISK</b>. We provide
        Analysis, strategies and education designed to inform and teach you
        about trading and how possibly to limit risk and make profits from
        trading. There is no assurance that you will make profits from what you
        are learning from us. You should review the information on these pages
        from time to time.
      </Typography>
      <br />
      <Typography>
        DEEP SKY TRADING ASSISTANT IS A STOCK ANALYSIS PLATFORM. IT IS NOT
        INTENDED TO BE TRADING OR INVESTING ADVICE. WE DO NOT RECOMMEND STOCKS
        TO BUY OR SELL. WE PROVIDE A PLATFORM TO ASSIST YOU IN MAKING YOUR OWN
        DECISIONS. PAST PERFORMANCE DOES NOT PREDICT FUTURE RESULTS. THE DEEP
        SKY TRADING ASSISTANT PLATFORM, ANALYSIS AND MARKET DATA IS PROVIDED
        'AS-IS' AND WITHOUT WARRANTY.
      </Typography>
      <br />
      <Typography>
        If the capital letters above were not enough, just to be 100% crystal
        clear - forecasts made using technical analysis and artificial
        intelligence is not a guaranteed outcome. Invest wisely. Always do your
        own careful due diligence and research before trading or investing.
      </Typography>
      <br />
      <Typography variant="h3">Testimonials</Typography>
      <br />
      <Typography>
        Testimonials and similar statements about success are made for
        promotional purposes. Success and results described in testimonials or
        other statements may not be representative of other students'
        experiences. Results will vary, and there are no assurances you are
        likely to achieve successful investment or trading results.
      </Typography>
      <br />
      <Typography variant="h3">Links to Other Websites</Typography>
      <br />
      <Typography>
        TradeSmart University LLC and its Affiliates' websites may provide links
        to other third-party websites or resources. TradeSmart University LLC
        and its Affiliates make no representations about any other website which
        you may access. TradeSmart University LLC and its Affiliates are not
        responsible for the availability or any content, advertising, products,
        services or other materials from third parties.
      </Typography>
      <br />
      <Typography variant="h3">Data and Other Information</Typography>
      <br />
      <Typography>
        <b>
          Deep Sky Trading Assistant products could include inaccuracies or
          errors.
        </b>{" "}
        We do not make guarantees as to the completeness or correctness of any
        Deep Sky Trading Assistant product. Any data used or made available by
        Deep Sky Trading Assistant is from sources believed by it to be reliable
        and accurate; however, Deep Sky Trading Assistant does not investigate
        the sources or confirm the data and it does not make any representations
        that the data or resulting calculations are complete or otherwise
        accurate.
      </Typography>
      <br />
      <Typography>
        From time to time, Deep Sky Trading Assistant may reference prior
        articles and opinions. These references may be selective, may reference
        only a portion of an article or opinion, and are likely not to be
        current. As markets change continuously, previously published
        information and data may not be current and should not be relied upon.
      </Typography>
      <br />
      <Typography variant="h3">Notices About Trading</Typography>
      <br />
      <Typography>
        <b>
          Deep Sky Trading Assistant and its personnel are not registered
          broker-dealers or investment advisers. You are encouraged to speak
          with licensed broker-dealers, investment advisers or counselors of
          your choice regarding the appropriateness of investing or of any
          particular investment strategy or security.
        </b>{" "}
        Deep Sky Trading Assistant products are for informational and
        educational purposes only, and no mention of a particular security in
        any Deep Sky Trading Assistant product constitutes a recommendation to
        buy, sell, or hold that or any other security, or that any particular
        security, portfolio of securities, transaction or investment strategy is
        suitable for any specific person. None of the information providers
        (e.g., instructors or sales persons or customer service representatives)
        or their affiliates will advise you personally concerning the nature,
        potential, value or suitability of any particular security, portfolio of
        securities, transaction, investment strategy or other matter. To the
        extent any of the information contained in any Deep Sky Trading
        Assistant product may be deemed to be investment advice, it is
        impersonal and not tailored to the individual investment needs of any
        specific person.
      </Typography>
      <br />
      <Typography>
        Deep Sky Trading Assistant personnel (e.g., instructors) are not subject
        to trading restrictions. Deep Sky Trading Assistant personnel could have
        a position in a security or initiate a position in a security at any
        time. In the event you enter into trading transactions, Deep Sky Trading
        Assistant personnel also may be trading or have positions in the same
        securities.
      </Typography>
      <br />
      <Typography>
        Deep Sky Trading Assistant products contain opinions about securities,
        and the opinions differ. For example, an opinion about a security in a
        certain Deep Sky Trading Assistant publication could differ from an
        opinion about the same security that is in another Deep Sky Trading
        Assistant publication. As another example, two instructors could have
        different views about the same security or factors affecting that
        security (such as market events).
      </Typography>
      <br />
      <Typography>
        All investments and trading involve risk. Trading and investing subjects
        you to risk of losses, including losses greater than your original
        investment. Many strategies, investments and securities (including
        options) are not suitable for everyone.{" "}
        <b>
          Deep Sky Trading Assistant does not determine whether any strategy,
          investment or security is suitable for any individual.
        </b>
      </Typography>
      <br />
      <Typography>
        Particularly with respect to options, prior to buying or selling an
        option, an investor must receive a copy of Characteristics and Risks of
        Standardized Options, called the Options Disclosure Document (ODD). This
        document is available from your broker-dealer or may be downloaded
        directly from The Options Clearing Corporation.
      </Typography>
      <br />
      <Typography>
        <Link href="https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document">
          CLICK HERE TO DOWNLOAD the Characteristics and Risks of Standardized
          Options.
        </Link>
      </Typography>
      <br />
      <Typography>
        No one should trade with money they cannot afford to lose. As such Deep
        Sky Trading Assistant directs all participants to practice trading with
        virtual, or fake funds. Deep Sky Trading Assistant in no way encourages
        individuals to place real money on any particular trade. Such decisions
        and any transaction related to such decision is at the sole discretion
        of the individual placing the trade.
      </Typography>
      <br />
      <Typography variant="h3">
        Past results are not indicative of future performance.
      </Typography>
      <br />
      <Typography>
        Where hypothetical or simulated examples are provided, because they do
        not represent actual trading, they have limitations. They are designed
        with hindsight and thus, can be biased to select favorable performance
        results. Also, since the trades have not been executed, the results may
        have under or over compensated for certain factors such as the lack of
        market liquidity. Examples of trades and investment strategies do not
        include the costs of commissions, interest and dividends and other
        expenses. Keep in mind that these costs affect whether an investment is
        profitable
      </Typography>
      <br />
      <Typography variant="h3">Questions?</Typography>
      <br />
      <Typography>
        Please send any questions you may have regarding our Terms & Conditions
        to: <Link href="mailto:info@tradesmartu.com">info@tradesmartu.com</Link>
      </Typography>
      <br />
      <Typography>
        TradeSmart University LLC, 330 Franklin Rd Suite 135A-407, Brentwood TN,
        37027.
      </Typography>
    </Box>
  );
};

export default Terms;
