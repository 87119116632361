import PropTypes from "prop-types";
import * as React from "react"

function GridIcon({ color }) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5.667h5.833c.5 0 .834.333.834.833v5.833c0 .5-.334.834-.834.834H1.5c-.5 0-.833-.334-.833-.834V1.5C.667 1 1 .667 1.5.667zM2.333 6.5H6.5V2.333H2.333V6.5zM16.5.667h-5.833c-.5 0-.834.333-.834.833v5.833c0 .5.334.834.834.834H16.5c.5 0 .833-.334.833-.834V1.5c0-.5-.333-.833-.833-.833zm-5 5.833h4.167V2.333H11.5V6.5zm5 3.333h-5.833c-.5 0-.834.334-.834.834V16.5c0 .5.334.833.834.833H16.5c.5 0 .833-.333.833-.833v-5.833c0-.5-.333-.834-.833-.834zm-5 5.834h4.167V11.5H11.5v4.167zM7.333 9.833H1.5c-.5 0-.833.334-.833.834V16.5c0 .5.333.833.833.833h5.833c.5 0 .834-.333.834-.833v-5.833c0-.5-.334-.834-.834-.834zm-5 5.834H6.5V11.5H2.333v4.167z"
        fill={color}
      />
    </svg>
  )
}

GridIcon.propTypes = {
  color: PropTypes.string
}

export default GridIcon
