import React from "react";
import { Box, Typography } from "@material-ui/core";

interface HeaderProps {
  title?: string;
  description?: string;
}

const Header = ({ title, description }: HeaderProps) => {
  return (
    <Box mb={4}>
      <Typography variant="h2">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
};

export default Header;
