import Paper from "@material-ui/core/Paper";
import { useCallback, useRef } from "react";
import {
  ChangeSet,
  EditingState,
  SearchState,
  PagingState,
  GroupingState,
  SortingState,
  SelectionState,
  IntegratedSorting,
  IntegratedGrouping,
  IntegratedFiltering,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import { snackActions } from "src/utils/SnackbarUtils";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import {
  Grid,
  Table,
  Toolbar,
  SearchPanel,
  GroupingPanel,
  PagingPanel,
  TableHeaderRow,
  ExportPanel,
  TableEditRow,
  TableGroupRow,
  TableEditColumn,
} from "@devexpress/dx-react-grid-material-ui";
import saveAs from "file-saver";
import { FC, useEffect, useState } from "react";
import Axios from "src/utils/axios";
import SelectCell from "./SelectCell";
import debounce from "src/utils/debounce";
import EditCell from "./EditCell";
import Cell from "./Cell";
import { format } from "src/utils/date";
import { Box, Typography } from "@material-ui/core";

interface IRow {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  displayRole: string;
  updatedAt: string;
  createdAt: string;
  username: string | null;
  lastSignInAt: string | null;
  lastSignInIp: string | null;
}

const columns = [
  { name: "action", title: "Actions" },
  { name: "id", title: "ID" },
  { name: "firstName", title: "First Name", required: true },
  { name: "displayRole", title: "Display Role" },
  { name: "lastName", title: "Last Name", required: true },
  { name: "email", title: "Email", required: true },
  { name: "role", title: "Role", required: true },
  { name: "lastSignInAt", title: "Last Sign In At" },
  { name: "updatedAt", title: "Updated At" },
  { name: "createdAt", title: "Created At" },
];

const onSave = (workbook) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "DeepSky-Users.xlsx"
    );
  });
};

const validate = (rows, columns) =>
  Object.entries(rows).reduce(
    (acc, [rowId, row]: any) => ({
      ...acc,
      [rowId]: columns.some(
        (column) => column.required && row[column.name] === ""
      ),
    }),
    {}
  );

const getRowId = (row) => row.id;

const UsersTable: FC<Props> = () => {
  const [rows, setRows] = useState<IRow[]>([]);
  const [errors, setErrors] = useState({});
  const [editingStateColumnExtensions] = useState([
    { columnName: "action", editingEnabled: false },
    { columnName: "id", editingEnabled: false },
    { columnName: "updatedAt", editingEnabled: false },
    { columnName: "displayRole", editingEnabled: false },
    { columnName: "createdAt", editingEnabled: false },
    { columnName: "lastSignInAt", editingEnabled: false },
  ]);
  const [pageSizes] = useState([50, 100, 150]);
  const getUsers = useCallback(async () => {
    await Axios.get("/users/admin/all").then((res) => {
      const { users } = res.data;
      setRows(
        users.map((item) => ({
          ...item,
          displayRole: item.role,
          createdAt: format(item.createdAt, "MMMM D, YYYY h:mm A"),
          lastSignInAt: format(item.lastSignInAt, "MMMM D, YYYY h:mm A"),
          updatedAt: format(item.updatedAt, "MMMM D, YYYY h:mm A"),
        }))
      );
    });
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const commitChanges = ({ added, changed }: ChangeSet): void => {
    if (added) {
      const addedEmail = added.map((item) => item.email);
      const hasDuplicate = rows.some((item) => addedEmail.includes(item.email));

      if (hasDuplicate) {
        return alert(
          "You are trying to add the user which is already added. No duplicate emails are allowed"
        );
      } else {
        const body = added.map((item) => item);
        Axios.post("/users/invite", body).then(() => {
          snackActions.success("User created successfully");
          getUsers()
        }).catch(() => {
          snackActions.error("Error creating user! Please try again");
        });
      }
    }
    if (changed) {
      const body = {
        id: Object.keys(changed)[0],
        ...Object.values(changed)[0],
      };
      Axios.put("/users/admin/update", body).then(() => {
        snackActions.success("User updated successfully");
        getUsers();
      }).catch(() => {
        snackActions.error("Error updating user! Please try again");
      });
    }
  };

  const onEdited = debounce(
    (edited) => setErrors(validate(edited, columns)),
    250
  );

  const exporterRef = useRef({ exportGrid: () => null });

  const startExport = useCallback(() => {
    if (exporterRef?.current) {
      exporterRef?.current?.exportGrid();
    }
  }, [exporterRef]);

  const handleUserInvite = useCallback((email) => {
    Axios.post("/users/admin/send-invite-email", { email }).then(() => {
      snackActions.success("Invitation email sent successfully");
    }).catch(() => {
      snackActions.error("Invitation email could not be sent! Please try again");
    });
  }, []);

  const handlePasswordReset = useCallback((email) => {
    Axios.post("/password/send-forgot-email", { email }).then(() => {
      snackActions.success("Password reset email sent successfully");
    }).catch(() => {
      snackActions.error("Password reset email could not be sent! Please try again");
    });
  }, []);

  return (
    <>
      <Box p={2}>
        <Typography variant="h3">Roles</Typography>
        <br />
        <Typography>customer: Stripe users</Typography>
        <Typography>
          guest-customer: Keap users and Manually added users
        </Typography>
        <Typography>guest-forex-customer: Same as guest-customer</Typography>
        <Typography>admin: Have all access without any restrictions</Typography>
        <br />
      </Box>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <PagingState defaultCurrentPage={0} defaultPageSize={50} />
          <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={editingStateColumnExtensions}
            onRowChangesChange={onEdited}
          />
          <SelectionState />
          <SortingState
            defaultSorting={[{ columnName: "firstName", direction: "desc" }]}
          />
          <GroupingState defaultGrouping={[{ columnName: "displayRole" }]} />
          <IntegratedSorting />
          <IntegratedGrouping />
          <SearchState defaultValue="" />
          <IntegratedFiltering />
          <IntegratedPaging />
          <Table
            cellComponent={(tableProps) => (
              <Cell
                {...tableProps}
                handleUserInvite={handleUserInvite}
                handlePasswordReset={handlePasswordReset}
              />
            )}
          />
          <TableHeaderRow />
          <Toolbar />
          <SearchPanel />
          <PagingPanel pageSizes={pageSizes} />
          <TableEditRow cellComponent={SelectCell} />
          <TableEditColumn
            showAddCommand
            showEditCommand
            cellComponent={(props) => <EditCell {...props} errors={errors} />}
          />
          <TableGroupRow />
          <GroupingPanel showSortingControls={true} />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter
          ref={exporterRef}
          rows={rows}
          columns={columns}
          onSave={onSave}
        />
      </Paper>
    </>
  );
};

export default UsersTable;
