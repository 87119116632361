import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  Grow,
  Hidden,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Page from "src/components/Page";
import Header from "src/components/Header";
import { Sentiments } from "src/types/Sentiments";
import DeepSkyAnalysis from "./components/DeepSkyAnalysis";
import TradingViewWidget from "react-tradingview-widget";
import Indicators from "src/constants/indicators";
import TechnicalAnalysis from "./components/TechnicalAnalysis";
import IndicatorCard from "./components/IndicatorCard";
import MarketWidget from "./components/MarketWidget";
import StockInfoHeader from "./components/StockInfoHeader";
import useStyles from "./styles";
import usePrediction from "src/hooks/usePrediction";
import { useNavigate, useParams } from "react-router";
import { isPredictionBullish } from "src/utils/prediction/predictionParser";
import getIndicatorList, {
  getConflictingString,
  getSupportiveString,
  getTechnicalAnalysis,
} from "./helpers/getIndicatorList";
import useStocks from "src/hooks/useStocks";
import { snackActions } from "src/utils/SnackbarUtils";
import Loader from "src/components/Loader";
import JoyrideEntry from "src/components/JoyRide";
import { briefingSteps } from "src/constants/joy-ride";
import useWatchList from "src/hooks/useWatchList";
import { Star, StarOutline } from "@material-ui/icons";

const OrangeStar = withStyles({
  root: {
    color: "#FF8500",
  },
})(Star);

const Briefing: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const toggleWidget = () => setOpen((prev) => !prev);
  const params = useParams();
  const symbol = params["symbol"] || "AAPL";
  const [state] = usePrediction(symbol);
  const navigate = useNavigate();
  const [indicatorTab, setIndicatorTab] = useState("DEFAULT");
  const handleIndicatorTabChange = (event, value) => setIndicatorTab(value);
  const {
    currentDayPrediction,
    predictions,
    sectorPredictions,
    marketsData,
    topPickObject,
    stock,
  } = state;

  const isOverallBullish = isPredictionBullish(predictions);
  const indicators = getIndicatorList(currentDayPrediction);
  const stockHeaderRef = useRef<HTMLDivElement>(null);
  const { availableStocks } = useStocks();

  const { addToWatchList, removeFromWatchList, isInWatchList } = useWatchList();
  const watchListed = isInWatchList(symbol);
  const toggleWatchListed = () => {
    if (watchListed) {
      removeFromWatchList(symbol);
    } else {
      addToWatchList(symbol);
    }
  };

  useEffect(() => {
    if (
      availableStocks.length > 0 &&
      (symbol === null ||
        !availableStocks.find((item) => item.symbol === symbol))
    ) {
      snackActions.warning("Requested symbol not found.");
      navigate("/404");
    }
  }, [symbol, availableStocks, navigate]);

  if (state?.isLoading) {
    return <Loader style={{ height: "85vh" }} />;
  }

  return (
    <Page
      className={`${classes.root} ${
        isOverallBullish ? classes.bullishBackground : classes.bearishBackground
      }`}
      title={`${symbol} Briefing`}
    >
      {!open && (
        <Button
          className={classes.widgetButton}
          variant="text"
          color="primary"
          endIcon={<ExpandMoreIcon />}
          onClick={toggleWidget}
          id="joy-ride-market-and-sector"
        >
          MARKET & SECTOR FORECAST
        </Button>
      )}
      <Hidden mdUp={true}>
        <Drawer open={open} anchor="top">
          <MarketWidget
            toggleWidget={toggleWidget}
            sectorPredictions={sectorPredictions}
            marketsData={marketsData}
          />
        </Drawer>
      </Hidden>

      <Hidden smDown={true}>
        <Grow in={open} style={{ transformOrigin: "top right", zIndex: 1 }}>
          <Box className={classes.widgetContainer}>
            <MarketWidget
              toggleWidget={toggleWidget}
              sectorPredictions={sectorPredictions}
              marketsData={marketsData}
              sectorText={stock?.sectorName}
            />
          </Box>
        </Grow>
      </Hidden>

      <Container maxWidth={false}>
        <Header
          title="Stock Briefing"
          crumbs={[
            { crumb: "Dashboard", link: "/dashboard" },
            { crumb: "Stocks" },
            { crumb: `${stock.symbol} ${stock.name}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.titleContainer}>
            <StockInfoHeader
              stock={{
                symbol: stock.symbol,
                label: stock.name,
                price: currentDayPrediction.previousClose ?? 0,
              }}
            />
            <Button
              className={classes.watchListButton}
              variant="text"
              id="joy-ride-add-to-watchlist"
              startIcon={
                watchListed ? <OrangeStar /> : <StarOutline color="secondary" />
              }
              onClick={toggleWatchListed}
            >
              Add To My Watchlist
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Divider className={`${classes.divider} ${classes.topDivider}`} />
            <DeepSkyAnalysis
              score={topPickObject?.averageScore}
              scoreText={topPickObject?.averageScoreText}
              sentiment={
                isOverallBullish ? Sentiments.BULLISH : Sentiments.BEARISH
              }
              periodForecast={[1, 2, 5, 10, 15]}
              predictions={predictions}
            />
            <Divider
              className={`${classes.divider} ${classes.bottomDivider}`}
            />
          </Grid>
          <Grid item xs={12}>
            <TechnicalAnalysis
              supportingString={getSupportiveString(indicators)}
              conflictingString={getConflictingString(indicators)}
              description={getTechnicalAnalysis(indicators, isOverallBullish)}
              onJumpToFullAnalysis={() =>
                // @ts-ignore
                stockHeaderRef?.current?.scrollIntoView()
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">Chart View</Typography>
            {/* @ts-ignore */}
            <Box ref={stockHeaderRef} className={classes.tabsContainer}>
              <Tabs
                value={indicatorTab}
                onChange={handleIndicatorTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Default"
                  value="DEFAULT"
                  classes={{ root: classes.tab }}
                />
                <Tab label="SMA" value="sma" classes={{ root: classes.tab }} />
                <Tab label="EMA" value="ema" classes={{ root: classes.tab }} />
                <Tab
                  label="Stochastic"
                  value="stochastic"
                  classes={{ root: classes.tab }}
                />
                <Tab label="RSI" value="rsi" classes={{ root: classes.tab }} />
                <Tab
                  label="MACD"
                  value="macd"
                  classes={{ root: classes.tab }}
                />
                <Tab
                  label="Bollinger"
                  value="bb"
                  classes={{ root: classes.tab }}
                />
              </Tabs>
            </Box>

            <Box mb={2} style={{ height: 500, width: "100%" }}>
              <TradingViewWidget
                autosize
                symbol={symbol}
                // eslint-disable-next-line react/style-prop-object
                style="9"
                hide_side_toolbar={false}
                {...(Indicators[indicatorTab] || {}).tradingViewConfig}
              />
            </Box>
          </Grid>
          {indicators.map((indicator) => (
            <Grid key={indicator.title} item xs={12} md={6} lg={4}>
              <IndicatorCard {...indicator} />
            </Grid>
          ))}
        </Grid>
      </Container>
      <JoyrideEntry steps={briefingSteps} id="briefing" />
    </Page>
  );
};

export default Briefing;
