import { Children, cloneElement } from "react";
import { TableEditColumn } from "@devexpress/dx-react-grid-material-ui";

const EditCell = ({ errors, ...props }: any) => {
  const { children } = props;

  return (
    <TableEditColumn.Cell {...props}>
      {Children.map(children, (child) =>
        child?.props.id === "commit"
          ? cloneElement(child, {
              disabled: errors[props.tableRow.rowId],
            })
          : child
      )}
    </TableEditColumn.Cell>
  );
};

export default EditCell;
