//@ts-nocheck
import { useEffect, useState } from "react";

const useRunAfterDOMLoaded = (callback) => {
  const [rerender, setRerender] = useState();
  const [afterRender, setAfterRender] = useState();

  useEffect(() => {
    if (!afterRender) return;
    if (callback) {
      callback();
    }
    setAfterRender(false);
  }, [afterRender, callback]);

  useEffect(() => {
    setAfterRender(true);
  }, [rerender]);

  return {
    setRerender, // expose function trigger re-render data
  };
};

export default useRunAfterDOMLoaded;
