import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Theme } from "@material-ui/core";
import { MailOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) => ({
  feedbackContainer: {
    border: "1px solid #DBD7D2",
    borderRadius: 4,
    padding: "10px",
    display: "flex",
    maxWidth: "224px",
    marginTop: 15,
    marginLeft: 15,
    marginBottom: 15,
    zIndex: 10,
  },
  feedbackGrid: {
    zIndex: 10,
  },
  externalLink: {
    color: "#FF8A00",
  },
  feedbackMobileContainer: {
    border: "1px solid #DBD7D2",
    borderRadius: 4,
    padding: "5px",
    position: "absolute",
    cursor: "pointer",
    bottom: 20,
    marginLeft: 7,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 10,
  },
}));

const handleClick = () => {
  window.open(
    "https://docs.google.com/forms/d/e/1FAIpQLSdw9rxlINOsLmLfpQe8HwieE6BXlCdIlQoPvV2Avxdj0CIpew/viewform",
    "Deep Sky Feedback",
    "width=640,height=641"
  );
};

const FeedbackBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.feedbackContainer}>
      <MailOutline color="primary" />
      <Box pl={1}>
        <Typography color="textPrimary" variant="h6">
          Help improve Deep Sky!
        </Typography>
        <Typography variant="caption">
          <Typography
            onClick={handleClick}
            href="#"
            className={classes.externalLink}
            component="a"
            variant="caption"
          >
            Send us your suggestions
          </Typography>{" "}
          for features and improvements in future releases.
        </Typography>
      </Box>
    </Box>
  );
};

const Mobile = () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.feedbackMobileContainer}
      component="a"
      onClick={handleClick}
    >
      <MailOutline color="primary" />
      <Typography variant="caption">Feedback</Typography>
    </Box>
  );
};

FeedbackBox.Mobile = Mobile;
export default FeedbackBox;
