import PropTypes from "prop-types";
import { useState } from "react";
import { Paper, Box } from "@material-ui/core";
import {
  SearchState,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  DataTypeProvider,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Toolbar,
  SearchPanel,
  VirtualTable,
  PagingPanel,
  TableColumnResizing,
  TableHeaderRow,
} from "@devexpress/dx-react-grid-material-ui";
import { useNavigate } from "react-router-dom";
import moneyFormatter from "src/utils/monetFormatter";
import TechnicalsCell from "./Cells/Technical";
import ScoreCell from "./Cells/Score";
import DirectionalCell from "./Cells/DirectionalCell";
import BodyCell from "./Cells/Body";
import HeaderCell from "./Cells/Header";
import PriceFilter from "./PriceFilter";
import filterByPrice from "./filterByPrice";

const DataProvider = ({ component, ...rest }) => {
  return <DataTypeProvider formatterComponent={component} {...rest} />;
};
DataProvider.propTypes = {
  component: PropTypes.shape({}),
};

const getRowId = (row) => row.id;

const ForexGrid = ({ data = [] }) => {
  const [columns] = useState([
    { name: "symbol", title: "Ticker" },
    { name: "name", title: "Stock Name" },
    {
      name: "formatedPredictedFor",
      title: "Predicted For",
    },
    { name: "sentiment", title: "15m Dir." },
    { name: "averageScore", title: "DS Score" },
    { name: "combinedDirection", title: "Technicals" },
    { name: "arrow", title: " " },
  ]);

  const [pageSizes] = useState([25, 50, 100]);
  const [defaultColumnWidths] = useState([
    { columnName: "symbol", width: 100 },
    { columnName: "name", width: 200 },
    { columnName: "formatedPredictedFor", width: 225 },
    { columnName: "sentiment", width: 100 },
    { columnName: "averageScore", width: 100 },
    { columnName: "combinedDirection", width: 120 },
    { columnName: "arrow", width: 50 },
  ]);

  const [selectedPriceFilters, setSelectedPriceFilters] = useState([]);
  const [priceFilterOpen, setPriceFilterOpen] = useState(false);
  const navigate = useNavigate();

  const handleFilterOpen = () => {
    setPriceFilterOpen((prev) => !prev);
  };

  const handleFilterClose = () => {
    setPriceFilterOpen(false);
  };

  return (
    <Paper style={{ overflow: "hidden", position: "relative" }}>
      <Grid
        rows={filterByPrice(data, selectedPriceFilters)}
        columns={columns}
        getRowId={getRowId}
      >
        <PagingState defaultCurrentPage={0} defaultPageSize={25} />
        <SortingState
          defaultSorting={[{ columnName: "averageScore", direction: "desc" }]}
        />
        <IntegratedSorting />
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <IntegratedPaging />
        <DataProvider
          for={["sectorSentiment", "sentiment"]}
          component={DirectionalCell}
        />
        <DataProvider for={["averageScore"]} component={ScoreCell} />
        <DataProvider
          for={["previousClose"]}
          component={({ value }) => moneyFormatter(value)}
        />
        <DataProvider for={["combinedDirection"]} component={TechnicalsCell} />

        <VirtualTable
          height={700}
          cellComponent={(props) => <BodyCell {...props} navigate={navigate} />}
        />
        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
        <TableHeaderRow
          showSortingControls
          cellComponent={({ children, column }) => (
            <HeaderCell
              column={column}
              selectedPriceFiltersCount={selectedPriceFilters?.length}
              handleClick={handleFilterOpen}
            >
              {children}
            </HeaderCell>
          )}
        />
        <Toolbar />
        <SearchPanel />
        <PagingPanel pageSizes={pageSizes} />
      </Grid>
      {priceFilterOpen && (
        <Box style={{ top: 150, left: 360, position: "absolute" }}>
          <PriceFilter
            onClose={handleFilterClose}
            selectedPriceFilters={selectedPriceFilters}
            setSelectedPriceFilters={setSelectedPriceFilters}
          />
        </Box>
      )}
    </Paper>
  );
};

ForexGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ForexGrid;
