export const getInitials = (name: string = "") => {
  return name
    .replace(/[^a-zA-Z]+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
};

export const pluralize = (count, noun, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};
