import { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import StarOutlineIcon from "@material-ui/icons/StarOutline";

import { Grid, makeStyles, Typography, Box } from "@material-ui/core";

export interface EmptyWatchListProps {
  className?: string;
  title?: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: 144,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    border: "3px solid rgba(0, 94, 135, 0.3)",
    color: "#707680",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px",
    },
  },

  wrapIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  icon: {
    marginRight: 10,
  },
}));

const EmptyWatchList: FC<EmptyWatchListProps> = ({
  className,
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={12} justify="center">
          <Box textAlign="center" paddingBottom={1}>
            <Typography variant="h2" className={classes.title}>
              You currently have nothing in your Watchlist.
            </Typography>
          </Box>
          <Box textAlign="center">
            <Typography
              variant="body2"
              align="center"
              className={classes.wrapIcon}
            >
              <StarOutlineIcon className={classes.icon} />
              Clicking a star will add that item to your list.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

EmptyWatchList.propTypes = {
  children: PropTypes.node,
};

export default EmptyWatchList;
