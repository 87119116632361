import React from "react";
import type { FC } from "react";
import { Box, Link, List, ListItem, Typography } from "@material-ui/core";

const Privacy: FC<Props> = (props) => {
  return (
    <Box lineHeight={1}>
      <Typography variant="h2">Privacy Policy</Typography>
      <br />
      <Typography>
        Thank you for visiting the online and mobile resources published by
        TradeSmart University, LLC.
      </Typography>
      <br />
      <Typography>
        Your privacy is very important to us. Accordingly, we have developed
        this policy in order for you to understand how we collect, use,
        communicate and make use of personal information. The following outlines
        our privacy policy.
      </Typography>
      <br />
      <Typography>
        This policy covers ​TradeSmart University LLC's use of personal
        information that it collects when you use the DeepSkyTrading.com website
        or its affiliated sites. From time to time, you will be asked to submit
        identifying information about yourself (e.g. name and email address) in
        order to receive or use our products, such as newsletters, online
        webinars etc. By entering your details in the fields requested, you
        enable ​TradeSmart University LLC and its service providers to provide
        you with the products you select.
      </Typography>
      <br />
      <Typography>
        Whenever you provide personal information, we will treat that
        information in accordance with this policy. When using your personal
        information ​TradeSmart University will act in accordance with current
        legislation and aim to meet current best practices. Any information you
        provide to ​TradeSmart University LLC will only be used within
        ​TradeSmart University LLC and by its agents and or service providers.
      </Typography>
      <br />
      <Typography>
        Your information also will be disclosed, however, when required by law
        or we believe in good faith that such action is necessary in order to
        comply with the law or legal process. Also, if you post or send
        offensive, inappropriate or objectionable content anywhere on or to
        DeepSkyTrading.com or its affiliated sites, or otherwise engage in any
        disruptive behavior, ​TradeSmart University LLC can use information
        about you in connection with stopping such behavior and exercising its
        rights and remedies. This may involve informing relevant third parties
        such as Internet service providers and law enforcement agencies about
        the content and your behavior.
      </Typography>
      <br />
      <Typography>
        When accessing the ​www.DeepSkyTrading.com website, TradeSmart
        University LLC will learn certain information about you during your
        visit.
      </Typography>
      <Typography>
        Similar to other commercial websites, our website utilizes a standard
        technology called "cookies" (see explanation below) and server logs to
        collect information about how our site is used. Information gathered
        through cookies and server logs may include the date and time of visits,
        the pages viewed, time spent at our site, and the websites visited just
        before and just after our own, as well as your IP address.
      </Typography>
      <br />
      <Typography variant="h3">Use of Cookies</Typography>
      <br />
      <Typography>
        A cookie is a very small text document, which often includes an
        anonymous unique identifier. When you visit a website, that site's
        computer asks your computer for permission to store this file in a part
        of your hard drive specifically designated for cookies. Each website can
        send its own cookie to your browser if your browser's preferences allow
        it, but (to protect your privacy) your browser only permits a website to
        access the cookies it has already sent to you, not the cookies sent to
        you by other sites.
      </Typography>
      <br />
      <Typography variant="h3">IP Addresses</Typography>
      <br />
      <Typography>
        IP addresses are used by your computer every time you are connected to
        the Internet. Your IP address is a number that is used by computers on
        the network to identify your computer. IP addresses are automatically
        collected by our webserver as part of demographic and profile data known
        as “traffic data '' so that data (such as the Web pages you request) can
        be sent to you.
      </Typography>
      <br />
      <Typography variant="h3">Email Information</Typography>
      <br />
      <Typography>
        If you choose to correspond with us through email, we may retain the
        content of your email messages together with your email address and our
        responses. We provide the same protections for these electronic
        communications that we employ in the maintenance of information received
        online, mail and telephone. This also applies when you register for our
        website, sign up through any of our forms using your email address or
        make a purchase on this site. For further information see the email
        policies below.
      </Typography>
      <br />
      <Typography variant="h3">
        How Do We Use the Information That You Provide to Us?
      </Typography>
      <br />
      <Typography>
        Broadly speaking, we use personal information for purposes of
        administering our business activities, providing customer service and
        making available other items and services to our customers and
        prospective customers.
      </Typography>
      <br />
      <Typography>
        TradeSmart University will not obtain personally-identifying information
        about you when you visit our site, unless you choose to provide such
        information to us, nor will such information be sold or otherwise
        transferred to unaffiliated third parties without the approval of the
        user at the time of collection.
      </Typography>
      <br />
      <Typography>
        We may disclose information when legally compelled to do so, in other
        words, when we, in good faith, believe that the law requires it or for
        the protection of our legal rights.
      </Typography>
      <br />
      <Typography variant="h3">Email Policies</Typography>
      <br />
      <Typography>
        We are committed to keeping your e-mail address confidential. We do not
        sell, rent, or lease our subscription lists to third parties, and we
        will not provide your personal information to any third party
        individual, government agency, or company at any time unless strictly
        compelled to do so by law.
      </Typography>
      <br />
      <Typography>
        We will use your e-mail address solely to provide timely information
        about TradeSmart University. We will maintain the information you send
        via e-mail in accordance with applicable federal law.
      </Typography>
      <br />
      <Typography variant="h3">CAN-SPAM Compliance</Typography>
      <br />
      <Typography>
        In compliance with the CAN-SPAM Act, all e-mail sent from our
        organization will clearly state who the e-mail is from and provide clear
        information on how to contact the sender. In addition, all e-mail
        messages will also contain concise information on how to remove yourself
        from our mailing list so that you receive no further e-mail
        communication from us.
      </Typography>
      <br />
      <Typography variant="h3">Choice/Opt-Out</Typography>
      <br />
      <Typography>
        For users who "Opt-In" to our mailing list, TradeSmart University will
        send regular emails such as but not limited to announcements, new
        products, special sales and promotions, reminders, updates and offers
        from related partners which may be of interest to our subscribers. Our
        site provides users the opportunity to opt-out of receiving
        communications from us and our partners by reading the unsubscribe
        instructions located at the bottom of any e-mail they receive from us at
        any time.
      </Typography>
      <br />
      <Typography>
        Users who no longer wish to receive our newsletter or promotional
        materials may opt-out of receiving these communications by clicking on
        the unsubscribe link in the e-mail.
      </Typography>
      <br />
      <Typography variant="h3">Use of External Links</Typography>
      <br />
      <Typography>
        www.DeepSkyTrading.com may contain links to other websites. TradeSmart
        University cannot guarantee the accuracy of information found at any
        linked site. Links to or from external sites not owned or controlled by
        TradeSmart University do not constitute an endorsement by TradeSmart
        University or any of its employees of the sponsors of these sites or the
        products or information presented therein.
      </Typography>
      <br />
      <Typography>
        By accessing this web site, you are agreeing to be bound by these web
        site Terms and Conditions of Use, all applicable laws and regulations,
        and agree that you are responsible for compliance with any applicable
        local laws.If you do not agree with any of these terms, you are
        prohibited from using or accessing this site. The materials contained in
        this web site are protected by applicable copyright and trademark law.
      </Typography>
      <br />
      <Typography variant="h3">Intellectual Property Rights</Typography>
      <br />
      <Typography>
        All copyrights, trademarks, patents and other intellectual property
        rights in and on our website and all content and software located on the
        site shall remain the sole property of TradeSmart University or its
        licensors.The use of our trademarks, content and intellectual property
        is forbidden without the express written consent from TradeSmart
        University.
      </Typography>
      <br />
      <Typography>
        You must not:
        <List>
          <ListItem>
            - Republish material from our website without prior written consent.
          </ListItem>
          <ListItem>- Sell or rent material from our website.</ListItem>
          <ListItem>
            - Reproduce, duplicate, create derivative, copy or otherwise exploit
            material on our website for any purpose.
          </ListItem>
          <ListItem>
            - Redistribute any content from our website, including onto another
            website.
          </ListItem>
        </List>
      </Typography>
      <br />
      <Typography variant="h3">Acceptable Use</Typography>
      <br />
      <Typography>
        You agree to use our website only for lawful purposes, and in a way that
        does not infringe the rights of, restrict or inhibit anyone else's use
        and enjoyment of the website. Prohibited behavior includes harassing or
        causing distress or inconvenience to any other user, transmitting
        obscene or offensive content or disrupting the normal flow of dialogue
        within our website.
      </Typography>
      <br />
      <Typography>
        You must not use our website to send unsolicited commercial
        communications. You must not use the content on our website for any
        marketing related purpose without our express written consent.
      </Typography>
      <br />
      <Typography variant="h3">Restricted Access</Typography>
      <br />
      <Typography>
        We may in the future need to restrict access to parts (or all) of our
        website and reserve full rights to do so. If, at any point, we provide
        you with a username and password for you to access restricted areas of
        our website, you must ensure that both your username and password are
        kept confidential.
      </Typography>
      <br />
      <Typography variant="h3">Use of Testimonials</Typography>
      <br />
      <Typography>
        In accordance to with the FTC guidelines concerning the use of
        endorsements and testimonials in advertising, please be aware of the
        following:
        <List>
          <ListItem>
            - Testimonials that appear on this site are actually received via
            text, audio or video submission. They are individual experiences,
            reflecting real life experiences of those who have used our products
            and/or services in some way. They are individual results and results
            do vary. We do not claim that they are typical results. The
            testimonials are not necessarily representative of all of those who
            will use our products and/or services.
          </ListItem>
          <ListItem>
            - The testimonials displayed in any form on this site (text, audio,
            video or other) are reproduced verbatim, except for correction of
            grammatical or typing errors. Some may have been shortened. In other
            words, not the whole message received by the testimonial writer is
            displayed when it seems too lengthy or not the whole statement seems
            relevant for the general public..
          </ListItem>
        </List>
      </Typography>
      <br />
      <Typography>
        TradeSmart University is not responsible for any of the opinions or
        comments posted on www.DeepSkyTrading.com. TradeSmart University is not
        a forum for testimonials, however provides testimonials as a means for
        customers to share their experiences with one another. To protect
        against abuse, all testimonials appear after they have been reviewed by
        management of TradeSmart University. TradeSmart University does not
        share the opinions, views or commentary of any testimonials on
        www.tradesmartu.com - the opinions are strictly the views of the
        testimonial source.
      </Typography>
      <br />
      <Typography variant="body2">
        The testimonials are never intended to make claims that our products
        and/or services can be used to ​guarantee any financial results. Trading
        involves risk and individuals must consider their own personal financial
        circumstances before placing any trades. Any such claims, implicit or
        explicit, in any shape or form, have not been​ tested or evaluated.
      </Typography>
      <br />
      <Typography variant="h3">
        How Do We Protect Your Information and Secure Information Transmissions?
      </Typography>
      <br />
      <Typography>
        Email is not recognized as a secure medium of communication. For this
        reason, we request that you do not send private information to us by
        email. However, doing so is allowed, but at your own risk. Some of the
        information you may enter on our website may be transmitted securely via
        a secure medium known as Secure Sockets Layer, or SSL. CreditCard
        information and other sensitive information is never transmitted via
        email.
      </Typography>
      <br />
      <Typography>
        TradeSmart University may use software programs to create summary
        statistics, which are used for such purposes as assessing the number of
        visitors to the different sections of our site, what information is of
        most and least interest, determining technical design specifications,
        and identifying system performance or problem areas.
      </Typography>
      <br />
      <Typography>
        For site security purposes and to ensure that this service remains
        available to all users, TradeSmart University uses software programs to
        monitor network traffic to identify unauthorized attempts to upload or
        change information, or otherwise cause damage.
      </Typography>
      <br />
      <Typography variant="h3">
        Disclaimer and Limitation of Liability
      </Typography>
      <br />
      <Typography>
        TradeSmart University makes no representations, warranties, or
        assurances as to the accuracy, currency or completeness of the content
        contained on this website or any sites linked to this site.
      </Typography>
      <br />
      <Typography>
        All the materials on this site are provided "as is” without any express
        or implied warranty of any kind, including warranties of
        merchantability, noninfringement of intellectual property or fitness for
        any particular purpose. In no event shall TradeSmart University or its
        agents or associates be liable for any damages
        whatsoever(including,without limitation, damages for loss of profits,
        business interruption, loss of information, injury or death)arising out
        of the use of or inability to use the materials, even if TradeSmart
        University has been advised of the possibility of such loss or damages.
      </Typography>
      <br />
      <Typography variant="h3">Policy Changes</Typography>
      <br />
      <Typography>
        We reserve the right to amend this privacy policy at any time with or
        without notice. However, please be assured that if the privacy policy
        changes in the future, we will not use the personal information you have
        submitted to us under this privacy policy in a manner that is materially
        inconsistent with this privacy policy, without your prior consent.
      </Typography>
      <br />
      <Typography>
        We are committed to conducting our business in accordance with these
        principles in order to ensure that the confidentiality of personal
        information is protected and maintained.
      </Typography>
      <br />
      <Typography variant="h3">Discussion Forums</Typography>
      <br />
      <Typography>
        Submissions and Postings By sending or transmitting to us suggestions,
        ideas, notes, concepts, information, or other materials (collectively,
        "Materials"), or by posting Materials to any area of our websites, you
        grant us a worldwide, non-exclusive, sublicensable (through multiple
        tiers), assignable, royalty-free, perpetual, irrevocable right to use,
        reproduce, distribute (through multiple tiers), create derivative works
        of, publicly perform, publicly display, digitally perform, make, have
        made, sell, offer for sale and import such Materials in any media now
        known or hereafter developed, for any purpose whatsoever, commercial or
        otherwise, without compensation to the provider of the Materials.
      </Typography>
      <br />
      <Typography>
        None of the Materials disclosed in any forum on our websites shall be
        subject to any obligation, whether of confidentiality, attribution, or
        otherwise, on our part and we shall not be liable for any use or
        disclosure of any Materials. Discussion Forums Information provided in
        discussion forums may be provided by third parties (e.g., students) not
        connected with TradeSmart University, some of whom may use anonymous
        screen names. People may post messages or make statements that are
        inaccurate, misleading, or deceptive, whether intentionally or
        unintentionally. We neither endorse nor are responsible for any opinion,
        advice, information, or statement made or displayed by third parties,
        nor are we responsible for any errors or omissions in such postings, or
        for hyperlinks embedded in messages. The opinions expressed by others
        are solely the opinions of those persons, and do not reflect the
        opinions of the TradeSmart University or any of its Affiliates. We have
        no obligation to monitor discussion forums, or any other materials that
        you or other third parties transmit or post on or to our websites. Under
        no circumstances will TradeSmart University or its Affiliates be liable
        for any loss or damage caused by your reliance on information obtained
        in discussion forums. We have the right (but not the obligation) to
        monitor our web sites and the materials you transmit or post; to alter
        or remove any such materials (including, without limitation, any
        posting); and to disclose such materials and the circumstances
        surrounding their transmission to any third party. CODE OF CONDUCT While
        using any TradeSmart University product, you agree not to:
        <List>
          <ListItem>
            - Restrict or inhibit any other visitor or member from using any
            TradeSmart University product, including, without limitation, by
            means of "hacking" or "cracking" or defacing any portion of any
            TradeSmartU.com websites;
          </ListItem>
          <ListItem>
            - Use any TradeSmart University product for any unlawful purpose;
            Express or imply that any statements you make are endorsed by us;
            Transmit (a) any content or information that is unlawful,
            fraudulent, threatening, harassing, abusive, libelous, defamatory,
            obscene or otherwise objectionable, or infringes on our or any third
            party's intellectual property or other rights; (b) any material,
            non-public information about companies; (c) any trade secret of any
            third party; or (d) any advertisements, solicitations, chain
            letters, pyramid schemes, investment opportunities, or other
            unsolicited commercial communication (except as otherwise expressly
            permitted by us); * Engage in spamming or flooding; * Transmit any
            software or other materials that contain any virus, worm, time bomb,
            Trojan horse, or other harmful or disruptive component;
          </ListItem>
          <ListItem>
            - Modify, adapt, sublicense, translate, sell, reverse engineer,
            decompile or disassemble any portion of any TradeSmartU.com websites
            or to otherwise violate any of our rights relating to our ownership
            and provision of our website or other TradeSmart University
            products;
          </ListItem>
          <ListItem>
            - Remove any copyright, trademark, or other proprietary rights
            notices contained in a TradeSmart University web sites;
          </ListItem>
          <ListItem>
            - "Frame" or "mirror" any part of TradeSmart University websites;
            Use any robot, spider, site search/retrieval application, or other
            manual or automatic device or process to retrieve, index, "data
            mine," or in any way reproduce or circumvent the navigational
            structure or presentation of any TradeSmart University product or
            its contents;
          </ListItem>
          <ListItem>
            - Harvest or collect information about visitors to or members of
            DeepSkyTrading.com; or Permit anyone without an account or
            subscription to use any TradeSmart University product through your
            subscription, user name or password.
          </ListItem>
          <ListItem>
            - While using TradeSmart University products you agree to comply
            with all applicable laws, rules and regulations.
          </ListItem>
        </List>
      </Typography>
      <br />
      <Typography variant="h3">Contact</Typography>
      <br />
      <Typography>
        If you have any questions regarding this policy, or your dealings with
        our website, please contact us here:{" "}
        <Link href="mailto:info@tradesmartu.com">info@tradesmartu.com</Link>
      </Typography>
      <br />
      <Typography>
        TradeSmart University 330 Franklin Rd 135A-407 Brentwood, TN 37027
      </Typography>
    </Box>
  );
};

export default Privacy;
