import React from "react";
import ErrorBoundaryPage from "src/pages/system/ErrorBoundary";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state as any;
    const { children } = this.props;

    if (hasError) {
      return <ErrorBoundaryPage />;
    }

    return children;
  }
}

export default ErrorBoundary;
