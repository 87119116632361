import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "src/store";
import Prediction from "src/models/Prediction";
import Axios from "src/utils/axios";
import getCurrentDate from "src/utils/prediction/getCurrentDate";
import ForexPrediction from "src/models/ForexPrediction";

interface PredictionState {
  forexPredictions: {
    data: ForexPrediction[] | any;
    isLoading: boolean;
  };
  forexShortPredictions: {
    data: ForexPrediction[] | any;
    isLoading: boolean;
  };
  topPicks: {
    data: Prediction[];
    isLoading: boolean;
  };
  watchList: {
    data: Prediction[];
    isLoading: boolean;
  };
  mostWatched: {
    data: Prediction[];
    isLoading: boolean;
  };
}

type Symbol = {
  symbol: string;
};

const initialState: PredictionState = {
  topPicks: {
    data: [],
    isLoading: false,
  },
  forexPredictions: {
    data: [],
    isLoading: false,
  },
  forexShortPredictions: {
    data: [],
    isLoading: false,
  },
  watchList: {
    data: [],
    isLoading: false,
  },
  mostWatched: {
    data: [],
    isLoading: false,
  },
};

const slice = createSlice({
  name: "predictions",
  initialState,
  reducers: {
    setLoading(
      state: PredictionState,
      action: PayloadAction<{ isLoading: boolean; key: string }>
    ) {
      const { isLoading, key } = action.payload;
      state[key].isLoading = isLoading;
    },
    setPredictions(
      state: PredictionState,
      action: PayloadAction<{ data: Prediction[]; key: string }>
    ) {
      const { data, key } = action.payload;
      state[key].data = data;
      state[key].isLoading = false;
    },
    addToWatchList(
      state: PredictionState,
      action: PayloadAction<{ data: Symbol }>
    ) {
      const { symbol } = action.payload.data;
      const stockItem = state.topPicks.data.find(
        (item) => item.symbol === symbol
      );
      const forexItem = state.forexPredictions.data.find(
        (item) => item.symbol === symbol
      );
      if (stockItem) {
        const hasAlreadyIncluded = state.watchList.data.some(
          (item) => item.symbol === stockItem?.symbol
        );
        if (!hasAlreadyIncluded) {
          state.watchList.data = [...state.watchList.data, stockItem];
        }
      }
      if (forexItem) {
        const hasAlreadyIncluded = state.watchList.data.some(
          (item) => item.symbol === stockItem?.symbol
        );
        if (!hasAlreadyIncluded) {
          state.watchList.data = [...state.watchList.data, forexItem];
        }
        console.log(state.watchList.data);
      }
    },
    removeFromWatchList(
      state: PredictionState,
      action: PayloadAction<{ data: Symbol }>
    ) {
      const { symbol } = action.payload.data;
      console.log(symbol);
      state.watchList.data = state.watchList.data.filter(
        (item) => item.symbol !== symbol
      );
    },
  },
});

export const reducer = slice.reducer;
export const fetchPredictions = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setLoading({ key: "topPicks", isLoading: true }));
  dispatch(slice.actions.setLoading({ key: "watchList", isLoading: true }));
  dispatch(slice.actions.setLoading({ key: "mostWatched", isLoading: true }));
  dispatch(
    slice.actions.setLoading({ key: "forexPredictions", isLoading: true })
  );
  dispatch(
    slice.actions.setLoading({ key: "forexShortPredictions", isLoading: true })
  );

  const params = {
    periods: 5,
    limit: 600,
    date: getCurrentDate(),
  };
  const { data } = await Axios.get(`/top-picks`, { params });
  const predictionData = (data || []).map((item) => new Prediction(item));
  const { data: watchlistStocks } = await Axios.get(`/watchlist`);
  const { data: popularStocks } = await Axios.get(`/watchlist/popular?n=15`);

  const { data: forexPredictionsResults } = await Axios.get(
    `/currencies/long-predictions?date=${params.date}`
  );
  const forexPredictions = forexPredictionsResults.map(
    (prediction) => new ForexPrediction(prediction)
  );

  const { data: forexShortPredictionsResults } = await Axios.get(
    `/currencies/short-predictions`
  );
  const forexShortPredictions = forexShortPredictionsResults.map(
    (prediction) => new ForexPrediction(prediction)
  );

  const watchlistStocksList = watchlistStocks.map((item) => item?.symbol);
  const popularStocksList = popularStocks.map((item) => item?.symbol);

  const userWatchList = ([...predictionData, ...forexShortPredictions] || []).filter((item) =>
    watchlistStocksList.includes(item.symbol)
  );
  const mostWatchedList = (predictionData || []).filter((item) =>
    popularStocksList.includes(item.symbol)
  );

  dispatch(
    slice.actions.setPredictions({
      key: "topPicks",
      data: predictionData,
    })
  );
  dispatch(
    slice.actions.setPredictions({
      key: "watchList",
      data: userWatchList,
    })
  );
  dispatch(
    slice.actions.setPredictions({
      key: "mostWatched",
      data: mostWatchedList,
    })
  );
  dispatch(
    slice.actions.setPredictions({
      key: "forexPredictions",
      data: forexPredictions,
    })
  );
  dispatch(
    slice.actions.setPredictions({
      key: "forexShortPredictions",
      data: forexShortPredictions,
    })
  );
};

export const addToWatchListAction = (symbol): AppThunk => async (dispatch) => {
  dispatch(
    slice.actions.addToWatchList({
      data: {
        symbol,
      },
    })
  );
};

export const removeFromWatchListAction = (symbol): AppThunk => async (
  dispatch
) => {
  dispatch(
    slice.actions.removeFromWatchList({
      data: {
        symbol,
      },
    })
  );
};

export default slice;
