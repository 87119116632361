import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { removeEmpty } from "src/utils/object";
import PaymentSummary from "../helpers/PaymentSummary";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import Header from "../helpers/Header";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import stripeOptions, { containerStyle } from "./stripe/stripeStyle";

const initialState = {
  firstName: "",
  lastName: "",
};

const Payment = () => {
  const [errors] = useState(initialState);
  const [values, setValues] = useState(initialState);
  const handleChange = (e) => setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))

  return (
    <Box pl={3} pr={3}>
      <Header
        title="Enter your payment info"
        description="Once you’ve entered your payment info, confirm your purchase and click submit payment. You’ll be given access to Deep Sky Trading Assistant immediately following payment."
      />

      <form>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              required
              error={Boolean(errors?.firstName)}
              fullWidth
              helperText={errors.firstName}
              label="First Name"
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              required
              error={Boolean(errors?.lastName)}
              fullWidth
              helperText={errors.lastName}
              label="Last Name"
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography>Card Number *</Typography>
              <Box style={containerStyle}>
                <CardNumberElement options={stripeOptions} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography>Expiry *</Typography>
            <Box style={containerStyle}>
              <CardExpiryElement options={stripeOptions} />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography>CVC *</Typography>
            <Box style={containerStyle}>
              <CardCvcElement options={stripeOptions} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <PaymentSummary
              total="$99 USD"
              subscriptionInfo="1 Year Subscription - expires July 6, 2022"
            />
          </Grid>
          <Grid item xs={12}>
            <ErrorPanel errors={removeEmpty(errors)} values={values} />
          </Grid>
          <Grid item md={8} xs={12}>
            <Box mt={1}>
              <Button
                fullWidth
                color="primary"
                type="submit"
                variant="contained"
              >
                Submit Payment
              </Button>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box mt={1}>
              <Button fullWidth color="primary">
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Payment;
