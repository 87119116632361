import formatNumbers from "../formatNumbers";

const getMinimumValue = (predictedCloseValue, mape) => {
  return predictedCloseValue - (mape * predictedCloseValue) / 100.0;
};

const getMaximumValue = (predictedCloseValue, mape) => {
  return predictedCloseValue + (mape * predictedCloseValue) / 100.0;
};

const getRangePrediction = ({ predictedCloseValue = 0, mape = 4, isForex = false }) => {
  const from = getMinimumValue(predictedCloseValue, mape);
  const to = getMaximumValue(predictedCloseValue, mape);
  const decimal = isForex ? 5 : 2;
  return `${String(formatNumbers(from, decimal))} - ${String(formatNumbers(to, decimal))}`;
};

getRangePrediction.getMinimumValue = getMinimumValue;
getRangePrediction.getMaximumValue = getMaximumValue;

export default getRangePrediction;
