import React from "react";
import type { FC } from "react";

import { Container, Paper, makeStyles } from "@material-ui/core";
import type { Theme } from "src/theme";
import Page from "src/components/Page";
import Header from "src/components/Header";
import StocksTable from "src/components/Tables/StocksTable";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
}));

const BrowseView: FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Stocks">
      <Container maxWidth={false}>
        <Header
          title="Stocks"
          crumbs={[
            { link: "/app/dashboard", crumb: "Dashboard" },
            { link: "/app/admin/stocks", crumb: "Stocks" },
          ]}
        ></Header>
        <Paper>
          <StocksTable />
        </Paper>
      </Container>
    </Page>
  );
};

export default BrowseView;
