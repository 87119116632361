import React from "react";

function BearIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        fill="#707680"
        d="M21.41 4.578c-1.631-1.858-3.763-.783-4.89.436 0 0 1.586.413 2.667 1.677s.965 2.897.965 2.897c1.257-.851 2.69-3.38 1.257-5.01zm-18.818 0c1.63-1.858 3.762-.783 4.888.436 0 0-1.585.413-2.666 1.677S3.85 9.588 3.85 9.588c-1.257-.851-2.69-3.38-1.257-5.01z"
      />
      <path
        fill="#707680"
        fillRule="evenodd"
        d="M12.016 5.384c1.632-.014 5.503.617 6.458 2.098 1.192 1.85 2.43 5.835 2.097 8.842-.233 2.094-5.182 5.1-6.992 5.1h-3.157c-1.81 0-6.759-3.006-6.992-5.1-.334-3.007.905-6.992 2.098-8.842C6.482 6 10.353 5.37 11.985 5.384h.031zm-1.862 8.73c.088-.152.164-.323.224-.46a8.272 8.272 0 01.054-.12c.076-.165.143-.281.23-.375.147-.16.453-.359 1.342-.359s1.196.199 1.343.359a1.4 1.4 0 01.23.376l.054.12c.06.136.136.307.223.459.163.28.427 1.047.597 1.85.083.394.138.77.149 1.076.005.152 0 .275-.015.367a.466.466 0 01-.03.118l-.003.002a.838.838 0 01-.248.126c-.187.07-.433.129-.714.176-.557.094-1.181.132-1.586.137a11.077 11.077 0 01-1.586-.137 4.117 4.117 0 01-.714-.176c-.173-.064-.235-.115-.248-.126l-.002-.002a.459.459 0 01-.03-.118 2 2 0 01-.015-.367c.01-.305.066-.682.149-1.076.17-.803.434-1.57.596-1.85zm1.85-2.301c-1.026 0-1.657.231-2.068.676a2.365 2.365 0 00-.4.631l-.08.18c-.054.12-.097.217-.156.32-.242.418-.532 1.307-.708 2.14-.09.425-.156.862-.17 1.244-.006.192 0 .381.027.555.026.168.077.358.189.521.176.26.478.408.724.499.267.1.581.17.892.223.624.106 1.305.146 1.745.15h.011c.44-.004 1.121-.044 1.745-.15.31-.052.624-.124.892-.223.246-.091.548-.239.724-.499.111-.163.163-.353.189-.521.027-.174.033-.363.026-.555a7.546 7.546 0 00-.17-1.244c-.176-.833-.465-1.722-.707-2.14-.06-.103-.103-.2-.156-.32l-.08-.18a2.363 2.363 0 00-.4-.63c-.412-.446-1.042-.677-2.069-.677z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default BearIcon;
