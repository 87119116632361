import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjsBusinessTime from "dayjs-business-time";

dayjs.extend(dayjsBusinessTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const getCurrentDate = () => {
  const query = new URLSearchParams(window.location.search);
  let currentDate = dayjs().tz("America/New_York");

  if (!currentDate.isBusinessDay()) {
    currentDate = currentDate.nextBusinessDay();
  }

  return query.get("date") || currentDate.format("YYYY-MM-DD");
};

export default getCurrentDate;
