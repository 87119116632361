import { Paper, Typography, Box, makeStyles } from "@material-ui/core";
import ModelPredictions from "./ModelPredictions";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import getMarketsData from "src/utils/prediction/getMarketsData";
import getForexMarketsData from "src/utils/prediction/getForexMarketsData";
import { Skeleton } from "@material-ui/lab";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
  },
  section: {
    borderBottom: "1px solid #DBD7D2",
    padding: "10px 20px",
  },
  heading: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(1.5),
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: "8px",
    fontWeight: 700,
  },
}));

interface MarketForecastProps {
  isLoading: boolean;
  isForex?: boolean;
  marketNames: string[];
}

const MarketForecast: FC<MarketForecastProps> = ({
  isLoading,
  isForex = false,
  marketNames,
}) => {
  const classes = useStyles();
  const marketsData = useSelector(
    (reduxState: RootState) => reduxState.stocks.marketsData
  );

  const {
    SPY: spyData,
    DIA: diaData,
    QQQ: qqqData,
    IJH: ijhData,
    IWM: iwmData,
    SOXX: soxxData,
  } = getMarketsData(marketsData);

  const { OIL: oilData, GLD: gldData, UUP: uupData } = getForexMarketsData(
    marketsData
  );

  if (isLoading) {
    return <Skeleton animation="wave" width="100%" height={240} />;
  }

  return (
    <div>
      <Box mb={2} className={classes.heading}>
        <Typography variant="h2">Market Forecast</Typography>
      </Box>
      <Box width={1} id="joy-ride-market-forecast">
        <Paper>
          <Box className={classes.section}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4">{marketNames[0]}</Typography>
            </Box>
            <ModelPredictions predictions={spyData} />
          </Box>
          <Box className={classes.section}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4">{marketNames[1]}</Typography>
            </Box>
            <ModelPredictions predictions={diaData} />
          </Box>
          <Box className={classes.section}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4">{marketNames[2]}</Typography>
            </Box>
            <ModelPredictions predictions={qqqData} />
          </Box>
          <Box className={classes.section}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4">{marketNames[3]}</Typography>
            </Box>
            <ModelPredictions predictions={isForex ? oilData : ijhData} />
          </Box>
          <Box className={classes.section}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4">{marketNames[4]}</Typography>
            </Box>
            <ModelPredictions predictions={isForex ? gldData : iwmData} />
          </Box>
          <Box className={classes.section}>
            <Box className={classes.titleContainer}>
              <Typography variant="h4">{marketNames[5]}</Typography>
            </Box>
            <ModelPredictions predictions={isForex ? uupData : soxxData} />
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default MarketForecast;
