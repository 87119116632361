import { Sentiments } from "src/types/Sentiments";
import getIndicatorCopy from "src/utils/prediction/getIndicatorCopy";
import { IndicatorCardProps } from "../components/IndicatorCard";

const list = [
  {
    title: "Simple Moving Averages",
    directionKey: "smaDirection",
    key: "smaDirectionIndicator",
  },
  {
    title: "Exponential Moving Averages",
    directionKey: "emaDirection",
    key: "emaDirectionIndicator",
  },
  {
    title: "Stochastic",
    directionKey: "stochasticDirection",
    key: "stochasticDirectionIndicator",
  },
  {
    title: "RSI",
    directionKey: "rsiDirection",
    key: "rsiDirectionIndicator",
  },
  {
    title: "MACD",
    directionKey: "macdDirection",
    key: "macdDirectionIndicator",
  },
  {
    title: "Bollinger Bands",
    directionKey: "bbDirection",
    key: "bbDirectionIndicator",
  },
];

const getIndicatorList = (prediction): IndicatorCardProps[] => {
  if (!prediction) return [];
  return list
    .filter(
      (item) =>
        prediction[item.key] !== "NOT_FOUND" &&
        prediction[item.key] !== "UNKNOWN" &&
        prediction[item.key] !== "NOT_PREDICTABLE" &&
        prediction[item.directionKey] !== "NOT_PREDICTABLE" &&
        prediction[item.directionKey] !== "UNKNOWN"
    )
    .map((item) => ({
      title: item.title,
      directionKey: item?.directionKey,
      description: getIndicatorCopy({
        matchingKey: prediction[item.key],
        stockContext: {
          symbol: prediction.symbol,
          isSupporting:
            prediction[item.directionKey] === prediction.predictedDirection,
        },
      }),
      sentiment: Sentiments[prediction[item.directionKey]],
      support:
        prediction[item.directionKey] === prediction.predictedDirection
          ? Sentiments.SUPPORTIVE
          : Sentiments.CONFLICTING,
    }));
};

const getConflictingString = (indicators: IndicatorCardProps[]) => {
  return indicators
    .filter((item) => item.sentiment !== Sentiments.NEUTRAL)
    .filter((item) => item.support === Sentiments.CONFLICTING)
    .map((item) => item.title)
    .join(", ");
};

const getSupportiveString = (indicators: IndicatorCardProps[]) => {
  return indicators
    .filter((item) => item.sentiment !== Sentiments.NEUTRAL)
    .filter((item) => item.support === Sentiments.SUPPORTIVE)
    .map((item) => item.title)
    .join(", ");
};

const isBullish = (sentiment) =>
  sentiment === Sentiments.BULLISH || sentiment === Sentiments.VERY_BULLISH;

const isBearish = (sentiment) =>
  sentiment === Sentiments.BEARISH || sentiment === Sentiments.VERY_BEARISH;

const getDirectionString = (sentiment) => {
  if (isBullish(sentiment)) {
    return `bullish`;
  }

  if (isBearish(sentiment)) {
    return `bearish`;
  }

  return "neutral";
};

// @ts-ignore
const formatter = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

const getTechnicalAnalysis = (
  indicators: IndicatorCardProps[],
  isOverallBullish: boolean
) => {
  const bullishList = indicators.filter(
    (item) =>
      item.sentiment === Sentiments.BULLISH ||
      item.sentiment === Sentiments.VERY_BULLISH
  );

  const bearishList = indicators.filter(
    (item) =>
      item.sentiment === Sentiments.BEARISH ||
      item.sentiment === Sentiments.VERY_BEARISH
  );

  const isMajorityOfIndicatorsBullish =
    bullishList?.length > bearishList?.length;
  const majorityIndicatorsString = (isMajorityOfIndicatorsBullish
    ? bullishList
    : bearishList
  ).map((item) => item.title);

  const direction = isMajorityOfIndicatorsBullish ? "bullish" : "bearish";
  const sentiment =
    isMajorityOfIndicatorsBullish === isOverallBullish
      ? "agreement"
      : "disagreement";

  const smaDirection = indicators.find(
    (item) => item?.directionKey === "smaDirection"
  );
  const emaDirection = indicators.find(
    (item) => item?.directionKey === "emaDirection"
  );
  const shouldHaveSecondSentence = Boolean(smaDirection && emaDirection);
  const secondSentence = `\n As you consider a trade opportunity, keep in mind the long-term trend is ${getDirectionString(
    smaDirection?.sentiment
  )} and the short-term momentum is ${getDirectionString(
    emaDirection?.sentiment
  )}.`;

  const analysis = `The ${formatter.format(
    majorityIndicatorsString
  )} are indicating ${direction} momentum, which is in ${sentiment} with the short-term artificial intelligence forecast.`;

  if (shouldHaveSecondSentence) {
    return analysis.concat(secondSentence);
  }

  return analysis;
};

export { getConflictingString, getSupportiveString, getTechnicalAnalysis };
export default getIndicatorList;
