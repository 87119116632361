import ForexPrediction from "src/models/ForexPrediction";
import { useSelector, RootState } from "src/store";
import mergePredictions from "src/utils/mergePredictions";

const useForexPredictions = () => {
  const { predictions, stocks } = useSelector(
    (reduxState: RootState) => reduxState
  );
  const shortPredictions = predictions.forexShortPredictions.data.map(
    (item) => {
      return {
        symbol: item.symbol,
        averageShortScore: item.averageScore,
        combinedShortDirection: {
          supporting: item.combinedDirection.supporting,
          conflicting: item.combinedDirection.conflicting,
        },
        previousShortClose: item.previousClose,
        predictedShortCloseValue: item.predictedCloseValue,
      };
    }
  );
  const longPredictions = predictions.forexPredictions.data;
  const allPredictions = mergePredictions(shortPredictions, longPredictions);
  const mergedPredictionsWithStocks = allPredictions.map((prediction) => {
    return new ForexPrediction({
      ...prediction,
      ...stocks.data.find((stock) => stock.symbol === prediction.symbol),
    });
  });

  return mergedPredictionsWithStocks;
};

export default useForexPredictions
