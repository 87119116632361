import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
interface Props {
  className?: string;
  // user?: IUser;
  currentTab?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  buttons: {
    '& > * + *': {
      marginLeft: 10
    }
  }
}));

const Header: FC<Props> = ({
  className,
  // user,
  currentTab,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Grid
      alignItems='center'
      container
      justify='space-between'
      spacing={3}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          <Link
            variant='body1'
            color='inherit'
            to='/dashboard'
            component={RouterLink}
          >
            Home
          </Link>
          <Link
            variant='body1'
            color='inherit'
            to='/dashboard/users/edit'
            component={RouterLink}
          >
            Profile
          </Link>
        </Breadcrumbs>
        <Typography variant='h3' color='textPrimary'>
          My Profile
        </Typography>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
