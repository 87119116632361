const copies = [
  {
    key: `100_UP_200_UP`,
    supportingCopy: (symbol) =>
      `The 100 and 200 SMAs are both trending up. This suggests the primary direction of ${symbol} is bullish. It is typically wise to place a trade in the direction of this primary trend. The current momentum should help support a bullish trade.`,
    conflictingCopy: (symbol) =>
      `The 100 and 200 SMAs are both trending up. This suggests the primary direction of ${symbol} is bullish. Even though Deep Sky is forecasting a bearish move, be careful if you are considering a bearish trade. Think of this like trying to swim against a current.`,
  },
  {
    key: `100_UP_200_DOWN`,
    supportingCopy: (symbol) =>
      `The 100 SMA is trending up while the 200 SMA is trending down. This suggests that a new bullish trend could be forming on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Consider a bullish trade with caution since the 200 has not yet confirmed the momentum.`,
    conflictingCopy: (symbol) =>
      `The 100 SMA is trending up, while the 200 SMA is trending down. This suggests that a new bullish trend could be forming on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Since Deep Sky is currently forecasting a bearish move, consider waiting to place a trade until there is better consensus.`,
  },
  {
    key: `100_UP_200_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 100 SMA is trending up while the 200 SMA is trending sideways. This suggests that a new bullish trend may have recently started on ${symbol}. It is typically wise to place a trade in the direction of this primary trend.`,
    conflictingCopy: (symbol) =>
      `The 100 SMA is trending up, while the 200 SMA is trending sideways. This suggests that a new bullish trend may have recently started on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Since Deep Sky is currently forecasting a bearish move, consider waiting to place a trade until there is better consensus.`,
  },
  {
    key: `100_DOWN_200_DOWN`,
    supportingCopy: (symbol) =>
      `The 100 and 200 SMAs are both trending down. This suggests the primary direction of ${symbol} is bearish. It is typically wise to place a trade in the direction of the primary trend. The current momentum should help support a bearish trade. Keep in mind that downward moves in the market can be volatile and often shorter-lived than bullish trends.`,
    conflictingCopy: (symbol) =>
      `The 100 and 200 SMAs are both trending down. This suggests the primary direction of ${symbol} is bearish. It can be difficult to place a counter-trend trade. Even though Deep Sky is forecasting a bullish move, be careful if you are considering a bullish trade. Think of this like trying to swim against a current.`,
  },
  {
    key: `100_DOWN_200_UP`,
    supportingCopy: (symbol) =>
      `The 100 SMA is trending down while the 200 SMA is trending up. This suggests that a new bearish trend may have recently started on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Keep in mind that downward moves in the market can be volatile and often shorter-lived than bullish trends.`,
    conflictingCopy: (symbol) =>
      `The 100 SMA is trending down, while the 200 SMA is trending up. This suggests that a new bearish trend may have recently started on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Since Deep Sky is currently forecasting a bullish move, consider waiting to place a trade until there is better consensus.`,
  },
  {
    key: `100_DOWN_200_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 100 SMA is trending down while the 200 SMA is trending sideways. This suggests that a new bearish trend may have recently started on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Keep in mind, downward moves can be volatile and are often shorter-lived than a bullish trend.`,
    conflictingCopy: (symbol) =>
      `The 100 SMA is trending down, while the 200 SMA is trending sideways. This suggests that a new bearish trend may have recently started on ${symbol}. It is typically wise to place a trade in the direction of this primary trend. Since Deep Sky is currently forecasting a bullish move, consider waiting to place a trade until there is better consensus.`,
  },
  {
    key: `100_NEUTRAL_200_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 100 and 200 SMAs are both trending sideways. This suggests the primary direction of ${symbol} is neither bullish nor bearish. This is currently a bad candidate for a momentum-based strategy. Consider waiting to place a trade. Or, use a sideways strategy.`,
    conflictingCopy: (symbol) =>
      `The 100 and 200 SMAs are both trending sideways. This suggests the primary direction of ${symbol} is neither bullish nor bearish. This is currently a bad candidate for a momentum-based strategy. Consider waiting to place a trade. Or, use a sideways strategy.`,
  },
  {
    key: `100_NEUTRAL_200_UP`,
    supportingCopy: (symbol) =>
      `The 100 SMA is trending sideways while the 200 SMA is trending up. This suggests that a previous bullish trend could be coming to an end on ${symbol}. If you are considering a bullish trade, check to see if other analysis is indicating a support zone. Be cautious if you are considering a bearish trade, because it can be difficult to perfectly time a bearish reversal. Or, wait for a better-developed trend.`,
    conflictingCopy: (symbol) =>
      `The 100 SMA is trending sideways, while the 200 SMA is trending up. This suggests that a previous bullish trend could be coming to an end on ${symbol}. If you are considering a bullish trade, check to see if other analysis is indicating a support zone. Be cautious if you are considering a bearish trade. It can be difficult to perfectly time a bearish reversal. Or, wait for a better-developed trend.`,
  },
  {
    key: `100_NEUTRAL_200_DOWN`,
    supportingCopy: (symbol) =>
      `The 100 SMA is trending sideways while the 200 SMA is trending down. This suggests that a previous bearish trend could be coming to an end on ${symbol}. If you are considering a bearish trade, check to see if other analysis is indicating a resistance zone. Also, be cautious if you are considering a bullish trade, because it can be difficult to perfectly time a bullish reversal. Or, wait for a better-developed trend.`,
    conflictingCopy: (symbol) =>
      `The 100 SMA is trending sideways, while the 200 SMA is trending down. This suggests that a previous bearish trend could be coming to an end on ${symbol}. If you are considering a bearish trade, check to see if other analysis is indicating a resistance zone. Also, be cautious if you are considering a bullish trade. It can be difficult to perfectly time a bullish reversal. Or, wait for a better-developed trend.`,
  },
  {
    key: `100_CROSSING_200_UP`,
    supportingCopy: () =>
      `The 100 SMA just crossed up over the 200 SMA. This can indicate the beginning of a new bullish trend. It is significant because many institutional traders watch for this signal to enter positions. Since Deep Sky is in agreement with a bullish forecast, it might be a good time to consider a trade. Caution: Remember that during a sideways move, the SMA cross is meaningless.`,
    conflictingCopy: () =>
      `The 100 SMA just crossed up over the 200 SMA. This can indicate the beginning of a new bullish trend. It is significant because many institutional traders watch for this signal to enter positions. Since Deep Sky is not in agreement with a bullish forecast, consider waiting until you have additional consensus.`,
  },
  {
    key: `100_CROSSING_200_DOWN`,
    supportingCopy: () =>
      `The 100 SMA just crossed down over the 200 SMA. This can indicate the beginning of a new bearish trend. It is significant because many institutional traders watch for this signal to exit positions (or short new positions). Since Deep Sky agrees with a bearish forecast, it might be a good time to consider a trade. Caution: Remember that during a sideways move, the SMA cross is meaningless.`,
    conflictingCopy: () =>
      `The 100 SMA just crossed down over the 200 SMA. This can indicate the beginning of a new bearish trend. It is significant because many institutional traders watch for this signal to exit positions (or short new positions). Since Deep Sky is not in agreement with a bearish forecast, consider waiting until you have additional consensus.`,
  },
  {
    key: `10_UP_20_UP_50_UP`,
    supportingCopy: (symbol) =>
      `All three EMAs are trending up. This suggests the momentum of ${symbol} is bullish. It is typically wise to place a trade in the direction of this primary trend. The current momentum should support a bullish trade.\n\nPro Tip: Check to ensure the moving averages are not too far apart (relative to their previous separation). Also, remember that the averages behave like "rubber bands", pulling price action back if it moves too far away. Check to see that the current price has not moved too far from the averages, suggesting a brief retracement could be imminent. Momentum indicators will help identify an overextended trend as well.`,
    conflictingCopy: (symbol) =>
      `All three EMAs are trending up. This suggests the momentum of ${symbol} is bullish. It is typically wise to place a trade in the direction of this primary trend.\n\nSince Deep Sky is forecasting bearish momentum, make sure the current movement has not overextended price action. You can identify this by looking at the separation of the averages and checking where price action is related to the moving averages. Remember that the averages behave like "rubber bands", pulling price action back if it moves too far away.\n\nA bearish trade would be fighting momentum right now. Consider waiting.`,
  },
  {
    key: `10_UP_20_UP_50_DOWN`,
    supportingCopy: (symbol) =>
      `The 10 and 20 EMA are trending up, while the 50 EMA is trending down. This could be the beginning of a new bullish trend, or it could also be occurring in a sideways move. Either way, consider waiting for some additional clarity on the trend direction.\n\nIt is also possible that ${symbol} is trending bearish and is retracing back to resistance. In this instance, check for additional confirmation and cautiously consider a bearish trade if price is at resistance.`,
    conflictingCopy: (symbol) =>
      `The 10 and 20 EMA are trending up, while the 50 EMA is trending down. This could be the very beginning of a new bullish trend, or it could also be occurring in a sideways move. Either way, consider waiting for some additional clarity on the trend direction.\n\nIt is also possible that ${symbol} is trending bearish and is retracing back to resistance. In this instance, check for additional confirmation and cautiously consider a bearish trade if price is at resistance.`,
  },
  {
    key: `10_UP_20_DOWN_50_DOWN`,
    supportingCopy: (symbol) =>
      `The 20 and 50 EMA are trending down, while the 10 EMA has turned up. This suggests the momentum of ${symbol} is bearish. This scenario is often caused during a short retracement within a primary bearish trend. Since Deep Sky is also forecasting a move lower, if you've identified a qualified resistance following a retracement, this might be a good bearish trade.\n\nBe careful that ${symbol} has not reached a support level and the rising 10 EMA is the beginning of a bullish reversal. Check for additional consensus with other indicators.`,
    conflictingCopy: (symbol) =>
      `The 20 and 50 EMA are trending down, while the 10 EMA has turned up. This suggests the momentum of ${symbol} is bearish. This scenario is often caused during a short retracement within a primary bearish trend. This would often be seen as a bearish setup. However, since Deep Sky is forecasting a move higher, consider waiting for additional consensus. Also, be careful that price has not reached a support level and the rising 10 EMA is the beginning of a bullish reversal.`,
  },
  {
    key: `10_DOWN_20_DOWN_50_DOWN`,
    supportingCopy: (symbol) =>
      `All three EMAs are trending down. This suggests the momentum of ${symbol} is bearish. It is typically wise to place a trade in the direction of this primary trend. The current momentum should support a bearish trade.\n\nPro Tip: Bearish moves can be volatile and short-lived. Check to ensure the moving averages are not too far apart (relative to their previous separation). Also, remember that the averages behave like "rubber bands", pulling price action back if it moves too far away. Check to see that the current price has not moved too far from the averages, suggesting a brief retracement could be imminent. Momentum indicators will help identify an overextended trend as well.`,
    conflictingCopy: (symbol) =>
      `All three EMAs are trending down. This suggests the momentum of ${symbol} is bearish. It is typically wise to place a trade in the direction of this primary trend.\n\nSince Deep Sky is forecasting bullish momentum, check to be sure the current movement has not overextended price action. You can identify this by looking at the separation of the averages and checking where price action is in relationship to the moving averages. Remember that the averages behave like "rubber bands", pulling price action back if it moves too far away.\n\nA bullish trade would be fighting momentum right now. Consider waiting.`,
  },
  {
    key: `10_DOWN_20_DOWN_50_UP`,
    supportingCopy: (symbol) =>
      `The 10 and 20 EMA are trending down, while the 50 EMA is trending up. This could be the beginning of a new bearish trend, or it could also be occurring in a sideways move. Either way, consider waiting for some additional clarity on the trend direction.\n\nIt is also possible that ${symbol} is trending bullish and is retracing back to support. In this instance, check for additional confirmation and cautiously consider a bullish trade if price is at support.`,
    conflictingCopy: (symbol) =>
      `The 10 and 20 EMA are trending down, while the 50 EMA is trending up. This could be the beginning of a new bearish trend, or it could also be occurring in a sideways move. Either way, consider waiting for some additional clarity on the trend direction.\n\nIt is also possible that ${symbol} is trending bullish and is retracing back to support. In this instance, check for additional confirmation and cautiously consider a bullish trade if price is at support.`,
  },
  {
    key: `10_DOWN_20_UP_50_UP`,
    supportingCopy: (symbol) =>
      `The 20 and 50 EMA are trending up, while the 10 EMA has turned down. This suggests the momentum of ${symbol} is bullish. This scenario can occur during a short retracement within a primary bullish trend. Since Deep Sky is also forecasting a move higher, if you've identified support following a retracement, this might be a good bullish trade.\n\nBe careful that ${symbol} has not reached a resistance level and that the falling 10 EMA is the beginning of a bearish reversal. Check for additional consensus with other indicators.`,
    conflictingCopy: (symbol) =>
      `The 20 and 50 EMA are trending up, while the 10 EMA has turned down. This suggests the momentum of ${symbol} is bullish. This scenario often occurs during a short retracement within a primary bullish trend. This would be viewed as a bullish setup. However, since Deep Sky is forecasting a move lower, consider waiting for additional consensus. Also, be careful that price has not reached a resistance level and the falling 10 EMA is the beginning of a bearish reversal.`,
  },
  {
    key: `10_NEUTRAL_20_NEUTRAL_50_NEUTRAL`,
    supportingCopy: (symbol) =>
      `All Exponential Moving Averages are sideways. There is no directional bias on ${symbol}. Avoid a momentum-based strategy. Consider a sideways-specific strategy or find a different trade.`,
    conflictingCopy: (symbol) =>
      `All Exponential Moving Averages are sideways. There is no directional bias on ${symbol}. Avoid a momentum-based strategy. Consider a sideways-specific strategy or find a different trade.`,
  },
  {
    key: `10_NEUTRAL_20_NEUTRAL_50_UP`,
    supportingCopy: (symbol) =>
      `The 10 and 20 EMA are trending sideways, while the 50 EMA is trending up. Technically, the dominant direction of ${symbol} is bullish. However, momentum is fading quickly. With Deep Sky forecasting a move higher, it is possible that ${symbol} has retracted back to support. In this instance, check for additional confirmation and cautiously consider a bullish trade if support has been identified.`,
    conflictingCopy: (symbol) =>
      `The 10 and 20 EMA are trending sideways, while the 50 EMA is trending up. Technically, the dominant direction of ${symbol} is bullish. However, momentum is fading quickly. With Deep Sky forecasting a move lower, consider waiting on a bullish trade until you have additional consensus.\n\nAlso, it is likely premature to enter a bearish trade. Consider waiting.`,
  },
  {
    key: `10_NEUTRAL_20_NEUTRAL_50_DOWN`,
    supportingCopy: (symbol) =>
      `The 10 and 20 EMA are trending sideways, while the 50 EMA is trending down. Technically, the dominant direction of ${symbol} is bearish. However, momentum is fading quickly. With Deep Sky forecasting a move lower, it is possible that ${symbol} has retracted. In this instance, check for additional confirmation and cautiously consider a bearish trade if price is at resistance.`,
    conflictingCopy: (symbol) =>
      `The 10 and 20 EMA are trending sideways, while the 50 EMA is trending down. Technically, the dominant direction of ${symbol} is bearish. However, with Deep Sky forecasting a move higher, consider waiting on a bearish trade until you have additional consensus.\n\nAlso, it is likely premature to enter a bullish trade. Consider waiting.`,
  },
  {
    key: `10_UP_20_NEUTRAL_50_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 20 and 50 EMA are trending sideways, while the 10 EMA has turned up. This suggests that ${symbol} does not have an established directional momentum. It's also possible that price action is breaking bullishly out of a consolidation pattern. Even with Deep Sky forecasting a higher move, be cautious jumping into a bullish breakout right away. These often retrace and test old resistance as new support, offering a better entry.`,
    conflictingCopy: (symbol) =>
      `The 20 and 50 EMA are trending sideways, while the 10 EMA has turned up. This suggests that ${symbol} does not have an established directional momentum. It's also possible that price action is breaking bullishly out of a consolidation pattern. However, with Deep Sky forecasting a move lower, be cautious jumping into a bullish breakout right away. These often retrace and test old resistance as new support, offering a better entry.`,
  },
  {
    key: `10_DOWN_20_NEUTRAL_50_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 20 and 50 EMA are trending sideways, while the 10 EMA has turned down. This suggests that ${symbol} does not have an established directional momentum. It's also possible that price action is breaking bearish out of a consolidation pattern. Even with Deep Sky forecasting a lower move, be cautious jumping into a bearish breakout right away. These often retrace and test old support as new resistance, offering a better entry.`,
    conflictingCopy: (symbol) =>
      `The 20 and 50 EMA are trending sideways, while the 10 EMA has turned down. This suggests that ${symbol} does not have an established directional momentum. It's also possible that price action is breaking bearish out of a consolidation pattern. However, with Deep Sky forecasting a move higher, be cautious jumping into a bearish breakout right away. These often retrace and test old support as new resistance, offering a better entry.`,
  },
  {
    key: `10_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 10 EMA has turned sideways. This means that momentum has started to decrease on ${symbol}. This could be consolidation, a retracement, or the beginning of a reversal. Consider waiting until you have better clarity on a trade direction.`,
    conflictingCopy: (symbol) =>
      `The 10 EMA has turned sideways. This means that momentum has started to decrease on ${symbol}. This could be consolidation, a retracement, or the beginning of a reversal. Consider waiting until you have better clarity on a trade direction.`,
  },
  {
    key: `20_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 20 EMA has turned sideways. This means that momentum is decreasing on ${symbol}. This could be consolidation, a retracement, or the beginning of a reversal. Consider waiting until you have better clarity on a trade direction.`,
    conflictingCopy: (symbol) =>
      `The 20 EMA has turned sideways. This means that momentum is decreasing on ${symbol}. This could be consolidation, a retracement, or the beginning of a reversal. Consider waiting until you have better clarity on a trade direction.`,
  },
  {
    key: `50_NEUTRAL`,
    supportingCopy: (symbol) =>
      `The 50 EMA has turned sideways. This means that momentum has decreased on ${symbol}. This could be consolidation, a retracement, or the beginning of a reversal. Consider waiting until you have better clarity on a trade direction.`,
    conflictingCopy: (symbol) =>
      `The 50 EMA has turned sideways. This means that momentum has decreased on ${symbol}. This could be consolidation, a retracement, or the beginning of a reversal. Consider waiting until you have better clarity on a trade direction.`,
  },
  {
    key: `OVER_80`,
    supportingCopy: (symbol) =>
      `Stochastic is over 80, which indicates that ${symbol} is overbought. However, keep in mind that the Stochastic indicator can remain overbought for weeks during a strong bullish trend. While perhaps this is not the ideal time to enter a new bullish trade, it might not be signaling an exit either.\n\nIf ${symbol} is trending down, an overbought Stochastic reading would be appropriate if you are considering a bearish trade entry.`,
    conflictingCopy: (symbol) =>
      `Stochastic is over 80, which technically indicates that ${symbol} is overbought. However, keep in mind that the Stochastic indicator can remain overbought for weeks during a strong bullish trend. While perhaps this is not the ideal time to enter a new bullish trade, it might not be signaling an exit either.\n\nIf the directional momentum of ${symbol} is down, this overbought Stochastic reading could be setting up a possible bearish entry.\n\nConsider trading with the momentum indicated by the moving averages and not against it.`,
  },
  {
    key: `CROSSING_UP_OVER_80`,
    supportingCopy: (symbol) =>
      `Stochastic just crossed over 80, which technically indicates that ${symbol} is overbought. However, keep in mind that the Stochastic indicator can remain overbought for weeks during a strong bullish trend. While perhaps this is not the ideal time to enter a new bullish trade, it might not be signaling an exit either.\n\nIf ${symbol} is trending down, an overbought Stochastic reading would be appropriate if you are considering a bearish trade entry.`,
    conflictingCopy: (symbol) =>
      `Stochastic just crossed over 80, which technically indicates that ${symbol} is overbought. However, keep in mind that the Stochastic indicator can remain overbought for weeks during a strong bullish trend. While perhaps this is not the ideal time to enter a new bullish trade, it might not be signaling an exit either.\n\nIf the directional momentum of ${symbol} is down, this overbought Stochastic reading could be setting up a possible bearish entry.\n\nConsider trading with the momentum indicated by the moving averages and not against it.`,
  },
  {
    key: `CROSSING_DOWN_BELOW_80`,
    supportingCopy: (symbol) =>
      `Stochastic just crossed below 80, which indicates that ${symbol} was recently overbought, and price action has started moving down. Deep Sky is also forecasting a bearish move. Check your moving averages to determine the primary direction of the momentum. If bearish, Stochastic crossing below 80 could be suggesting a trade entry.\n\nHowever, if the primary momentum indicated by the moving averages is bullish, this is likely a short-term retracement. Consider waiting for the pullback to complete and watch for consensus of a bullish entry.\n\nIf ${symbol} is trending down, Stochastic crossing down through 80 would support a bearish trade entry.`,
    conflictingCopy: (symbol) =>
      `Stochastic just crossed below 80, which indicates that ${symbol} was recently overbought, and price action has now started moving down.\n\nWith Deep Sky forecasting a higher move, it's possible this may be a very short-term pullback. Check your moving averages to determine the primary direction of momentum. If the primary momentum is bullish, this is likely a short-term retracement. Consider a bullish entry if there is a consensus of a completed pullback with other analysis.\n\nIf momentum is downward, you could consider a bearish trade, especially if Deep Sky begins forecasting a bearish move as well.`,
  },
  {
    key: `BELOW_20`,
    supportingCopy: (symbol) =>
      `Stochastic is below 20, which indicates that ${symbol} is oversold. If your moving averages show generally bullish or sideways momentum, watch for a new bullish trade opportunity to develop soon. Typically Stochastic does not remain oversold very long during a bullish or sideways trend.\n\nIf your moving averages are trending down, the oversold state of ${symbol} is not likely a good time to enter a new bearish trade. If you are currently in a bearish trade, oversold Stochastic is not necessarily an exit signal. However, you should consider tightening stops.`,
    conflictingCopy: (symbol) =>
      `Stochastic is below 20, which indicates that ${symbol} is oversold. If your moving averages show generally bullish or sideways momentum, watch for a new bullish trade opportunity to develop. Currently, Deep Sky is still forecasting a lower move, but typically Stochastic does not remain oversold very long during a bullish or sideways trend.\n\nIf your moving averages are trending down, the oversold Stochastic reading is not likely a good time to enter a new bearish trade. If you are currently in a bearish trade, oversold Stochastic is not necessarily an exit signal. However, you should consider tightening stops.\n\nIf ${symbol} is trending up, an oversold Stochastic reading would be appropriate if you are considering a bullish trade entry. Consider waiting a period or two until Stochastic crosses back up over 20 and you get consensus with Deep Sky.`,
  },
  {
    key: `CROSSING_DOWN_BELOW_20`,
    supportingCopy: (symbol) =>
      `Stochastic just crossed below 20, which indicates that ${symbol} is now oversold. If your moving averages generally show bullish or sideways momentum, watch for a new bullish trade opportunity to develop soon. Typically Stochastic does not remain oversold very long during a bullish or sideways trend.\n\nIf your moving averages are trending down, the oversold state of ${symbol} is not likely a good time to enter a new bearish trade. If you are currently in a bearish trade, oversold stochastic is not necessarily an exit signal. However, you should consider tightening stops.`,
    conflictingCopy: (symbol) =>
      `Stochastic just crossed below 20, which indicates that ${symbol} is now oversold. If your moving averages generally show bullish or sideways momentum, watch for a new bullish trade opportunity to develop. Currently, Deep Sky is still forecasting a lower move, but typically Stochastic does not remain oversold very long during a bullish or sideways trend.\n\nIf your moving averages are trending down, the oversold Stochastic reading is not likely a good time to enter a new bearish trade. If you are currently in a bearish trade, oversold Stochastic is not necessarily an exit signal. However, you should consider tightening stops.`,
  },
  {
    key: `CROSSING_UP_ABOVE_20`,
    supportingCopy: (symbol) =>
      `Stochastic just crossed over 20, which indicates that ${symbol} was recently oversold and price action has started heading back up. If your moving averages show generally bullish or sideways momentum, consider a new bullish trade.`,
    conflictingCopy: (symbol) =>
      `Stochastic just crossed over 20, which indicates that ${symbol} was recently oversold and price action has started heading back up. If your moving averages show generally bullish or sideways momentum, consider a new bullish trade.\n\nWith Deep Sky forecasting a lower move, be cautious with a bullish trade. It's possible there may be an opportunity to enter at a better price. However, typically Stochastic does not remain oversold very long in a bullish trend. Check for additional indications of support.`,
  },
  {
    key: `UP_BETWEEN_20_80`,
    supportingCopy: (symbol) =>
      `Stochastic is trending up between oversold and overbought zones. This should be supporting a bullish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of resistance. Your moving averages will help determine if ${symbol} is trending or moving sideways.`,
    conflictingCopy: (symbol) =>
      `Stochastic is trending up between oversold and overbought zones. This should be supporting a bullish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of resistance. Your moving averages will help determine if ${symbol} is trending or moving sideways.`,
  },
  {
    key: `DOWN_BETWEEN_20_80`,
    supportingCopy: (symbol) =>
      `Stochastic is trending down between overbought and oversold zones. This should be supporting a bearish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of support. Your moving averages will help determine if ${symbol} is trending or moving sideways.`,
    conflictingCopy: (symbol) =>
      `Stochastic is trending down between overbought and oversold zones. This should be supporting a bearish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of support. Your moving averages will help determine if ${symbol} is trending or moving sideways.`,
  },
  {
    key: `OVER_70`,
    supportingCopy: (symbol) =>
      `RSI is over 70, which indicates that ${symbol} is overbought. This is often a signal that bullish momentum will decline soon. Typically, RSI does not remain above 70 very long. Each security is unique. Consider looking at recent times RSI has been above 70, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During an uptrend, RSI can remain overbought for a while. However, be cautious if you are considering a new bullish trade because price action may retrace over the next few periods, providing a better entry. If ${symbol} is trending down, an overbought RSI reading would be appropriate if you are considering a bearish trade entry.`,
    conflictingCopy: (symbol) =>
      `RSI is over 70, which indicates that ${symbol} is overbought. This is often a signal that bullish momentum will decline soon. Typically, RSI does not remain above 70 very long. Each security is unique. Consider looking at recent times RSI has been above 70, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During an uptrend, RSI can remain overbought for a while. However, even though Deep Sky is forecasting a higher move, be cautious if you are considering a new bullish trade. Price action may retrace over the next few periods, providing a better bullish entry.\n\nIf ${symbol} is trending down, an overbought RSI reading would be appropriate if you are considering a bearish trade entry. Consider waiting a period or two until you get consensus with Deep Sky.`,
  },
  {
    key: `CROSSING_UP_OVER_70`,
    supportingCopy: (symbol) =>
      `RSI just crossed over 70, which indicates that ${symbol} is now overbought. This is often a signal that bullish momentum will decline soon. Typically, RSI does not remain above 70 very long. Each security is unique. Consider looking at recent times RSI has been above 70, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During an uptrend, RSI can remain overbought for a while. However, be cautious if you are considering a new bullish trade because price action may retrace over the next few periods, providing a better entry. If ${symbol} is trending down, an overbought RSI reading would be appropriate if you are considering a bearish trade entry.`,
    conflictingCopy: (symbol) =>
      `RSI just crossed over 70, which indicates that ${symbol} is now overbought. This is often a signal that bullish momentum will decline soon. Typically, RSI does not remain above 70 very long. Each security is unique. Consider looking at recent times RSI has been above 70, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During an uptrend, RSI can remain overbought for a while. However, even though Deep Sky is forecasting a higher move, be cautious if you are considering a new bullish trade. Price action may retrace over the next few periods, providing a better bullish entry.\n\nIf ${symbol} is trending down, an overbought RSI reading would be appropriate if you are considering a bearish trade entry. Consider waiting a period or two until you get consensus with Deep Sky.`,
  },
  {
    key: `CROSSING_DOWN_BELOW_70`,
    supportingCopy: (symbol) =>
      `RSI just crossed below 70, which indicates that ${symbol} was recently overbought and price action has started moving down. Deep Sky is also forecasting a bearish move. Check your moving averages to determine the primary direction of the momentum. If bearish, RSI crossing below 80 could be suggesting a trade entry.\n\nHowever, if the primary momentum indicated by the moving averages is bullish, this is likely a short-term retracement. Consider waiting for the pullback to complete and watch for consensus of a bullish entry.`,
    conflictingCopy: (symbol) =>
      `RSI just crossed below 70, which indicates that ${symbol} was recently overbought and price action has now started moving down.\n\nWith Deep Sky forecasting a higher move, it's possible that this is just a very short-term pullback. Check your moving averages to determine the primary direction of momentum. If the primary momentum is bullish, this is likely a short-term retracement. Consider a bullish entry if there is a consensus of a completed pullback.\n\nIf momentum is bearish, you could consider a bearish trade, especially if Deep Sky begins forecasting a bearish move as well.`,
  },
  {
    key: `BELOW_30`,
    supportingCopy: (symbol) =>
      `RSI is below 30, which indicates that ${symbol} is oversold. This is often a signal that bearish momentum will decline soon. Typically, RSI does not remain below 30 very long. Each security is unique. Consider looking at recent times RSI has been below 30, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During a downtrend, RSI can remain oversold for a while. However, be cautious if you are considering a new bearish trade because price action may retrace over the next few periods, providing a better entry. If ${symbol} is trending up, an oversold RSI reading would be appropriate if you are considering a bullish trade entry.`,
    conflictingCopy: (symbol) =>
      `RSI is below 30, which indicates that ${symbol} is oversold. This is often a signal that bearish momentum will decline soon. Typically, RSI does not remain below 30 very long. Each security is unique. Consider looking at recent times RSI has been below 30, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During a downtrend, RSI can remain oversold for a while. However, even though Deep Sky is forecasting a lower move, be cautious if you are considering a new bearish trade. Price action may retrace over the next few periods, providing a better bearish entry.\n\nIf ${symbol} is trending up, an oversold RSI reading would be appropriate if you are considering a bullish trade entry. Consider waiting a period or two until RSI crosses back up over 30 and you get a consensus with Deep Sky.`,
  },
  {
    key: `CROSSING_DOWN_BELOW_30`,
    supportingCopy: (symbol) =>
      `RSI just crossed below 30, which indicates that ${symbol} is now oversold. This is often a signal that bearish momentum will decline soon. Typically, RSI does not remain below 30 very long. Each security is unique. Consider looking at recent times RSI has been below 30, and observe how long it stayed there.\n\nIt is also important to analyze RSI in light of the dominant trend of ${symbol}. During a downtrend, RSI can remain oversold for a while. However, be cautious if you are considering a new bearish trade because price action may retrace over the next few periods, providing a better entry. If ${symbol} is trending up, an oversold RSI reading would be appropriate if you are considering a bullish trade entry.`,
    conflictingCopy: (symbol) =>
      `RSI just crossed below 30, which indicates that ${symbol} is now oversold. If your moving averages generally show bullish momentum, watch for a new bullish trade opportunity to develop. Currently, Deep Sky is still forecasting a lower move, so you may want to wait a few periods until you get a consensus. Typically, however, RSI does not remain oversold very long during a bullish trend.\n\nIf your moving averages are trending down, the oversold RSI reading is not likely a good time to enter a new bearish trade. If you are currently in a bearish trade, oversold RSI is not necessarily an exit signal. However, you should consider tightening stops.`,
  },
  {
    key: `CROSSING_UP_ABOVE_30`,
    supportingCopy: (symbol) =>
      `RSI just crossed over 30, which indicates that ${symbol} was recently oversold and price action has started heading back up. If your moving averages show bullish momentum, consider a new bullish trade.`,
    conflictingCopy: (symbol) =>
      `RSI just crossed over 30, which indicates that ${symbol} was recently oversold and price action has started heading back up. If your moving averages show bullish momentum, consider a new bullish trade.\n\nHowever, with Deep Sky forecasting a lower move, be cautious. There may be an opportunity to enter at a better price. Keep in mind, RSI does not typically remain oversold very long in a bullish trend. Check for additional indications of support.\n\nIf ${symbol} is trending bearish, you might consider looking for a trade entry near resistance.`,
  },
  {
    key: `UP_BETWEEN_30_70`,
    supportingCopy: (symbol) =>
      `RSI is trending up between oversold and overbought zones. This should be supporting a bullish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of resistance. Your moving averages will help determine if ${symbol} is trending or moving sideways.\n\nBe cautious of momentum slowing as RSI approaches 70.`,
    conflictingCopy: (symbol) =>
      `RSI is trending up between oversold and overbought zones. This should be supporting a bullish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of resistance. Your moving averages will help determine if ${symbol} is trending or moving sideways.\n\nBe cautious of momentum slowing as RSI approaches 70.`,
  },
  {
    key: `DOWN_BETWEEN_30_70`,
    supportingCopy: (symbol) =>
      `RSI is trending down between overbought and oversold zones. This should be supporting a bearish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of support. Your moving averages will help determine if ${symbol} is trending or moving sideways.\n\nBe cautious of bearish momentum slowing as RSI approaches 30.`,
    conflictingCopy: (symbol) =>
      `RSI is trending down between overbought and oversold zones. This should be supporting a bearish move in price. During a trend, this can extend for a long time. In a sideways move, watch for price to slow or stop at upcoming points of support. Your moving averages will help determine if ${symbol} is trending or moving sideways.\n\nBe cautious of bearish momentum slowing as RSI approaches 30.`,
  },
  {
    key: `TRENDING_UP`,
    supportingCopy: (symbol) =>
      `MACD is trending up, which should be supporting a rising price. If price action is trending down while MACD is trending up, that would be divergence and can signal a change in the direction of ${symbol}'s momentum.\n\nWith Deep Sky also forecasting a rising price, consider either holding or entering a bullish trade. Be sure to check that price is not near resistance or that momentum is overbought.`,
    conflictingCopy: (symbol) =>
      `MACD is trending up, which should be supporting a rising price. If price action is trending down while MACD is trending up, that would be divergence and can signal a change in the direction of ${symbol}'s momentum.\n\nWith Deep Sky forecasting a falling price, a bullish trade consideration may have a better entry over the next few periods. A rising MACD, however, is not the ideal indication for a bearish trade setup.`,
  },
  {
    key: `TRENDING_DOWN`,
    supportingCopy: (symbol) =>
      `MACD is trending down, which should be supporting a falling price. If price action is trending up while MACD is trending down, that would be divergence and can signal a change in the direction of ${symbol}'s momentum.\n\nWith Deep Sky also forecasting a falling price, consider either holding or entering a bearish trade. Be sure to check that price is not near a support or that momentum is oversold. This is not an ideal indication for a bullish entry.`,
    conflictingCopy: (symbol) =>
      `MACD is trending down, which should be supporting a falling price. If price action is trending up while MACD is trending down, that would be divergence and can signal a change in the direction of ${symbol}'s momentum.\n\nWith Deep Sky forecasting a rising price, a bearish trade consideration may have a better entry over the next few periods. A falling MACD, however, is not the ideal indication for a bullish trade setup.`,
  },
  {
    key: `TRENDING_NEUTRAL`,
    supportingCopy: (symbol) =>
      `MACD is trending sideways. If the indicator is sideways around the 0 (zero) reading on the scale, ${symbol} is likely trending sideways. If the MACD line is sideways on either the positive or negative side of the scale, ${symbol} is likely in a stable bullish or bearish trend, respectively.\n\nRemember, MACD is measuring the distance between moving averages. If that distance remains constant, MACD will trend sideways.`,
    conflictingCopy: (symbol) =>
      `MACD is trending sideways. If the indicator is sideways around the 0 (zero) reading on the scale, ${symbol} is likely trending sideways. If the MACD line is sideways on either the positive or negative side of the scale, ${symbol} is likely in a stable bullish or bearish trend, respectively.\n\nRemember, MACD is measuring the distance between moving averages. If that distance remains constant, MACD will trend sideways.`,
  },
  {
    key: `TRENDING_NEUTRAL_LOWER`,
    supportingCopy: (symbol) =>
      `MACD is trending sideways below the 0 (zero) line. This often occurs when a stock is trending bearish. Check previous measurements for ${symbol} to guage how far away it is from the zero line. If it's within a typical range, the trend is likely more sustainable. If it's outside the normal movement, the trend may be unsustainable and ready for a retracement. Remember, MACD is measuring the distance between moving averages. If that distance remains constant, MACD will trend sideways.
      `,
    conflictingCopy: (symbol) =>
      `MACD is trending sideways below the 0 (zero) line. This often occurs when a stock is trending bearish. Check previous measurements for ${symbol} to guage how far away it is from the zero line. If it's within a typical range, the trend is likely more sustainable. If it's outside the normal movement, the trend may be unsustainable and ready for a retracement. Remember, MACD is measuring the distance between moving averages. If that distance remains constant, MACD will trend sideways.
      `,
  },
  {
    key: `TRENDING_NEUTRAL_HIGHER`,
    supportingCopy: (symbol) =>
      `MACD is trending sideways above the 0 (zero) line. This often occurs when a stock is trending bullish. Check previous measurements for ${symbol} to guage how far away it is from the zero line. If it's within a typical range, the trend is likely more sustainable. If it's outside the normal movement, the trend may be unsustainable and ready for a retracement. Remember, MACD is measuring the distance between moving averages. If that distance remains constant, MACD will trend sideways.
      `,
    conflictingCopy: (symbol) =>
      `MACD is trending sideways above the 0 (zero) line. This often occurs when a stock is trending bullish. Check previous measurements for ${symbol} to guage how far away it is from the zero line. If it's within a typical range, the trend is likely more sustainable. If it's outside the normal movement, the trend may be unsustainable and ready for a retracement. Remember, MACD is measuring the distance between moving averages. If that distance remains constant, MACD will trend sideways.
      `,
  },
  {
    key: `CROSSING_UP_SIGNAL_LINE`,
    supportingCopy: () =>
      `The main MACD line just crossed up over the signal line. If MACD has recently been trending bearish, this could be a sign that momentum is shifting bullish. If MACD has been trending sideways, disregard this signal.`,
    conflictingCopy: () =>
      `The main MACD line just crossed up over the signal line. If MACD has recently been trending bearish, this could be a sign that momentum is shifting bullish. If MACD has been trending sideways, disregard this signal.`,
  },
  {
    key: `CROSSING_DOWN_SIGNAL_LINE`,
    supportingCopy: () =>
      `The main MACD line just crossed down over the signal line. If MACD has recently been trending bullish, this could be a sign that momentum is shifting bearish. If MACD has been trending sideways, disregard this signal.`,
    conflictingCopy: () =>
      `The main MACD line just crossed down over the signal line. If MACD has recently been trending bullish, this could be a sign that momentum is shifting bearish. If MACD has been trending sideways, disregard this signal.`,
  },
  {
    key: `HISTOGRAM_INCREASING_POSITIVE`,
    supportingCopy: (symbol) =>
      `Notice the MACD histogram is increasing, which shows that bullish momentum is gaining. This is a positive signal during a bullish trend. Just be careful it doesn't increase too quickly. That could indicate an unsustainable level of volatility. Reference previous histogram readings to see what is typical on ${symbol}.`,
    conflictingCopy: (symbol) =>
      `Notice the MACD histogram is increasing, which shows that bullish momentum is gaining. This is a positive signal during a bullish trend. Just be careful it doesn't increase too quickly. An overextended histogram combined with Deep Sky forecasting a downward move could indicate an unsustainable level of volatility in price. Reference previous histogram readings to see what is typical on ${symbol}.`,
  },
  {
    key: `HISTOGRAM_DECREASING_POSITIVE`,
    supportingCopy: (symbol) =>
      `Notice the MACD histogram is decreasing, which shows that bullish momentum is becoming less aggressive. This alone does not signal an end to a bullish trend. Just be careful if you are considering any new bullish trades. There could be a short-term retracement or a reversal. Consider tightening stops on any current bullish trades.\n\nIf the dominant momentum on ${symbol} is bearish, the declining histogram supports a bearish trade idea.`,
    conflictingCopy: (symbol) =>
      `Notice the MACD histogram is decreasing, which shows that bullish momentum is becoming less aggressive. This alone does not signal an end to a bullish trend. Just be careful if you are considering any new bullish trades. There could be a short-term retracement or a reversal. Consider tightening stops on any current bullish trades.\n\nIf the dominant momentum on ${symbol} is bearish, the declining histogram supports a bearish trade idea.`,
  },
  {
    key: `HISTOGRAM_INCREASING_NEGATIVE`,
    supportingCopy: (symbol) =>
      `Notice the MACD histogram is increasing on the negative side of the scale, which shows that bearish momentum is gaining. This is a positive signal during a bearish trend. Just be careful it doesn't increase too quickly. That could indicate an unsustainable level of volatility. Reference previous histogram readings to see what is typical on ${symbol}.`,
    conflictingCopy: (symbol) =>
      `Notice the MACD histogram is increasing on the negative side of the scale, which shows that bearish momentum is gaining. This is a positive signal during a bearish trend. Just be careful it doesn't increase too quickly. That could indicate an unsustainable level of volatility. Reference previous histogram readings to see what is typical on ${symbol}.`,
  },
  {
    key: `HISTOGRAM_DECREASING_NEGATIVE`,
    supportingCopy: (symbol) =>
      `Notice the MACD histogram is decreasing on the negative side of the scale, which shows that bearish momentum is becoming less aggressive. This alone does not signal an end to a bearish trend. Just be careful if you are considering any new bearish trades.\n\nIf the dominant momentum on ${symbol} is bullish, the declining histogram supports a new bullish trade idea.`,
    conflictingCopy: (symbol) =>
      `Notice the MACD histogram is decreasing on the negative side of the scale, which shows that bearish momentum is becoming less aggressive. This alone does not signal an end to a bearish trend. Just be careful if you are considering any new bearish trades.\n\nIf the dominant momentum on ${symbol} is bullish, the declining histogram supports a new bullish trade idea.`,
  },
  {
    key: `TOP_UP_BOTTOM_UP`,
    supportingCopy: (symbol) =>
      `Bollinger Bands are both trending up on ${symbol}. This supports a bullish trend and bullish trades.\n\nPro Tip: Remember, the price movement should be contained within the bands. Be cautious if you see movement outside of the top band. That level of volatility is usually accompanied by a pullback in a period or two. Also, be careful if the price tries and fails to return near the upper band. That is a signal that momentum is decaying.`,
    conflictingCopy: (symbol) =>
      `Bollinger Bands are both trending up on ${symbol}. This supports a bullish trend and bullish trades.\n\nWith Deep Sky forecasting a move lower, double-check how price action has been interacting with the top band. Remember, the price movement should be contained within the bands. Be cautious if you see movement outside of the top band. That level of volatility is usually accompanied by a pullback in a period or two. Also, be careful if price tries and fails to return near the upper band. That is a signal that momentum is decaying.`,
  },
  {
    key: `TOP_DOWN_BOTTOM_DOWN`,
    supportingCopy: (symbol) =>
      `Bollinger Bands are both trending down on ${symbol}. This supports a bearish trend and bearish trades.\n\nPro Tip: Remember, the price movement should be contained within the bands. Be cautious if you see movement outside of the bottom band. That level of volatility is usually accompanied by a pullback in a period or two. Also, be careful if the price tries and fails to return near the bottom band. That is a signal that momentum is decaying.`,
    conflictingCopy: (symbol) =>
      `Bollinger Bands are both trending down on ${symbol}. This supports a bearish trend and bearish trades.\n\nWith Deep Sky forecasting a move higher, double-check how price action has been interacting with the bottom band. Remember, the price movement should be contained within the bands. Be cautious if you see movement outside of the bottom band. That level of volatility is usually accompanied by a pullback in a period or two. Also, be careful if the price tries and fails to return near the bottom band. That is a signal that momentum is decaying.`,
  },
  {
    key: `TOP_UP_BOTTOM_DOWN`,
    supportingCopy: () =>
      `The Bollinger Bands are spreading apart. This indicates a quick increase in price volatility. This indication alone does not suggest a directional bias.`,
    conflictingCopy: () =>
      "Copy is for the briefing only. This instance has no directional bias, so it will always be `Supporting` the DS forecast.",
  },
  {
    key: `TOP_DOWN_BOTTOM_UP`,
    supportingCopy: () =>
      `The Bollinger Bands are squeezing together, which indicates consolidation. This sideways move is usually concluded with a significant breakout. Often, the longer the squeeze persists, the greater the breakout volatility. There is no directional bias. Be cautious if you attempt to trade the breakout. They are often followed with a whipsaw.`,
    conflictingCopy: () =>
      `The Bollinger Bands are squeezing together, which indicates consolidation. This sideways move is usually concluded with a significant breakout. Often, the longer the squeeze persists, the greater the breakout volatility. There is no directional bias. Be cautious if you attempt to trade the breakout. They are often followed with a whipsaw.`,
  },
  {
    key: `TOP_NEUTRAL_BOTTOM_UP`,
    supportingCopy: () =>
      `The top Bollinger Band is sideways, while the bottom is moving up. This indicates declining bearish momentum and volatility. Soon, the top band will either turn up and better confirm a bullish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
    conflictingCopy: () =>
      `The top Bollinger Band is sideways, while the bottom is moving up. This indicates declining bearish momentum and volatility. Soon, the top band will either turn up and better confirm a bullish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
  },
  {
    key: `TOP_NEUTRAL_BOTTOM_DOWN`,
    supportingCopy: () =>
      `The top Bollinger Band is sideways, while the bottom is moving down. This indicates increasing bearish momentum and volatility. Soon, the top band will either turn down and better confirm a bearish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
    conflictingCopy: () =>
      `The top Bollinger Band is sideways, while the bottom is moving down. This indicates increasing bearish momentum and volatility. Soon, the top band will either turn down and better confirm a bearish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
  },
  {
    key: `TOP_UP_BOTTOM_NEUTRAL`,
    supportingCopy: () =>
      `The top Bollinger Band is moving up, while the bottom is sideways. This indicates increasing bullish momentum and volatility. Soon, the bottom band will either turn up and better confirm a bullish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
    conflictingCopy: () =>
      `The top Bollinger Band is moving up, while the bottom is sideways. This indicates increasing bullish momentum and volatility. Soon, the bottom band will either turn up and better confirm a bullish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
  },
  {
    key: `TOP_DOWN_BOTTOM_NEUTRAL`,
    supportingCopy: () =>
      `The top Bollinger Band is moving down, while the bottom is sideways. This indicates decreasing bullish momentum and volatility. Soon, the bottom band will either turn down and better confirm a bearish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
    conflictingCopy: () =>
      `The top Bollinger Band is moving down, while the bottom is sideways. This indicates decreasing bullish momentum and volatility. Soon, the bottom band will either turn down and better confirm a bearish move, or the bands will begin squeezing, signaling price consolidation and declining volatility.`,
  },
];

const defaultCopy = {
  key: "",
  supportingCopy: () => "",
  conflictingCopy: () => "",
};

/**
 * This function will return the copy text for the stock based on selcted key
 * @param {String} matchingKey copy matching key from INDICATORS_KEYS
 * @param {Object} stockContext stock context ( stock )
 */
const getIndicatorCopy = ({ matchingKey, stockContext }) => {
  const { symbol, isSupporting } = stockContext;

  const matchingObject =
    copies.find((item) => item.key === matchingKey) || defaultCopy;

  if (isSupporting) {
    return matchingObject.supportingCopy(symbol);
  }

  return matchingObject.conflictingCopy(symbol);
};

export default getIndicatorCopy;
