export enum Sentiments {
  VERY_BULLISH = "VERY_BULLISH",
  BULLISH = "BULLISH",
  NEUTRAL = "NEUTRAL",
  BEARISH = "BEARISH",
  VERY_BEARISH = "VERY_BEARISH",
  SUPPORTIVE = "SUPPORTIVE",
  CONFLICTING = "CONFLICTING",
  UNKNOWN = "UNKNOWN",
  NOT_PREDICTABLE = "NOT_PREDICTABLE",
}

export enum SentimentsText {
  VERY_BULLISH = "VERY BULLISH",
  BULLISH = "BULLISH",
  NEUTRAL = "NEUTRAL",
  BEARISH = "BEARISH",
  VERY_BEARISH = "VERY BEARISH",
  SUPPORTIVE = "SUPPORTIVE",
  CONFLICTING = "CONFLICTING",
  UNKNOWN = "UNKNOWN",
  NOT_PREDICTABLE = "NOT PREDICTABLE",
}

export enum SentimentColours {
  VERY_BULLISH = "#0093D1",
  BULLISH = "#A6CCD8",
  NEUTRAL = "#C1C8C9",
  BEARISH = "#EABE94",
  VERY_BEARISH = "#FF8603",
}