function compare(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}


const filterByPrice = (data, selectedFilters) => {
  const orderedSelectedFilters = [...selectedFilters].sort(compare)
  const isSelectAll = orderedSelectedFilters.find(item => item?.id === 0);

  if (isSelectAll || selectedFilters.length === 0) {
    return data
  }

  const filteredData = []

  selectedFilters.forEach(filterItem => {
    const chunk = data.filter(item => (filterItem.min === null ? true : (item?.previousClose >= filterItem.min)) && (filterItem.max === null ? true : (item?.previousClose <= filterItem.max)))
    filteredData.push(...chunk)
  })

  return filteredData
}

export default filterByPrice
