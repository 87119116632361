import React, { useState } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import Terms from "src/components/Terms";
import Privacy from "src/components/Privacy";
import logo from "src/assets/ds-logo-coloured.svg";

import {
  Grid,
  makeStyles,
  Paper,
  Hidden,
  Divider,
  Box,
  Dialog,
  Button,
} from "@material-ui/core";

interface CrestProps {
  children?: ReactNode;
  leftColumnComponent?: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  paper: {
    maxWidth: 1000,
    minHeight: 0,
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "auto",
    background: "#fff",
    padding: theme.spacing(2),
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  logoContainer: {
    paddingBottom: 20,
    maxWidth: 200
  },
  logo: {
    paddingLeft: 12
  },
  gridContainer: {
    height: "100%",
  },
  footer: {
    fontSize: 11,
    lineHeight: "16px",
    color: "#808080",
    padding: theme.spacing(1, 0, 0, 0),
  },
}));

const RegisterCrest: FC<CrestProps> = ({ children, leftColumnComponent }) => {
  const classes = useStyles();
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);

  return (
    <div className={classes.root}>
      <Dialog open={showTerms} onBackdropClick={() => setShowTerms(false)}>
        <Box m={2}>
          <Terms />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowTerms(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Dialog open={showPrivacy} onBackdropClick={() => setShowPrivacy(false)}>
        <Box m={2}>
          <Privacy />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowPrivacy(false)}
          >
            Close
          </Button>
        </Box>
      </Dialog>
      <Paper className={classes.paper}>
        <Grid
          container
          alignItems="stretch"
          direction="row"
          justify="flex-start"
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={3} className={classes.logoContainer}>
            <Box display="flex" justifyContent="flex-start">
              <img
                src={logo}
                alt="bv logo"
                className={classes.logo}
                width="100"
                height="88"
              />
            </Box>
            <Box>{leftColumnComponent}</Box>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <Divider orientation="vertical" variant="middle" />
            </Grid>
          </Hidden>
          <Grid container item md={9} xs={12} alignContent="center">
            {children}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

RegisterCrest.propTypes = {
  children: PropTypes.node,
};

export default RegisterCrest;
