import React from 'react';
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Box,
  Card,
  Button,
  Theme,
  IconButton,
  withStyles,
} from "@material-ui/core";
import {
  ArrowForward,
  Close,
  Done,
  Star,
  StarOutline,
} from "@material-ui/icons";
import UpLotsIcon from "src/assets/icons/UpLotsIcon";
import UpIcon from "src/assets/icons/UpIcon";
import DownIcon from "src/assets/icons/DownIcon";
import DownLotsIcon from "src/assets/icons/DownLotsIcon";
import getFormattedScore from "src/utils/prediction/getFormattedScore";
import { Sentiments } from "src/types/Sentiments";
import { StockItem } from "src/slices/stocks";
import { Skeleton } from "@material-ui/lab";
import useWatchList from "src/hooks/useWatchList";
import TradingViewSingleTicker from "../TradingViewSingleTicker";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
  },
  rootPadding: {
    padding: theme.spacing(0.5, 2.5, 0.5, 2.5),
  },
  sectorName: {
    color: "#707680",
  },
  forexInfo: {
    padding: theme.spacing(1, 2.5),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    borderBottom: `1px solid #DBD7D2`,
  },
  listItemContainer: {
    flexGrow: 1,
  },
  squareBox: {
    minHeight: 24,
    minWidth: "64px",
    borderRadius: 2,
    padding: "5px 10px 3px 10px",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
  },
  buttonPickScore: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  squareBoxTextContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
  },
  squareBoxText: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
    alignItems: "center",
  },
  buttonViewBriefing: {
    padding: theme.spacing(0.5, 0),
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  viewBriefingWrapper: {
    borderTop: `1px solid #DBD7D2`,
  },
  skeletonWrapper: {
    height: 350,
    width: 360,
  },
  iconStyle: {
    fontSize: 14,
  },
  watchListButton: {
    padding: theme.spacing(0.5),
  },
  label: {
    minWidth: "142px",
    paddingRight: theme.spacing(2),
    position: "relative",
    bottom: "2px",
  },
}));

const OrangeStar = withStyles({
  root: {
    color: "#FF8500",
  },
})(Star);

const PredictionBox = ({ direction, text, hideIcon, color }) => {
  const classes = useStyles();
  let icon;
  if (direction === Sentiments.BULLISH) {
    icon = <UpIcon className={classes.iconStyle} />;
  }

  if (direction === Sentiments.VERY_BULLISH) {
    icon = <UpLotsIcon className={classes.iconStyle} />;
  }

  if (direction === Sentiments.BEARISH) {
    icon = <DownIcon className={classes.iconStyle} />;
  }

  if (direction === Sentiments.VERY_BEARISH) {
    icon = <DownLotsIcon className={classes.iconStyle} />;
  }

  return (
    <Box
      className={classes.squareBox}
      style={{
        backgroundColor: color,
        // minWidth: "102px",
      }}
    >
      <Box className={classes.squareBoxTextContainer}>
        <Typography className={classes.squareBoxText}>
          {text} {!hideIcon ? icon : ""}
        </Typography>
      </Box>
    </Box>
  );
};

PredictionBox.propTypes = {
  direction: PropTypes.string,
  hideIcon: PropTypes.bool,
  text: PropTypes.string,
};

const ListItem = ({ label, children }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.rootPadding}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Typography variant="body1" className={classes.label}>
        {label}
      </Typography>
      {children}
    </Box>
  );
};

ListItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  label: PropTypes.string,
};

interface IForexCard {
  forexData: StockItem;
  isLoading?: boolean;
}

const ForexCard = ({ forexData, isLoading }: IForexCard) => {
  const classes = useStyles();
  const { addToWatchList, removeFromWatchList, isInWatchList } = useWatchList();
  const watchListed = isInWatchList(forexData?.symbol);
  const toggleWatchListed = () => {
    if (watchListed) {
      removeFromWatchList(forexData?.symbol);
    } else {
      addToWatchList(forexData?.symbol);
    }
  };
  const sentiment = forexData?.sentiment;
  const shortSentiment = forexData?.shortSentiment;

  if (isLoading) {
    return (
      <Card className={classes.skeletonWrapper}>
        <Skeleton
          animation="wave"
          width="80%"
          style={{ margin: 6, marginLeft: 50 }}
        />
        <Skeleton animation="wave" variant="rect" width={435} height={280} />
        <Skeleton
          animation="wave"
          width="80%"
          style={{ margin: 6, marginLeft: 50 }}
        />
      </Card>
    );
  }

  return (
    <Card className={classes.root} id="joy-ride-forex-card">
      <Box className={classes.forexInfo}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography variant="h2" style={{ display: "flex" }}>
            <TradingViewSingleTicker
              widgetProps={{
                symbol: `${forexData.symbol}`.replace("/", ""),
                colorTheme: "light",
                width: 280,
                isTransparent: true,
              }}
            />
          </Typography>
          {!watchListed ? (
            <IconButton
              className={classes.watchListButton}
              onClick={toggleWatchListed}
              id="joy-ride-add-to-watchlist"
            >
              <StarOutline color="secondary" />
            </IconButton>
          ) : (
            <IconButton
              className={classes.watchListButton}
              onClick={toggleWatchListed}
              id="joy-ride-add-to-watchlist"
            >
              <OrangeStar />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box pt={1} pb={1} className={classes.listItemContainer}>
        <ListItem label="Direction">
          <Box
            textAlign="center"
            marginRight="8px"
            display="flex"
            flexGrow="1"
            flexDirection="column"
          >
            <Typography variant="caption">15 Min</Typography>
            <PredictionBox
              direction={shortSentiment?.displayShortDirection}
              color={shortSentiment?.shortColor}
            />
          </Box>
          <Box
            textAlign="center"
            display="flex"
            flexGrow="1"
            flexDirection="column"
          >
            <Typography variant="caption">1 Day</Typography>
            <PredictionBox
              direction={sentiment?.displayDirection}
              color={sentiment?.color}
            />
          </Box>
        </ListItem>

        {/* <ListItem label="Price Prediction (15m)">
          <PredictionBox
            direction={forexData.predictedDisplayDirection}
            text={forexData?.rangePrediction}
            color={sentiment?.color}
            hideIcon
          />
        </ListItem> */}
        {forexData?.averageScore && (
          <ListItem label="Sky Score">
            <Button
              variant="outlined"
              className={classes.buttonPickScore}
              fullWidth
              style={{ marginRight: "8px" }}
            >
              {getFormattedScore(forexData?.averageShortScore)}
            </Button>
            <Button
              variant="outlined"
              className={classes.buttonPickScore}
              fullWidth
            >
              {getFormattedScore(forexData?.averageScore)}
            </Button>
          </ListItem>
        )}

        <ListItem label="Technical Indicators">
          <Button
            variant="outlined"
            className={classes.buttonPickScore}
            fullWidth
            style={{ marginRight: "8px" }}
          >
            <Done fontSize="inherit" />{" "}
            {forexData?.combinedShortDirection?.supporting}{" "}
            <Box
              pl={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Close fontSize="inherit" />
              {forexData?.combinedShortDirection?.conflicting}
            </Box>
          </Button>
          <Button
            variant="outlined"
            className={classes.buttonPickScore}
            fullWidth
          >
            <Done fontSize="inherit" />{" "}
            {forexData?.combinedDirection?.supporting}{" "}
            <Box
              pl={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Close fontSize="inherit" />
              {forexData?.combinedDirection?.conflicting}
            </Box>
          </Button>
        </ListItem>
      </Box>

      <Box className={`${classes.rootPadding} ${classes.viewBriefingWrapper}`}>
        <Button
          color="primary"
          className={classes.buttonViewBriefing}
          component={RouterLink}
          to={`/dashboard/forex/short-briefing/${forexData.symbol}`}
          target="_blank"
        >
          VIEW BRIEFING&nbsp;&nbsp;
          <ArrowForward />
        </Button>
      </Box>
    </Card>
  );
};

ForexCard.defaultValues = {
  data: {
    modelPredictedSectorDirection: "",
    predictedDirection: "",
    combinedDirection: {},
  },
};

ForexCard.propTypes = {
  data: PropTypes.shape({
    modelPredictedSectorDirection: PropTypes.string,
    predictedDirection: PropTypes.string,
  }),
  score: PropTypes.number,
  forexData: PropTypes.shape({}),
};

export default React.memo(ForexCard);
