import Joyride, { CallBackProps, STATUS, ACTIONS } from "react-joyride";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Step } from "react-joyride";
import useAuth from "src/hooks/useAuth";
import { useQuery } from "src/hooks/useQuery";
import { useNavigate } from "react-router";

interface IJoyride {
  id: string;
  shouldRunUnauthenticated?: boolean;
  steps: Step[];
  disabledByDefault?: boolean;
}

const JoyrideEntry: FC<IJoyride> = ({
  id,
  shouldRunUnauthenticated = false,
  disabledByDefault,
  steps,
}) => {
  const [run, setRun] = useState(() =>
    disabledByDefault ? false : localStorage.getItem(id) === null
  );

  const { isAuthenticated } = useAuth();
  const query = useQuery();
  const navigate = useNavigate();

  const stopJoyRide = useCallback(() => {
    setRun(false);
    navigate(window.location.pathname, { replace: true });
  }, [navigate]);

  const handleJoyrideCallback = useCallback(
    (data: CallBackProps) => {
      const { status, action } = data;
      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
      if (finishedStatuses.includes(status)) {
        stopJoyRide();
        localStorage.setItem(id, "true");
      }
      const finishedActionStatuses: string[] = [ACTIONS.CLOSE];
      if (finishedActionStatuses.includes(action)) {
        stopJoyRide();
      }
    },
    [stopJoyRide, id]
  );

  useEffect(() => {
    if (query.get("tour") === "true") {
      setRun(true);
    }
  }, [query, navigate]);

  const shouldRun = useMemo(() => {
    return run && (isAuthenticated || shouldRunUnauthenticated);
  }, [run, isAuthenticated, shouldRunUnauthenticated]);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      run={shouldRun}
      continuous={true}
      scrollToFirstStep={true}
      showSkipButton={true}
      steps={steps}
      styles={{
        options: {
          zIndex: 10000000000,
          primaryColor: "#005E87",
          width: "396px",
        },
      }}
    />
  );
};

export default JoyrideEntry;
