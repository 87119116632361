import { useMemo, FC } from "react";
import Fuse from "fuse.js";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import useStocks from "src/hooks/useStocks";
import { useLocation, useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  notchedOutline: {
    borderColor: "#fff !important",
  },
  input: {
    color: "#fff",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#fff",
    },
  },
  cssLabel: {
    color: "#fff !important",
  },
  cssOutlinedInput: {
    color: "#fff",
    "&$cssFocused $notchedOutline": {
      borderColor: "#fff",
    },
  },
  root: {
    minWidth: "60%",
    [theme.breakpoints.up("sm")]: {
      minWidth: "300px",
    },
  },
}));

const fuseOptions = {
  keys: ["symbol", "name"],
};

const Search: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { availableStocks } = useStocks();

  const fuse = useMemo(() => {
    return new Fuse(availableStocks, fuseOptions);
  }, [availableStocks]);

  const location = useLocation();

  return (
    <>
      <Autocomplete
        id="stock-search"
        options={availableStocks}
        disableClearable
        classes={{
          option: `${classes.option} brochure`,
        }}
        className={classes.root}
        autoHighlight
        getOptionLabel={(option) => option?.name ?? ""}
        // defaultValue={selectedStock}
        // value={selectedStock}
        getOptionSelected={(option, value) => option.symbol === value.symbol}
        filterOptions={(options, searchState) => {
          if (searchState.inputValue === "") {
            return options;
          }
          return (
            fuse
              .search(searchState.inputValue)
              // @ts-ignore
              .map((search) => search.item)
          );
        }}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);
          return (
            <div>
              {parts.map((part, index) => (
                <span
                  key={index}
                  className='brochure'
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                    color: part.highlight ? "#03CAFF" : "black",
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
        onChange={(e, option) => {
          if (location.pathname.includes("dashboard") && option) {
            navigate(`/dashboard/briefing/${option.symbol}`);
          }
          if (option?.itemType === "currency") {
            navigate(`/dashboard/forex/short-briefing/${option.symbol}`);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a Stock"
            className={classes.cssLabel}
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{
              ...params.inputProps,
              form: {
                autoComplete: "off",
              },
            }}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: {
                input: classes.cssLabel,
                root: classes.cssOutlinedInput,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
        )}
      />
    </>
  );
};

export default Search;
