import React from "react";
import Header from "../helpers/Header";
import { Link as RouterLink } from "react-router-dom";
import SelectPlanCard from "src/components/DetailCards/SelectPlanCard/SelectPlanCard";
import getPlans from "./helpers/getPlans";
import { Grid, Button, Box, Typography } from "@material-ui/core";
const plans = getPlans();

const SelectPlan = ({ state, updateState }) => {
  return (
    <>
      <Header
        title="Select your plan"
        description="Try our special offer or select a longer term plan for greater savings. All our plans include access to all application features, including 1-2-5-10-15 period predictions, automated technical analysis, and more!"
      />
      <Grid container spacing={2}>
        {plans?.map((plan, index) => (
          <Grid
            item
            container
            sm={index === 0 ? 12 : 6}
            md={4}
            justify={index === 0 ? "center" : "space-evenly"}
          >
            <SelectPlanCard
              labels={plan}
              checked={state?.selectedPlan === plan?.id}
              onClick={() => updateState({ selectedPlan: plan?.id })}
            />
          </Grid>
        ))}

        <Box pl={1} mb={3}>
          <Typography color="textSecondary">(All prices in USD)</Typography>
        </Box>
      </Grid>
      <Box
        mt={2}
        width={1}
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          alignContent="center"
          justify="flex-end"
        >
          <Grid item md={2} xs={12} alignContent="center">
            <Box mt={1}>
              <Button
                fullWidth
                color="primary"
                to="/login"
                component={RouterLink}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button fullWidth color="primary" type="submit" variant="contained">
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SelectPlan;
