import PropTypes from "prop-types";
import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Checkbox from "@material-ui/core/Checkbox";
import { Card, Box, Button, Typography } from "@material-ui/core";

const priceRanges = [
  { label: "Select All", min: null, max: null, id: 0 },
  { label: "$0 - $50", min: 0, max: 50, id: 1 },
  { label: "$50 - $100", min: 50, max: 100, id: 2 },
  { label: "$100 - $250", min: 100, max: 250, id: 3 },
  { label: "$250 - $500", min: 200, max: 250, id: 4 },
  { label: "$500 - $1000", min: 500, max: 1000, id: 5 },
  { label: "$1000+", min: 1000, max: null, id: 6 },
];

const PriceFilter = ({
  onClose,
  selectedPriceFilters,
  setSelectedPriceFilters,
}) => {
  const selectedPriceFiltersIds = selectedPriceFilters?.map((item) => item?.id);

  const handleToggle = (selectedItem) => {
    if (selectedItem?.id === 0) {
      if (selectedPriceFilters.find((item) => item?.id === 0)) {
        return setSelectedPriceFilters([]);
      }
      return setSelectedPriceFilters(priceRanges);
    }

    if (selectedPriceFiltersIds.includes(selectedItem.id)) {
      const newFilters = selectedPriceFilters?.filter(
        (item) => item.id !== selectedItem.id
      );

      return setSelectedPriceFilters(newFilters);
    }

    return setSelectedPriceFilters([...selectedPriceFilters, selectedItem]);
  };

  return (
    <Card>
      <List>
        {priceRanges.map((item) => {
          return (
            <Box minWidth={225}>
              <ListItem
                key={item.label}
                divider
                dense
                button
                style={{ paddingTop: 0, paddingBottom: 0 }}
                onClick={() => handleToggle(item)}
              >
                <Checkbox
                  checked={selectedPriceFiltersIds.includes(item.id)}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  color="primary"
                />
                <Typography variant="body1" color="textPrimary">
                  {item.label}
                </Typography>
              </ListItem>
            </Box>
          );
        })}
      </List>

      <Box p={2} display="flex" justifyContent="space-between">
        <Button onClick={onClose} variant="contained" color="primary">
          <Box pl={3} pr={3}>
            Ok
          </Box>{" "}
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </Box>
    </Card>
  );
};

PriceFilter.propTypes = {
  onClose: PropTypes.func,
  selectedPriceFilters: PropTypes.arrayOf(PropTypes.object),
  setSelectedPriceFilters: PropTypes.func,
};

export default PriceFilter;
