import validationSchema from "./helpers/formValidation";
import { Formik } from "formik";
import { Link as RouterLink } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  Button,
  Box,
  TextField,
  Link,
  FormControlLabel,
  Typography,
  Checkbox,
  Grid,
} from "@material-ui/core";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import useBoolean from "src/hooks/useBoolean";
import Header from "../helpers/Header";

const UserForm = () => {
  const showPassword = useBoolean(false);
  const showConfirmPassword = useBoolean(false);

  return (
    <Box pl={3} pr={5}>
      <Header
        title="Register a new account"
        description="Enter your information to create a new Deep Sky account."
      />
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          userName: "",
          phoneNumber: "",
          password: "",
          confirmPassword: "",
          agree: false,
          marketingOptIn: false,
          submit: null,
        }}
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    required
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={handleBlur}
                    type="email"
                    value={values.email}
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    fullWidth
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    label="Phone Number"
                    name="phoneNumber"
                    onBlur={handleBlur}
                    type="tel"
                    value={values.phoneNumber}
                    variant="outlined"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">
                    Your password must be at least 8 characters, and contain one
                    capital letter, one number or special character.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={Boolean(touched.userName && errors.userName)}
                    fullWidth
                    helperText={touched.userName && errors.userName}
                    label="Username"
                    name="userName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.userName}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                    type={showPassword.value ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={showPassword.toggle}
                          >
                            {showPassword.value ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    label="Confirm Password"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    variant="outlined"
                    type={showConfirmPassword.value ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={showConfirmPassword.toggle}
                          >
                            {showConfirmPassword.value ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        color="primary"
                        name="agree"
                        checked={values.agree}
                        onChange={() => setFieldValue("agree", !values.agree)}
                      />
                    }
                    label={
                      <>
                        I agree to the{" "}
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          color="primary"
                        >
                          Terms &amp; Conditions
                        </Link>
                      </>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        color="primary"
                        name="marketingOptIn"
                        checked={values.marketingOptIn}
                        onChange={() => setFieldValue("marketingOptIn", !values.marketingOptIn)}
                      />
                    }
                    label={
                      <Typography>
                        Deep Sky can contact me at this email address with
                        additional offers and marketing communications.
                      </Typography>
                    }
                  />
                  <ErrorPanel
                    errors={errors}
                    values={values}
                    touched={touched}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Box mt={1}>
                    <Button
                      fullWidth
                      color="primary"
                      to="/login"
                      component={RouterLink}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Box mt={1}>
                    <Button
                      fullWidth
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Next
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default UserForm;
