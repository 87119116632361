import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import User from 'src/models/User';

interface UsersState {
  user: User;
  isLoading: boolean;
}

const initialState: UsersState = {
  user: new User(),
  isLoading: false
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser(state: UsersState, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.user = user;
      state.isLoading = false;
    }
  }
});

export const reducer = slice.reducer;
export default slice;
