import { useEffect } from "react";
import type { FC, ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { snackActions } from "src/utils/SnackbarUtils";
import { useSelector } from "src/store";

interface AuthGuardProps {
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, logout } = useAuth();
  const { pathname, search } = useLocation();
  const storeState = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (storeState === undefined) {
      logout();
      snackActions.error(
        "Your session has ended. To continue working please re-log back in."
      );
    }
  }, [logout, storeState]);

  useEffect(() => {
    let redirectUrl = "";
    if (pathname) redirectUrl = pathname;
    if (search) redirectUrl += search;
    if (!isAuthenticated) {
      navigate(
        redirectUrl === ""
          ? "/login"
          : `/login?redirectUrl=${encodeURIComponent(redirectUrl)}`
      );
    }
  }, [isAuthenticated, pathname, search, navigate]);

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
