import faker from "faker";
import { getInitials } from "src/utils/string";
import { removeEmpty, serializeObj } from "src/utils/object";

export enum Roles {
  ADMIN,
}

export default class User {
  readonly id?: string;
  avatar?: string;
  firstName: string = "";
  lastName: string = "";
  username?: string = "";
  email: string = "";
  phoneNumber?: string;
  role?: string;
  emailConfirmed?: boolean;
  active?: boolean;
  token?: string = "";
  createdDate?: string;
  createdBy?: number;
  modifiedDate?: string;
  modifiedBy?: number;
  modifiedByName?: string;
  createdByName?: string;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  set fullName(value) {
    // do nothing
  }

  get initials(): string {
    return getInitials(this.fullName);
  }

  generateMockData(): void {
    this.firstName = faker.name.firstName();
    this.lastName = faker.name.lastName();
    this.email = faker.internet.email();
  }

  serialize() {
    return removeEmpty(serializeObj(this));
  }

  clone(): User {
    return new User(serializeObj(this));
  }

  static getMockUser(): User {
    const user = new User();
    user.generateMockData();
    return user;
  }
}
