// @ts-ignore
import React, { useState } from "react";
import { FC } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { validationMessage } from "src/utils/validations";
import { Modes } from "src/types/IForm";
import ChangePasswordForm from "./ChangePasswordForm";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import User from "src/models/User";

interface Props {
  [key: string]: any;
  user?: User;
}

const UserForm: FC<Props> = ({
  className,
  user,
  onSubmit,
  initialValues,
  onChangePasswordSubmit,
  hasSaveButton = true,
  mode = Modes.EDIT,
  isEditable = true,
  ...rest
}) => {
  const [changePassword, setChangePassword] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
    false
  );

  return (
    <Formik
      {...rest}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        firstName: Yup.string()
          .max(255)
          .required(validationMessage("First Name")),
        lastName: Yup.string()
          .max(255)
          .required(validationMessage("Last Name")),
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        submitForm,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <Typography variant="h3">User Information</Typography>
            <Box mt={3}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditable}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    required
                    helperText={touched.firstName && errors.firstName}
                    label="First Name"
                    name="firstName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditable}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    fullWidth
                    required
                    label="Last Name"
                    name="lastName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    variant="outlined"
                  />
                </Grid>

                {mode === Modes.CREATE && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        required
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((prev) => !prev)}
                                // onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        fullWidth
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                        label="Confirm Password"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        variant="outlined"
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setShowConfirmPassword((prev) => !prev)
                                }
                                // onMouseDown={handleMouseDownPassword}
                              >
                                {showConfirmPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onBlur={handleBlur}
                    value={values.email}
                    type="email"
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>

            <Box mt={2} mb={3}>
              <Grid container spacing={4}>
                {mode === Modes.EDIT && (
                  <Grid item xs={12}>
                    {!changePassword ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => setChangePassword(true)}
                      >
                        Change Password
                      </Button>
                    ) : (
                      <ChangePasswordForm
                        onCancel={() => setChangePassword(!changePassword)}
                        onSubmit={onChangePasswordSubmit}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box my={2}>
              <ErrorPanel errors={errors} values={values} touched={touched} />
            </Box>
            {hasSaveButton && (
              <Box p={2} display="flex" justifyContent="flex-end">
                <Box pl={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting || !isEditable}
                    onClick={submitForm}
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            )}
          </>
        </form>
      )}
    </Formik>
  );
};

UserForm.defaultProps = {
  onSubmit: () => {},
  onChangePasswordSubmit: () => {},
};

export default UserForm;
