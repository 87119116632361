import React from 'react';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { config } from 'src/config';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, redirect } = useAuth();
  const navigate = useNavigate();

  if (isAuthenticated) {
    let url = config.APP_HOME;

    if (redirect) url = redirect;

    navigate(url);
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
