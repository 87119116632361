import { FC, ReactNode } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import NeutralIcon from "src/assets/icons/NeutralIcon";
import UpLotsIcon from "src/assets/icons/UpLotsIcon";
import UpIcon from "src/assets/icons/UpIcon";
import DownIcon from "src/assets/icons/DownIcon";
import DownLotsIcon from "src/assets/icons/DownLotsIcon";
import { Sentiments } from "src/types/Sentiments";
import { Sentiment } from "src/utils/prediction/getSentiment";
import formatNumbers from "src/utils/formatNumbers";
import { isForex } from "src/utils/forex";
// import formatNumbers from "src/utils/formatNumbers";
// import { isShortBriefing } from "src/utils/shortBriefing";

export interface PriceBlockProps {
  className?: string;
  label?: string;
  sentiment?: Sentiment;
  periodForecast?: number[];
  minPrice?: number;
  maxPrice?: number;
  children?: ReactNode;
}

const useStyles = makeStyles((theme) => ({
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  },
  icon: {
    fontSize: "1.25rem",
    // filter: "drop-shadow(0px 1px 1px #333)",
  },
  priceBlock: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    borderRadius: "2px",
    height: "35px",
    width: "100%",
  },
  priceBlockWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "8px",
    width: "100%",
  },
  priceBlockLg: {
    minWidth: "65px",
  },
  priceBlockText: {
    lineHeight: "1.2",
  },
  sentimentColour: {
    backgroundColor: ({ sentiment }: PriceBlockProps) => sentiment?.color,
  },
}));

const PriceBlock: FC<PriceBlockProps> = ({
  className,
  sentiment,
  minPrice,
  maxPrice,
  label,
  ...rest
}) => {
  const classes = useStyles({ sentiment });
  const icon = (sentiment) => {
    switch (sentiment.displayDirection) {
      case Sentiments.VERY_BULLISH: {
        return <UpLotsIcon className={classes.icon} />;
      }
      case Sentiments.BULLISH: {
        return <UpIcon className={classes.icon} />;
      }
      case Sentiments.NEUTRAL: {
        return <NeutralIcon className={classes.icon} />;
      }
      case Sentiments.BEARISH: {
        return <DownIcon className={classes.icon} />;
      }
      case Sentiments.VERY_BEARISH: {
        return <DownLotsIcon className={classes.icon} />;
      }
    }
  };

  return (
    <Box className={`${classes.priceBlockWrapper} ${className}`} {...rest}>
      <Typography variant="caption">{label}</Typography>
      <Box
        className={`${classes.priceBlock} ${
          minPrice || maxPrice ? classes.priceBlockLg : null
        } ${classes.sentimentColour}`}
      >
        {icon(sentiment)}
        {!isForex() && maxPrice && minPrice && (
          <Box className={classes.flexColumn}>
            <>
              {maxPrice && (
                <Typography
                  variant="caption"
                  className={classes.priceBlockText}
                >
                  {formatNumbers(maxPrice)}
                </Typography>
              )}
              {minPrice && (
                <Typography
                  variant="caption"
                  className={classes.priceBlockText}
                >
                  {formatNumbers(minPrice)}
                </Typography>
              )}
            </>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PriceBlock;
