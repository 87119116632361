import { FC, ReactElement, ReactNode, useState } from "react";
import Carousel from "react-material-ui-carousel";

import {
  Button,
  Box,
  Typography,
  Container,
  Fade,
  Checkbox,
  Link,
  Backdrop,
  Divider,
  makeStyles,
} from "@material-ui/core";
import Lottie from "react-lottie";
import frame1 from "src/assets/lotties/onboarding/frame1.json";
import frame2 from "src/assets/lotties/onboarding/frame2.json";
import frame4 from "src/assets/lotties/onboarding/frame4.json";
import frame5 from "src/assets/lotties/onboarding/frame5.json";
import frame6 from "src/assets/lotties/onboarding/frame6.json";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
  carousel: {},
  carouselItem: {
    textAlign: "center",
    minHeight: 450,
    maxWidth: 500,
    margin: "auto",
    marginBottom: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    paddingTop: "100px",
    paddingBottom: "40px",
    overflow: "scroll",
  },
  buttons: {
    position: "relative",
    top: -27,
    "&> * + * ": {
      marginLeft: 100,
    },
  },
}));

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: frame1,
};

interface ItemProps {
  className?: string;
  children?: ReactNode;
  title?: string;
  lottie?: Lottie;
  description?: string | ReactElement;
  lastSlide?: boolean;
  onDeepSkyStart?: () => void;
  onDisclaimerClick?: () => void;
  index?: number;
  currentIndex?: number;
}

const Item: FC<ItemProps> = ({
  className,
  title,
  lottie,
  description,
  lastSlide,
  onDisclaimerClick,
  onDeepSkyStart,
  index,
  currentIndex,
}) => {
  const [agree, setAgree] = useState(false);

  return (
    <Box className={className} mb={2}>
      <Fade in timeout={2000}>
        <Typography variant="h2" color="primary" gutterBottom>
          {title}
        </Typography>
      </Fade>

      <Lottie options={{ ...defaultOptions, animationData: lottie }} />

      <Box mt={2}>
        <Typography style={{ color: "#40434A" }}>{description}</Typography>
      </Box>
      <Box mt={2}>
        {lastSlide && (
          <>
            <Box display="flex">
              <Checkbox
                checked={agree}
                onChange={() => setAgree((prev) => !prev)}
                name="agree"
              />
              <Box pt={2}>
                <Typography>
                  I understand and have read the{" "}
                  <Link
                    onClick={onDisclaimerClick}
                    style={{ cursor: "pointer" }}
                  >
                    disclaimer
                  </Link>{" "}
                  and take full responsibility for all trades I make.
                </Typography>
              </Box>
            </Box>
            <br />

            <Button
              variant="contained"
              color="primary"
              disabled={!agree}
              onClick={onDeepSkyStart}
            >
              Start Using Deep Sky
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

const Onboarding = () => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const navigate = useNavigate();

  const handleDisclaimerClick = () => {
    setOpenDisclaimer(true);
  };

  const handleDeepSkyStart = () => {
    localStorage.setItem("onboarding-completed", "true");
    navigate("/");
  };

  const items = [
    {
      title: "Welcome to Deep Sky. We’re here to help qualify your trades.",
      lottie: frame1,
      description:
        "Deep Sky is a trading assistant driven by artificial intelligence. We don’t have a crystal ball; we can’t see the future. We analyze lots and lots of data points to find predictable patterns in stocks & forex.",
    },
    {
      title: "Deep Sky Projections vs Technical Indicators",
      lottie: frame2,
      description: (
        <>
          <strong>Deep Sky Projections</strong> use advanced AI to find trading
          patterns and make future stock and forex behavior projections.{" "}
          <strong>Technical Indicators</strong> describe the current behavior of
          the stock or currency pair. Look for agreement between both of these
          pieces of information to find higher-quality trades.
        </>
      ),
    },
    {
      title: "Use The Sky Score™ to help find the highest quality projections.",
      lottie: frame4,
      description:
        "The Sky Score™ is a proprietary formula that combines data like market, sector, and individual security projections with technical indicators and historical projection accuracy. It works on a scale of 1-1000—the higher score, the better.",
    },
    {
      title:
        "There’s limits to what we can do. Learn to avoid trades during unpredictable events.",
      lottie: frame5,
      description: (
        <>
          If you are surprised by an event, so is Deep Sky. Use caution during
          non-typical volatility events like{" "}
          <strong>
            earnings, short squeezes, red-flag forex events, or other big moves.
          </strong>{" "}
          Deep Sky is best at analyzing consistent, predictable stock patterns,
          not huge swings.
        </>
      ),
    },
    {
      title: "Trade responsibly. Know your limits.",
      lottie: frame6,
      description:
        "Deep Sky is designed to help you make higher quality trading decisions, but it isn’t a magic 8-ball. Use your own judgment when making a trade and follow risk management best practices.",
      lastSlide: true,
    },
  ];

  return (
    <>
      <Container style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ textAlign: "center", marginTop: 40 }}>
          <Carousel
            className={classes.carousel}
            animation="slide"
            autoPlay={false}
            index={currentIndex}
            swipe
            navButtonsAlwaysInvisible
            cycleNavigation={false}
            next={(next) => setCurrentIndex(next)}
            prev={(prev) => setCurrentIndex(prev)}
          >
            {items.map((item, i) => (
              <Item
                className={classes.carouselItem}
                key={i}
                {...item}
                index={i}
                currentIndex={currentIndex}
                onDisclaimerClick={handleDisclaimerClick}
                onDeepSkyStart={handleDeepSkyStart}
              />
            ))}
          </Carousel>
        </div>
      </Container>
      <Container style={{ textAlign: "center" }} className={classes.buttons}>
        <Button
          style={{ fontWeight: 700 }}
          color="primary"
          onClick={() =>
            setCurrentIndex((prev) => (prev - 1 < 0 ? 0 : prev - 1))
          }
          disabled={currentIndex <= 0}
        >
          PREV
        </Button>
        <Button
          color="primary"
          style={{ fontWeight: 700 }}
          onClick={() => setCurrentIndex((prev) => prev + 1)}
          disabled={currentIndex >= items.length - 1}
        >
          NEXT
        </Button>
      </Container>
      <Backdrop
        open={openDisclaimer}
        onClick={() => setOpenDisclaimer(false)}
        className={classes.backdrop}
      >
        <Container>
          <Box style={{ background: "#f7f3ee" }} p={4}>
            <Typography variant="h1" color="textPrimary" gutterBottom>
              Disclaimer
            </Typography>
            <Divider />
            <br />
            <Typography color="textPrimary" gutterBottom>
              DEEP SKY TRADING ASSISTANT IS A STOCK ANALYSIS PLATFORM. IT IS NOT
              INTENDED TO BE TRADING OR INVESTING ADVICE. WE DO NOT RECOMMEND
              STOCKS TO BUY OR SELL. WE PROVIDE A PLATFORM TO ASSIST YOU IN
              MAKING YOUR OWN DECISIONS. PAST PERFORMANCE DOES NOT PREDICT
              FUTURE RESULTS. THE DEEP SKY TRADING ASSISTANT PLATFORM, ANALYSIS,
              AND MARKET DATA IS PROVIDED 'AS-IS' AND WITHOUT WARRANTY. <br />{" "}
              <br /> If the capital letters above were not enough, just to be
              100% crystal clear: forecasts made using technical analysis and
              artificial intelligence do not guarantee an outcome. Invest
              wisely. Always do your own careful due diligence and research
              before trading or investing.
            </Typography>
            <br />
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenDisclaimer(false)}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Container>
      </Backdrop>
    </>
  );
};

export default Onboarding;
