const mergePredictions = (shortPredictions, longPredictions) => {
  let allPredictions: any = [];
  for (let i = 0; i < shortPredictions.length; i++) {
    allPredictions.push({
      ...shortPredictions[i],
      ...longPredictions.find(
        (itmInner) => itmInner.symbol === shortPredictions[i].symbol
      ),
    });
  }
  return allPredictions;
};

export default mergePredictions;
