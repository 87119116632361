import earningsData from "src/constants/earnings-report-date.json";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjsBusinessTime from "dayjs-business-time";
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(dayjsBusinessTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);


/**
 * Find if the stock has earnings report in daysWindow
 * @param {*} symbol
 */
const hasNearEarningsReportDate = (symbol, daysWindow = 5) => {
  const getEarningReportDates = earningsData.filter(
    (item) => item.symbol === symbol
  );

  const startDate = dayjs(new Date()).subtract(daysWindow, "days");
  const endDate = dayjs(new Date()).addBusinessDays(daysWindow);

  return getEarningReportDates.some((item) =>
    dayjs(item.reportDate).isBetween(startDate, endDate)
  );
};

export default hasNearEarningsReportDate;
