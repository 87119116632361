const getScore = (score) => (1000 * score) / 300;

const getFormattedScore = (score = 0) => {
  const formattedScore = getScore(score);
  return (formattedScore || 0).toFixed(0);
};

const getFormattedScoreText = (score = 0) => {
  const formattedScore = getScore(score);
  let text = "USE CAUTION";

  if (formattedScore >= 580 && formattedScore <= 650) {
    text = "MODERATE CONFIDENCE";
  }

  if (formattedScore > 650) {
    text = "HIGH CONFIDENCE";
  }

  return text;
};

export { getFormattedScoreText };
export default getFormattedScore;
