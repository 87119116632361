import React from "react";
import parse from "html-react-parser";
import {
  Card,
  Box,
  makeStyles,
  useTheme,
  Typography,
  CardActionArea,
} from "@material-ui/core";
import RadioButtonWithDetails from "src/components/Shared/RadioButtonWithDetails";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 270,
    borderRadius: 4,
  },
  title: {
    textTransform: "uppercase",
    padding: 10,
    color: "#fff",
    textAlign: "center",
  },
}));

interface ISelectPlanCard {
  checked?: boolean;
  onClick?: () => void;
  labels: {
    color?: string;
    title?: string;
    price?: string;
    stickerColor?: string;
    stickerText?: string;
    description?: string;
    texts?: string[];
  };
}

const SelectPlanCard = ({ labels, onClick, checked }: ISelectPlanCard) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={onClick}>
        <Box
          style={{
            backgroundColor: labels?.color || theme?.palette?.primary?.main,
          }}
        >
          {labels?.title && (
            <Typography variant="h3" className={classes.title}>
              {parse(labels?.title)}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" flexDirection="column" p={3}>
          <Box mb={1}>
            <Typography variant="h1">{parse(labels?.price || "")}</Typography>
          </Box>
          <Typography variant="body1" align="center">
            {parse(labels?.description || "")}
          </Typography>
          <br />
          {Array.isArray(labels?.texts) && labels.texts.length > 0 && (
            <Typography variant="body1" align="center">
              {labels?.texts?.map((item) => parse(item))}
            </Typography>
          )}
          <br />
          {labels?.stickerText ? (
            <Box
              style={{
                color: "#fff",
                borderRadius: 4,
                padding: "5px 10px",
                backgroundColor:
                  labels?.stickerColor || theme?.palette?.primary?.main,
              }}
            >
              <Typography variant="h4">{labels?.stickerText}</Typography>
            </Box>
          ) : (
            <Box height={30} />
          )}
          <br />
          <RadioButtonWithDetails
            checked={checked}
            onChange={onClick}
            label="Select this plan"
          />
        </Box>
      </CardActionArea>
    </Card>
  );
};

SelectPlanCard.defaultProps = {
  labels: {
    texts: [],
  },
};

export default SelectPlanCard;
