import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import DashboardLayout from "./layouts/DashboardLayout";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./layouts/MainLayout";
import Register from "./pages/authentication/Register";
import Forgot from "./pages/authentication/Forgot";
import DetailsView from "./pages/users/DetailsView";
import Briefing from "./pages/briefing";
import ResetPassword from "./pages/authentication/ResetPassword";
import ErrorBoundaryPage from "./pages/system/ErrorBoundary";
import TSULogin from "./pages/authentication/TSULogin";
import MyWatchList from "./pages/my-watchlist";
import Onboarding from "./pages/onboarding";
import Forex from "./pages/forex";
import ForexBriefing from "./pages/forex-briefing";
import { default as UsersBrowse } from "./pages/users/BrowseView";
import { default as StocksBrowse } from "./pages/stocks/BrowseView";
import AdminGuard from "./components/AdminGuard";
import Accuracy from "./pages/accuracy";
import Brochure from "./pages/brochure";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const Overview = Loadable(lazy(() => import("./pages/home")));

// Error pages
const NotFound = Loadable(lazy(() => import("./pages/system/NotFound")));

// Legal
const Terms = Loadable(lazy(() => import("./pages/legal/Terms")));
const Privacy = Loadable(lazy(() => import("./pages/legal/Privacy")));

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: (
      <GuestGuard>
        <MainLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "tsu-login",
        element: <TSULogin />,
      },

      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot",
        element: <Forgot />,
      },
      {
        path: "onboarding",
        element: <Onboarding />,
      },
    ],
  },

  {
    path: "user",
    element: <MainLayout />,
    children: [
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    ],
  },

  {
    path: "/ai-based-stock-briefings-and-indicators-analysis/SPY",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Brochure />,
      },
    ],
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "/briefing/:symbol",
        element: <Briefing />,
      },
      {
        path: "/forex",
        element: <Forex />,
      },
      {
        path: "/forex/briefing/:currencyFrom/:currencyTo",
        element: <ForexBriefing />,
      },
      {
        path: "/forex/short-briefing/:currencyFrom/:currencyTo",
        element: <ForexBriefing />,
      },
      {
        path: "/my-watchlist",
        element: <MyWatchList />,
      },
      {
        path: "/admin/users",
        element: (
          <AdminGuard>
            <UsersBrowse />
          </AdminGuard>
        ),
      },
      {
        path: "/admin/stocks",
        element: (
          <AdminGuard>
            <StocksBrowse />
          </AdminGuard>
        ),
      },
      {
        path: "/admin/accuracy",
        element: (
          <AdminGuard>
            <Accuracy />
          </AdminGuard>
        ),
      },
      {
        path: "/users/edit",
        element: <DetailsView />,
      },
    ],
  },
  {
    path: "*",
    children: [
      {
        path: "401",
        element: <NotFound />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ErrorBoundaryPage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "terms",
    element: (
      <DashboardLayout>
        <Terms />
      </DashboardLayout>
    ),
  },
  {
    path: "privacy",
    element: (
      <DashboardLayout>
        <Privacy />
      </DashboardLayout>
    ),
  },
];

export default routes;
