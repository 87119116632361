import {
  SentimentColours,
  Sentiments,
  SentimentsText,
} from "src/types/Sentiments";
import chroma from "chroma-js";

const getPercentageChange = (A, B) => (B - A) / B;

export type Sentiment = {
  color: string;
  shortColor: string;
  label: string;
  displayDirection: Sentiments;
  displayShortDirection: Sentiments;
  direction: Sentiments;
};

/**
 *
 * @param A previous
 * @param B predicted
 * @returns sentiment
 */
const getSentiment = (previousClose, prediction): Sentiment => {
  const defaultValue = {
    color: SentimentColours.NEUTRAL,
    shortColor: SentimentColours.NEUTRAL,
    label: SentimentsText.NEUTRAL,
    direction: Sentiments.NEUTRAL,
    displayDirection: Sentiments.NEUTRAL,
    displayShortDirection: Sentiments.NEUTRAL,
  };

  if (!previousClose || !prediction) {
    return defaultValue;
  }

  const percentageDiff = getPercentageChange(previousClose, prediction);
  const isBullish = prediction > previousClose;
  const threshold = 3;
  const chromaThresold = 45;
  const chromaBullishFunction = chroma.scale([
    SentimentColours.BULLISH,
    SentimentColours.VERY_BULLISH,
  ]);
  const chromaBearishFunction = chroma.scale([
    SentimentColours.BEARISH,
    SentimentColours.VERY_BEARISH,
  ]);

  const isVery =
    percentageDiff * 100 > threshold || percentageDiff * 100 < -threshold;

  if (isBullish) {
    return {
      label: isVery ? SentimentsText.VERY_BULLISH : SentimentsText.BULLISH,
      color: chromaBullishFunction(
        Math.abs(percentageDiff) * chromaThresold
      ).hex(),
      shortColor: chromaBullishFunction(
        Math.abs(percentageDiff) * chromaThresold
      ).hex(),
      displayDirection: isVery ? Sentiments.VERY_BULLISH : Sentiments.BULLISH,
      displayShortDirection: isVery
        ? Sentiments.VERY_BULLISH
        : Sentiments.BULLISH,
      direction: Sentiments.BULLISH,
    };
  }

  return {
    label: isVery ? SentimentsText.VERY_BEARISH : SentimentsText.BEARISH,
    color: chromaBearishFunction(
      Math.abs(percentageDiff) * chromaThresold
    ).hex(),
    shortColor: chromaBearishFunction(
      Math.abs(percentageDiff) * chromaThresold
    ).hex(),
    displayDirection: isVery ? Sentiments.VERY_BEARISH : Sentiments.BEARISH,
    displayShortDirection: isVery
      ? Sentiments.VERY_BEARISH
      : Sentiments.BEARISH,
    direction: Sentiments.BEARISH,
  };
};

// Not in use
const getMomentumOutlookSentiment = ({
  previousCloseValues = [],
  predictions = [],
}) => {
  const aggregated = [...previousCloseValues, ...predictions];
  const dx1 = 7;
  const dx2 = 7;

  const difA = aggregated.map(
    (item, index) => (item - aggregated[index - dx1]) / dx1
  );
  const difB = difA.map((item, index) => (item - difA[index - dx2]) / dx2);
  const last15 = difB.slice(-15) || [];
  const output =
    last15.reduce((sum, a) => {
      return sum + a;
    }, 0) / (last15.length || 1);

  const positive4e4 = 0.0004;
  const negative4e4 = -0.0004;
  const positive1e4 = 0.0001;
  const negative1e4 = -0.0001;

  const lastOutput = output;
  const lastDayClose = previousCloseValues[previousCloseValues.length - 1];

  let direction = "";
  if (lastOutput > positive4e4 * lastDayClose) {
    direction = Sentiments.VERY_BULLISH;
  }
  if (lastOutput > 0 && lastOutput < positive4e4 * lastDayClose) {
    direction = Sentiments.BULLISH;
  }
  if (
    lastOutput > negative1e4 * lastDayClose &&
    lastOutput < positive1e4 * lastDayClose
  ) {
    direction = Sentiments.NEUTRAL;
  }
  if (lastOutput < 0 && lastOutput > negative4e4 * lastDayClose) {
    direction = Sentiments.BEARISH;
  }
  if (lastOutput < negative4e4 * lastDayClose) {
    direction = Sentiments.VERY_BEARISH;
  }

  return direction || Sentiments.NOT_PREDICTABLE;
};

export { getMomentumOutlookSentiment };
export default getSentiment;
