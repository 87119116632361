import { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Prediction from "src/models/Prediction";
import { RootState } from "src/store";
import Axios from "src/utils/axios";
import getCurrentDate from "src/utils/prediction/getCurrentDate";

interface IUsePrediction {
  predictions: Prediction[];
  currentDayPrediction: Prediction;
  sectorPredictions: Prediction[];
  marketsData: Prediction[];
  topPickObject: Prediction;
  previousPredictions: Prediction[];
  isLoading: boolean;
  stock: {
    symbol?: string;
    name?: string;
    label?: string;
    sectorName?: string;
    sectorSymbol?: string;
  };
}

const usePrediction = (symbol: string): [IUsePrediction, any] => {
  const [state, setState] = useState<IUsePrediction>({
    predictions: [],
    currentDayPrediction: new Prediction({}),
    topPickObject: new Prediction({}),
    marketsData: [],
    sectorPredictions: [],
    previousPredictions: [],
    stock: {},
    isLoading: false,
  });

  const sectorsData = useSelector(
    (reduxState: RootState) => reduxState.stocks.sectorsData
  );

  const marketsData = useSelector(
    (reduxState: RootState) => reduxState.stocks.marketsData
  );

  const stocks = useSelector((reduxState: RootState) => reduxState.stocks.data);

  const getPredictions = useCallback(async (stockSymbol, periods = 16) => {
    const params = {
      periods: periods || 16,
      date: getCurrentDate(),
    };
    try {
      const { data } = await Axios(`/briefings/${stockSymbol}`, { params });
      return (data || []).map((item) => new Prediction(item));
    } catch (e) {
      console.log("Error while fetching data");
      return [];
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setState((prev) => ({ ...prev, isLoading: true }));
      const data = await getPredictions(symbol);
      const previousPredictions = await getPredictions(symbol, -16);
      const stock = stocks.find((stock) => stock.symbol === symbol) ?? {
        sectorSymbol: "",
      };
      const sectorPredictions = sectorsData.filter(
        (sector) => sector.symbol === stock?.sectorSymbol
      );

      const params = {
        periods: 5,
        symbol,
        date: getCurrentDate(),
      };
      const { data: topPicks } = await Axios.get(`/top-picks`, { params });

      setState({
        currentDayPrediction: data[0] || new Prediction({}),
        predictions: data,
        sectorPredictions,
        marketsData: [],
        previousPredictions,
        topPickObject: new Prediction(topPicks?.[0] || {}),
        stock,
        isLoading: false,
      });
    };

    if (symbol) {
      fetchData();
    }
  }, [symbol, getPredictions, sectorsData, stocks]);

  return [{ ...state, marketsData }, setState];
};

export default usePrediction;
