import { FC, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { AlertTitle, Alert } from "@material-ui/lab";
import { Box, Grid, Typography } from "@material-ui/core";
import moneyFormatter from "src/utils/monetFormatter";
import hasNearEarningsReportDate from "src/utils/hasNearEarningsReportDate";

const useStyles = makeStyles((theme) => ({
  root: {},
  changeColor: {
    color: "#039600",
  },
}));

interface StockProps {
  label?: string;
  symbol?: string;
  price?: number;
  change?: string;
}

interface StockInfoHeaderProps {
  className?: string;
  stock?: StockProps;
  children?: ReactNode;
}

const StockInfoHeader: FC<StockInfoHeaderProps> = ({ stock }) => {
  const classes = useStyles();
  const { symbol, label, price, change } = stock ?? {};
  const hasNearEarningsDays = hasNearEarningsReportDate(symbol);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item>
          <Box display="flex" alignItems="baseline" flexWrap="wrap">
            <Box mr={2} mb={2}>
              <Typography variant="h1">
                {symbol}: {label}
              </Typography>
              <Typography variant="h2">{moneyFormatter(String(price))}</Typography>
            </Box>
            <Box>
              {hasNearEarningsDays && (
                <Alert
                  severity="warning"
                  variant="outlined"
                  style={{ marginBottom: 20 }}
                >
                  <AlertTitle>Warning - Volatile Markets</AlertTitle>
                  <strong>Check the earnings report date.</strong>
                </Alert>
              )}
            </Box>
          </Box>
          {change && (
            <Typography className={classes.changeColor} variant="body1">
              {change}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default StockInfoHeader;
