import { useEffect, useState } from "react";
import type { FC } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import useAuth from "src/hooks/useAuth";
import { validationMessage } from "src/utils/validations";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import {
  Button,
  Box,
  TextField,
  Typography,
  Link,
  Grid,
} from "@material-ui/core";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import { submitError } from "src/components/Forms/FormikHelper";
import Page from "src/components/Page";
import TSULogo from "src/components/TSULogo";
import Axios from "src/utils/axios";
import useSettings from "src/hooks/useSettings";

interface TSULoginProps {
  className?: string;
}

const OrangeTextTypography = withStyles({
  root: {
    color: "#FF8500",
  },
})(Typography);

const TSULogin: FC<TSULoginProps> = ({ className, ...rest }) => {
  const { isAuthenticated } = useAuth() as any;
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const { settings } = useSettings();

  useEffect(() => {
    // Already logged in
    if (isAuthenticated) {
      navigate("/dashboard");
    }
    // if already updated password
    if (localStorage.getItem("hasUpdatedPassword") === "true") {
      navigate("/login");
    }
  }, [navigate, isAuthenticated]);

  return (
    <Page title="TSU Login">
      <TSULogo />
      {!completed && (
        <>
          <Box my={2}>
            <Typography variant="h2">Welcome to the new Deep Sky!</Typography>
          </Box>
          <Box mb={2}>
            <Typography>
              We’re excited to announce the launch of the fully redesigned Deep
              Sky. We’ve worked hard to create new technology that helps retail
              traders, no matter their experience level.
            </Typography>
          </Box>
          <Box mb={2}>
            <OrangeTextTypography variant="h4">
              Important: If you have not yet done so, you will need to set a new
              password exclusively for Deep Sky.
            </OrangeTextTypography>
          </Box>
          <Box mb={2}>
            <Typography variant="h4">
              Please begin by entering your TSU login:
            </Typography>
          </Box>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              email: settings.rememberMe,
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required(validationMessage("Email Address")),
            })}
            onSubmit={async (values, actions) => {
              try {
                await Axios.post<any>("password/send-forgot-email", {
                  email: values?.email?.trim()?.toLowerCase(),
                  branding: "tsu",
                });
                actions.setStatus({ success: true });
                actions.setSubmitting(false);
                setCompleted(true);
              } catch {
                const err = {
                  response: {
                    data: {
                      message:
                        "Looks like this email is not registered with Deep Sky yet. Please contact us.",
                    },
                  },
                };
                submitError(err, actions);
                setCompleted(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className={clsx(className)}
                {...rest}
              >
                <Box mt={2} mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ErrorPanel errors={errors} />
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </>
      )}
      {completed && (
        <>
          <Box my={2}>
            <Typography variant="h2">
              We’ve sent you an email with further instructions.
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography>
              Check the email address that you just submitted - you should find
              a message from us with a link to update your password.
            </Typography>
          </Box>
        </>
      )}
      <Box>
        <Typography>
          Did you already set a new password? Then just head to the{" "}
          <Link component={RouterLink} to="/login" underline="always">
            login page.
          </Link>
        </Typography>
      </Box>
    </Page>
  );
};

TSULogin.propTypes = {
  className: PropTypes.string,
};

export default TSULogin;
