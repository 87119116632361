import { TableEditRow } from "@devexpress/dx-react-grid-material-ui";
import { MenuItem, Select } from "@material-ui/core";

const SelectCell = (props) => {
  const { name } = props?.column;
  const { value, onValueChange } = props ?? {};

  if (name === "role") {
    return (
      <TableEditRow.Cell {...props} data-display-name="SelectCell">
        <Select
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          defaultValue="guest-customer"
        >
          <MenuItem value="customer" disabled>
            customer
          </MenuItem>
          <MenuItem value="guest-customer">guest-customer</MenuItem>
          <MenuItem value="guest-forex-customer">guest-forex-customer</MenuItem>
          <MenuItem value="blocked">blocked</MenuItem>
        </Select>
      </TableEditRow.Cell>
    );
  }

  return <TableEditRow.Cell {...props} data-display-name="SelectCell" />;
};

export default SelectCell;
