import ForexPrediction from "src/models/ForexPrediction";
import Prediction from "src/models/Prediction";
import { format } from "src/utils/date";
import getCurrentDate from "src/utils/prediction/getCurrentDate";
import getSentiment from "./getSentiment";
import moment from "moment-timezone";

const start = moment();
const remainder = 15 - (start.minute() % 15);
const dateTime = moment(start)
  .add(remainder, "minutes")
  .seconds(0)
  .milliseconds(0);

const getPeriodText = (index) => {
  if (index === 1) return `${format(getCurrentDate(), "MMM DD")}`;
  return `+${index}`;
};

const getForexPredictionForBlocks = (
  data: (Prediction | ForexPrediction)[] = [],
  isShortForm
) => {
  const currentDay = data?.[0];
  const times = [
    moment(dateTime).toISOString(),
    moment(dateTime).add(30, "minutes").toISOString(),
    moment(dateTime).add(1, "hours").toISOString(),
    moment(dateTime).add(4, "hours").toISOString(),
    moment(dateTime).add(8, "hours").toISOString(),
  ];

  const showPeriods = [1, 2, 5, 10, 15];

  if (data.length > 0) {
    data.push(data[data.length - 1]);
  }

  return data
    ?.map((item, index) => ({
      predictedCloseValue: item?.predictedCloseValue,
      mape: item?.mape,
      id: item.id,
      period: index,
      predictedFor: item.predictedFor,
      sentiment: getSentiment(
        currentDay.previousClose,
        item.predictedCloseValue
      ),
    }))
    .filter((item) =>
      isShortForm
        ? times.includes(item.predictedFor)
        : showPeriods.includes(item.period)
    )
    .map((item) => ({
      ...item,
      label: isShortForm
        ? moment(item.predictedFor).tz("America/New_York").format("h:mm a")
        : getPeriodText(item.period),
    }));
};

export default getForexPredictionForBlocks;
