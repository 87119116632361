import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

export interface DownIconProps {
  [key: string]: any;
}

const DownIcon: FC<DownIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <path d="M13.6064 3L8.44326 10.1244L3.28015 3H0.5L8.5 14L16.5 3H13.6064Z" />
    </SvgIcon>
  );
};

export default DownIcon;
