import React from "react";

function BullIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        fill="#707680"
        d="M18.537 5.89c-.549.845-1.703 1.505-2.541 1.771 0 0-.044.71.727 1.338.771.629 1.557.49 1.557.49.466-.352 1.537-1.388 2.085-2.713.549-1.326-.057-3.981-.428-5.143-.238 1.066-.539 2.929-1.4 4.257zm-13.073 0c.549.845 1.703 1.505 2.541 1.771 0 0 .044.71-.727 1.338-.771.629-1.557.49-1.557.49-.466-.352-1.537-1.388-2.085-2.713-.549-1.326.057-3.981.428-5.143.238 1.066.539 2.929 1.4 4.257z"
      />
      <path
        fill="#707680"
        d="M15.15 7.326c-.288-.52-.652-.898-1.37-.898h-3.558c-.72 0-1.083.377-1.37.898-.36.652-.36 1.708-1.37 2.36-1.012.65-2.135 0-3.797 1.437-.875.756-.824 1.977-.63 2.538.225-.284.97-.965 1.865-1.482.809-.468 1.513-.27 1.752-.225v2.291c0 .45.876 1.528 1.415 1.91.54.382.967 1.145 1.012 1.707.045.562-.248 2.067-.248 2.83 0 .922.405 2.629 1.977 2.674h2.345c1.572-.045 1.977-1.752 1.977-2.673 0-.764-.292-2.27-.247-2.831.044-.562.472-1.325 1.01-1.707.54-.382 1.416-1.46 1.416-1.91v-2.291c.24-.045.944-.243 1.752.225.895.517 1.64 1.198 1.865 1.482.194-.561.245-1.782-.63-2.538-1.662-1.438-2.785-.786-3.796-1.438s-1.01-1.707-1.37-2.359z"
      />
    </svg>
  );
}

export default BullIcon;
