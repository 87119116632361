import { Box } from "@material-ui/core";
import { useState, useEffect } from "react";
import Lottie from "src/components/Lottie";
import refreshIconAnimation from "src/assets/lotties/forex-refresh-icon.json";
import refreshTextAnimation from "src/assets/lotties/forex-refresh-text.json";
import completeIconAnimation from "src/assets/lotties/forex-complete-icon.json";
import completeTextAnimation from "src/assets/lotties/forex-complete-text.json";
import { fetchPredictions } from "src/slices/predictions";
import { config } from "src/config";
import { useDispatch } from "react-redux";
import useTimer from "src/hooks/useTimer";
import TimerText from "./TimerText";

const RefreshTimer = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);
  const dispatchRedux = useDispatch();
  const { timer } = useTimer();

  useEffect(() => {
    const baseUrl = config.APP_BASE_API_URL;
    const eventSource = new EventSource(`${baseUrl}/forex-sync/ping`);
    //@ts-ignore
    eventSource.onmessage = (event, data) => {
      if (isRefreshing) {
        setIsRefreshing(false);
        setIsCompleting(true);
        setTimeout(() => {
          setIsCompleting(false);
          dispatchRedux(fetchPredictions());
        }, 3000);
      } else {
        dispatchRedux(fetchPredictions());
      }
      return null;
    };
    eventSource.onerror = function () {
      eventSource.close();
    };
  }, [dispatchRedux, isRefreshing]);

  useEffect(() => {
    if (timer.minutes() === 0 && timer.seconds() === 0) {
      setIsRefreshing(true);
    }
  }, [timer]);

  const loaderStatus = (isRefreshing, isCompleting) => {
    if (isRefreshing) {
      return (
        <Box display="flex" justifyContent="center" id="joy-ride-refresh-timer">
          <Lottie
            data={refreshIconAnimation}
            style={{ height: "32px", paddingRight: "8px" }}
          />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Lottie
              data={refreshTextAnimation}
              style={{ height: "32px", paddingRight: "8px" }}
            />
          </Box>
        </Box>
      );
    }
    if (isCompleting) {
      return (
        <Box display="flex" justifyContent="center" id="joy-ride-refresh-timer">
          <Lottie
            data={completeIconAnimation}
            style={{ height: "32px", paddingRight: "8px" }}
          />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Lottie
              data={completeTextAnimation}
              style={{ height: "32px", paddingRight: "8px" }}
            />
          </Box>
        </Box>
      );
    } else return <TimerText />;
  };

  return loaderStatus(isRefreshing, isCompleting);
};

export default RefreshTimer;
