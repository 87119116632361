import React from 'react';
import type { FC } from 'react';
import { Link, Typography } from '@material-ui/core';

interface Props {
  email: string;
}

const CommonEmail: FC<Props> = ({ email = '' }) => {
  return (
    <Typography>
      <Link href={`mailto:${email}`} color="primary" target="_top">
        {email}
      </Link>
    </Typography>
  );
};

export default CommonEmail;
