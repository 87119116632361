import { FC } from "react";
import { Grid, makeStyles, Typography, Box, Divider } from "@material-ui/core";
import { Sentiments } from "src/types/Sentiments";
// import SentimentText from "src/components/SentimentText";
import PriceBlock from "src/components/PriceBlock";
import getFormattedScore from "src/utils/prediction/getFormattedScore";
import Prediction from "src/models/Prediction";
import getPredictionForBlocks from "src/utils/prediction/getPredictionForBlocks";
import getRangePrediction from "src/utils/prediction/getRangePrediction";

export interface DeepSkyAnalysisProps {
  className?: string;
  score?: number | null;
  scoreText?: string;
  sentiment?: Sentiments;
  periodForecast?: number[];
  predictions?: Prediction[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    color: "#40434A",
  },
  container: {
    padding: theme.spacing(3, 0),
  },

  deepSkyScoreContainer: {
    display: "flex",
    alignItems: "center",
  },
  // stockOutlookContainer: {
  //   display: "flex",
  //   flexDirection: "row",
  //   alignItems: "center",
  //   marginTop: theme.spacing(0.5),
  //   [theme.breakpoints.up("md")]: {
  //     maxWidth: "30%",
  //     flexBasis: "30%",
  //     justifyContent: "space-between",
  //     marginTop: theme.spacing(0.5),
  //   },
  // },
  periodForecastContainer: {
    display: "flex",

    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up(700)]: { flexDirection: "row", flex: 1 },
  },
  periodContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(700)]: {
      minWidth: "135px",
      marginBottom: theme.spacing(0),
    },
  },
  priceBlockContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  scoreBox: {
    border: "1px solid #40434A",
    borderRadius: "4px",
    padding: "5px",
    marginRight: "8px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  confidence: {
    color: "#707680",
  },
  divider: {
    visibility: "hidden",
    margin: theme.spacing(0, 1.5),
    maxHeight: "73px",
    [theme.breakpoints.up(700)]: { visibility: "visible" },
  },
}));

const DeepSkyAnalysis: FC<DeepSkyAnalysisProps> = ({
  score = 0,
  scoreText = "",
  sentiment,
  periodForecast,
  predictions,
}) => {
  const classes = useStyles({ sentiment });
  const modelPredictions = getPredictionForBlocks(predictions);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2">Deep Sky Analysis</Typography>
        </Grid>
        <Grid
          item
          className={classes.deepSkyScoreContainer}
          id="joy-ride-deepsky-analysis"
        >
          {score && (
            <Box display="flex" alignItems="center">
              <Box className={classes.scoreBox}>
                <Typography variant="h1">
                  {getFormattedScore(score ?? 0)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h4">Sky Score</Typography>
                {scoreText && (
                  <Typography variant="h4" className={classes.confidence}>
                    {scoreText}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Grid>

        {score && <Divider orientation="vertical" className={classes.divider} />}

        <Grid
          id="joy-ride-deepsky-forecast"
          item
          className={classes.periodForecastContainer}
        >
          <Grid item className={classes.periodContainer}>
            <Box>
              <Typography variant="h4">Period Forecast</Typography>
              <Typography variant="body1">
                {periodForecast?.join(", ")}
              </Typography>
            </Box>
          </Grid>
          <Grid item className={classes.priceBlockContainer}>
            {modelPredictions.map((item, index) => (
              <PriceBlock
                key={item?.label}
                label={item?.label}
                minPrice={
                  index < 2
                    ? getRangePrediction.getMinimumValue(
                        item?.predictedCloseValue,
                        item?.mape
                      )
                    : undefined
                }
                maxPrice={
                  index < 2
                    ? getRangePrediction.getMaximumValue(
                        item?.predictedCloseValue,
                        item?.mape
                      )
                    : undefined
                }
                sentiment={item?.sentiment}
              />
            ))}
          </Grid>
        </Grid>

        {/* <Box>
          <SentimentText sentiment={sentiment} />
        </Box> */}
      </Grid>
    </div>
  );
};

export default DeepSkyAnalysis;
