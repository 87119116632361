import { Step, StepLabel, Stepper, makeStyles, Box } from "@material-ui/core";
import {
  CustomStepConnector,
  CustomStepIcon,
  IStep,
} from "src/components/Common/CustomStepper";
import type { Theme } from "src/theme";
import {
  InfoOutlined as InfoOutlinedIcon,
  CreditCard as CreditCardIcon,
  ViewCarousel as ViewCarouselIcon,
  ListAlt as ListAltIcon,
} from "@material-ui/icons";

const steps: IStep[] = [
  {
    label: "Enter your info",
    icon: InfoOutlinedIcon,
  },
  {
    label: "Select your plan",
    icon: ViewCarouselIcon,
  },
  {
    label: "Payment",
    icon: CreditCardIcon,
  },
  {
    label: "Summary",
    icon: ListAltIcon,
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  stepper: {
    backgroundColor: "transparent",
    paddingRight: 0
  },
}));

interface IRegisterStepper {
  activeStep?: number;
  onChange: (number) => void;
}

const RegisterStepper = ({ activeStep, onChange }: IRegisterStepper) => {
  const classes = useStyles();

  return (
    <Box>
      <Stepper
        color="primary"
        activeStep={activeStep}
        className={classes.stepper}
        connector={<CustomStepConnector />}
        orientation="vertical"
      >
        {steps.map((step, index) => (
          <Step key={step.label} onClick={() => onChange(index)}>
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon steps={steps} {...props} />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

RegisterStepper.defaultProps = {
  onChange: (number) => {},
};

export default RegisterStepper;
