import moment from "moment";
import { useState, useEffect } from "react";

const useTimer = () => {
  const currentTime = moment();
  const remainder = 15 - (currentTime.minute() % 15);
  const fifteenMinuteInterval = moment(currentTime)
    .add(remainder, "minutes")
    .seconds(0)
    .milliseconds(0);

  const [timer, setTimer] = useState(
    moment(fifteenMinuteInterval.diff(currentTime))
  );
  useEffect(() => {
    const intervalID = setInterval(function () {
      setTimer(moment(fifteenMinuteInterval.diff(currentTime)));
    }, 1000);
    return () => clearInterval(intervalID);
  }, [fifteenMinuteInterval, timer, currentTime]);
  return { timer };
};

export default useTimer;
