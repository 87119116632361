import PropTypes from "prop-types";
import PriceBlock from "src/components/PriceBlock"

const DirectionalCell = ({ value }) => {
  return (
    <PriceBlock
      sentiment={value}
      style={{ height: '24px', width: '36px', borderRadius: '4px' }}
    />
  );
};

DirectionalCell.propTypes = {
  value: PropTypes.shape({})
}

export default DirectionalCell
