import type { FC } from "react";
import { Container, makeStyles } from "@material-ui/core";
import Page from "src/components/Page";
import { Theme } from "src/theme";
import { RootState } from "src/store";
import Header from "src/components/Header";
import List from "./List";
import { useSelector } from "src/store";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  buttons: {
    marginRight: 16,
    "& > * + *": {
      marginLeft: 10,
    },
  },
}));

const MyWatchList: FC = () => {
  const classes = useStyles();
  const predictions = useSelector(
    (reduxState: RootState) => reduxState.predictions
  );

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header
          title="My Watchlist"
          crumbs={[
            { crumb: "Deep Sky", link: "/dashboard" },
            { crumb: "My Watchlist" },
          ]}
        />
        <List
          title="My Watchlist"
          isLoading={predictions.watchList.isLoading}
          data={predictions.watchList.data}
        />
      </Container>
    </Page>
  );
};

export default MyWatchList;
