import React from "react";

function VerticalLegendIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 83"
      {...props}
    >
      <path fill="url(#prefix__paint0_linear)" d="M0 .5h10v82H0z" />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={5}
          x2={5}
          y1={0.5}
          y2={82.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.042} stopColor="#008ECC" />
          <stop offset={0.125} stopColor="#01B3F1" />
          <stop offset={0.219} stopColor="#43BEE7" />
          <stop offset={0.307} stopColor="#71DFFF" />
          <stop offset={0.406} stopColor="#BCE9F2" />
          <stop offset={0.49} stopColor="#E6E6E6" />
          <stop offset={0.589} stopColor="#FFDAB6" />
          <stop offset={0.677} stopColor="#FFBE82" />
          <stop offset={0.766} stopColor="#FFA753" />
          <stop offset={0.854} stopColor="#FF8500" />
          <stop offset={0.953} stopColor="#FF3801" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default VerticalLegendIcon;
