import type { Item } from "./item";
import StarIcon from "@material-ui/icons/Star";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import ForexIcon from "src/assets/icons/Forex";
import useAuth from "src/hooks/useAuth";

export interface Section {
  items: Item[];
  subheader: string;
}

export const useSections = (): Section[] => {
  const { user } = useAuth();

  return [
    {
      subheader: "",
      items: [
        {
          title: "Stocks",
          icon: ShowChartIcon,
          href: "/dashboard",
        },
        {
          title: "Forex",
          icon: ForexIcon,
          href: "/dashboard/forex",
          newIcon: true,
        },
        {
          title: "My Watchlist",
          icon: StarIcon,
          href: "/dashboard/my-watchlist",
        },
        {
          title: "Profile",
          icon: PersonIcon,
          href: "/dashboard/users/edit",
        },
        {
          title: "Users",
          icon: PeopleIcon,
          href: "/dashboard/admin/users",
          access: "admin",
        },
        {
          title: "Accuracy",
          icon: TrackChangesIcon,
          href: "/dashboard/admin/accuracy",
          access: "admin",
        },
      ].filter((item) => {
        if (user?.role === "admin") {
          return true;
        } else {
          return item.access !== "admin";
        }
      }),
    },
  ];
};
