import { removeEmpty, serializeObj } from "src/utils/object";
import { getCombineState } from "src/utils/prediction/getCombinePredictionState";
import { getFormattedScoreText } from "src/utils/prediction/getFormattedScore";
import getRangePrediction from "src/utils/prediction/getRangePrediction";
import { parsePrediction } from "src/utils/prediction/predictionParser";

interface ICombineDirection {
  supporting: number;
  conflicting: number;
  neutral: number;
}

export default class Prediction {
  readonly id?: string;
  adjustedClose: number | null = null;
  averageScore: number | null = null;
  averageScoreText: string = "";
  bbDirection: string = "";
  bbDirectionIndicator: string = "";
  close: number | null = null;
  createdAt: string = "";
  date: string = "";
  deletedAt: string = "";
  directionScore: number | null = null;
  emaDirection: string = "";
  emaDirectionIndicator: string = "";
  high: number | null = null;
  low: number | null = null;
  macdDirection: string = "";
  macdDirectionIndicator: string = "";
  mape: number = 0;
  marketDirection: string = "";
  marketOpposeScore: number | null = null;
  marketScore: number | null = null;
  modelDirectionScore: number | null = null;
  modelPricePredictionScore: number | null = null;
  open: number | null = null;
  predictedCloseValue: number = 0;
  previousClose: number | null = null;
  pricePredictionScore: number | null = null;
  rsiDirection: string = "";
  rsiDirectionIndicator: string = "";
  sectorDirection: string = "";
  sectorScore: number | null = null;
  smaDirection: string = "";
  smaDirectionIndicator: string = "";
  stochasticDirection: string = "";
  stochasticDirectionIndicator: string = "";
  symbol: string = "";
  updatedAt: string = "";
  volume: number | null = null;
  originalDirection: string = "";
  predictedDirection: string = "";
  rangePrediction?: string | null = null;
  combinedDirection: ICombineDirection = {
    supporting: 0,
    conflicting: 0,
    neutral: 0,
  };
  predictedFor: string = "";

  constructor(init?: Partial<Prediction>) {
    const parsedPrediction = parsePrediction(init);
    const predictedDirection = parsedPrediction?.predictedDirection;

    this.originalDirection = parsedPrediction?.originalDirection;
    this.predictedDirection = predictedDirection;
    this.combinedDirection = getCombineState(init, predictedDirection);
    this.rangePrediction = getRangePrediction({
      predictedCloseValue: init?.predictedCloseValue,
      mape: init?.mape,
      isForex: false,
    });
    this.averageScoreText = getFormattedScoreText(init?.averageScore ?? 0);
    Object.assign(this, init);
  }

  serialize() {
    return removeEmpty(serializeObj(this));
  }

  clone(): Prediction {
    return new Prediction(serializeObj(this));
  }
}
