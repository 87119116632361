import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";

interface IPaymentSummary {
  total?: string;
  subscriptionInfo?: string;
}

const PaymentSummary = ({ total, subscriptionInfo }: IPaymentSummary) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography variant="h3">Your subscription info</Typography>
        <Typography variant="subtitle1">Deep Sky Trading Assistant</Typography>
        <Typography variant="body1">{subscriptionInfo}</Typography>
      </Grid>
      <Grid item md={6} xs={12} container justify="flex-end">
        <Typography variant="h4" align="right">
          Total
        </Typography>
        <Box pl={1}>
          <Typography variant="body1" align="right">
            {total}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;
