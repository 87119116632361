import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography, Theme } from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";

const useStyles = makeStyles((theme: Theme) => ({
  subscribeContainer: {
    border: "1px solid #DBD7D2",
    borderRadius: 4,
    padding: "10px",
    display: "flex",
    maxWidth: "224px",
    marginTop: 15,
    marginLeft: 15,
    marginBottom: 15,
    zIndex: 10,
  },
  subscribeGrid: {
    zIndex: 10,
  },
  externalLink: {
    color: "#FF8A00",
  },
  subscribeMobileContainer: {
    border: "1px solid #DBD7D2",
    borderRadius: 4,
    padding: "5px",
    position: "absolute",
    cursor: "pointer",
    bottom: 20,
    marginLeft: 7,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 10,
  },
}));
const SubscribeBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.subscribeContainer}>
      <LockOpenIcon color="primary" />
      <Box pl={1}>
        <Typography color="textPrimary" variant="h6">
          Unlock full features now
        </Typography>
        <Typography variant="caption">
          <Typography
            href="https://www.joindeepsky.com/sign-up"
            className={classes.externalLink}
            component="a"
            variant="caption"
          >
            Subscribe to Deep Sky
          </Typography>{" "}
          to get access to over XXX stocks, Forex analysis, and more!
        </Typography>
      </Box>
    </Box>
  );
};

const Mobile = () => {
  const classes = useStyles();

  return (
    <Box className={classes.subscribeMobileContainer} component="a">
      <LockOpenIcon color="primary" />
      <Typography variant="caption">Subscribe</Typography>
    </Box>
  );
};

SubscribeBox.Mobile = Mobile;
export default SubscribeBox;
