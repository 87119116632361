import { useCallback, useState } from "react";
import { Hidden } from "@material-ui/core";
import type { FC } from "react";
import UserForm from "./UserForm/UserForm";
import SelectPlan from "./SelectPlan/SelectPlan";
import Payment from "./Payment/Payment";
import Summary from "./Summary/Summary";
import RegisterCrest from "./helpers/RegisterCrest";
import RegisterStepper from "./helpers/RegisterStepper";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import { config } from "src/config";

// const stripePromise = loadStripe(config.APP_STRIPE_KEY);
const RegisterView: FC = () => {
  const [registerStepperIndex, setRegisterStepperIndex] = useState(0);
  const [state, setState] = useState({ selectedPlan: 0 });
  const handleStepChange = (newStep) => {
    setRegisterStepperIndex(newStep);
  };
  const updateState = useCallback(
    (newUpdate) => setState((prev) => ({ ...prev, ...newUpdate })),
    []
  );

  return (
    // <Elements stripe={stripePromise}>
      <RegisterCrest
        leftColumnComponent={() => (
          <Hidden smDown>
            <RegisterStepper
              activeStep={registerStepperIndex}
              onChange={handleStepChange}
            />
          </Hidden>
        )}
      >
        {registerStepperIndex === 0 && <UserForm />}
        {registerStepperIndex === 1 && (
          <SelectPlan state={state} updateState={updateState} />
        )}
        {registerStepperIndex === 2 && <Payment />}
        {registerStepperIndex === 3 && <Summary />}
      </RegisterCrest>
    // </Elements>
  );
};

export default RegisterView;
