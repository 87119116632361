import { FC } from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Box,
  Button,
  Divider,
  Hidden,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Sentiments } from "src/types/Sentiments";
import PriceBlock from "./components/PriceBlock";
import Prediction from "src/models/Prediction";
import getPredictionForBlocks from "src/utils/prediction/getPredictionForBlocks";
import getMarketsData from "src/utils/prediction/getMarketsData";
import ModelPredictionsForMarketWidget from "./components/ModelPredictions";

export interface MarketWidgetProps {
  className?: string;
  sentiment?: Sentiments;
  sectorText?: string;
  date?: Date;
  toggleWidget?: () => void;
  marketsData?: Prediction[];
  sectorPredictions?: Prediction[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    zIndex: 100000000,
  },
  paper: {
    width: "100%",
    justifyContent: "center",
    display: "flex",
    background: "#fff",
    color: "#40434A",
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 0),
    },
  },
  container: {
    [theme.breakpoints.up("xs")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: { alignItems: "flex-start" },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(-1),
      marginRight: theme.spacing(1),
      paddingBottom: 0,
    },
  },
  box: {
    [theme.breakpoints.up("md")]: {
      border: "1px solid #DBD7D2",
      borderRadius: "2px",
      padding: theme.spacing(0, 1),
      paddingBottom: theme.spacing(0.5),
      minHeight: "99px",
    },
    [theme.breakpoints.up(1106)]: { minHeight: "0" },
  },
  subheadingContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      height: "50px",
    },
    [theme.breakpoints.up(1106)]: {
      height: "auto",
    },
  },
  firstPriceBlock: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxWidth: "76px",
      minWidth: "38px",
    },
  },
  itemContainerLeft: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(-1),
      marginTop: theme.spacing(-1),
    },
  },
  itemContainerRight: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(-1),
    },
  },
  heading: {
    fontSize: "24px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: "normal",
    lineHeight: 1.2,
    letterSpacing: "-0.24px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "500",
      lineHeight: 1.235,
      letterSpacing: "-0.06px",
      marginLeft: theme.spacing(1.3),
    },
  },
  subHeading: {
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "16px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "500",
      lineHeight: 1.235,
      letterSpacing: "-0.06px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      marginLeft: theme.spacing(0),
      fontSize: "14px",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: "normal",
      lineHeight: 1.5,
      letterSpacing: "-0.05px",
    },
  },
  divider: {
    color: "#DBD7D2",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: { display: "none" },
  },
}));

const MarketWidget: FC<MarketWidgetProps> = ({
  className,
  toggleWidget,
  sectorText,
  marketsData,
  sectorPredictions,
}) => {
  const classes = useStyles();
  const {
    SPY: spyData,
    DIA: diaData,
    QQQ: qqqData,
    IJH: ijhData,
    IWM: iwmData,
    SOXX: soxxData,
  } = getMarketsData(marketsData ?? []);

  const formattedSectorPredictions = getPredictionForBlocks(sectorPredictions);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} className={classes.container}>
          <Grid
            item
            container
            spacing={1}
            xs={8}
            md={6}
            style={{ paddingBottom: 0 }}
          >
            <Grid item xs={12} md={6}>
              <Typography className={classes.heading}>
                Sector Forecast
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item md={6}>
                <Typography className={classes.heading}>
                  Market Forecast
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs={4}
            md={6}
            className={classes.buttonContainer}
            style={{ paddingBottom: 0 }}
          >
            <Button
              endIcon={<CloseIcon />}
              onClick={toggleWidget}
              color="primary"
            >
              Close
            </Button>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            md={6}
            className={classes.itemContainerLeft}
          >
            <Grid item xs={12} md={6}>
              <Box className={classes.box}>
                <Box className={classes.subheadingContainer}>
                  <Typography className={classes.subHeading}>
                    {sectorText}
                  </Typography>
                </Box>

                <Box className={classes.alignCenter}>
                  {formattedSectorPredictions.map((item, index) => (
                    <PriceBlock
                      sentiment={item?.sentiment}
                      date={item?.label}
                      key={item.id}
                      className={index === 0 ? classes.firstPriceBlock : ""}
                      empty={index !== 0}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>

            <Hidden mdUp>
              <Grid item xs={12} md={6}>
                <Divider className={classes.divider} />
                <Box className={classes.box}>
                  <Typography className={classes.heading}>
                    Market Forecast
                  </Typography>
                </Box>
              </Grid>
            </Hidden>

            {spyData && (
              <Grid item xs={12} md={3}>
                <Box className={classes.box}>
                  <Box className={classes.subheadingContainer}>
                    <Typography className={classes.subHeading}>
                      S&P 500 (SPY)
                    </Typography>
                  </Box>
                  <Box className={classes.alignCenter}>
                    <ModelPredictionsForMarketWidget
                      predictions={spyData}
                      firstPriceBlock={classes.firstPriceBlock}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {diaData && (
              <Grid item xs={12} md={3}>
                <Divider className={classes.divider} />
                <Box className={classes.box}>
                  <Box className={classes.subheadingContainer}>
                    <Typography className={classes.subHeading}>
                      DOW 30 (DIA)
                    </Typography>
                  </Box>

                  <Box className={classes.alignCenter}>
                    <ModelPredictionsForMarketWidget
                      predictions={diaData}
                      firstPriceBlock={classes.firstPriceBlock}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            spacing={1}
            md={6}
            className={classes.itemContainerRight}
          >
            {qqqData && (
              <Grid item xs={12} md={3}>
                <Divider className={classes.divider} />
                <Box className={classes.box}>
                  <Box className={classes.subheadingContainer}>
                    <Typography className={classes.subHeading}>
                      NASDAQ (QQQ)
                    </Typography>
                  </Box>

                  <Box className={classes.alignCenter}>
                    <ModelPredictionsForMarketWidget
                      predictions={qqqData}
                      firstPriceBlock={classes.firstPriceBlock}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {ijhData && (
              <Grid item xs={12} md={3}>
                <Divider className={classes.divider} />
                <Box className={classes.box}>
                  <Box className={classes.subheadingContainer}>
                    <Typography className={classes.subHeading}>
                      Mid Cap (IJH)
                    </Typography>
                  </Box>

                  <Box className={classes.alignCenter}>
                    <ModelPredictionsForMarketWidget
                      predictions={ijhData}
                      firstPriceBlock={classes.firstPriceBlock}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {iwmData && (
              <Grid item xs={12} md={3}>
                <Divider className={classes.divider} />
                <Box className={classes.box}>
                  <Box className={classes.subheadingContainer}>
                    <Typography className={classes.subHeading}>
                      Small Cap (IWM)
                    </Typography>
                  </Box>

                  <Box className={classes.alignCenter}>
                    <ModelPredictionsForMarketWidget
                      predictions={iwmData}
                      firstPriceBlock={classes.firstPriceBlock}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
            {soxxData && (
              <Grid item xs={12} md={3}>
                <Divider className={classes.divider} />
                <Box className={classes.box}>
                  <Box className={classes.subheadingContainer}>
                    <Typography className={classes.subHeading}>
                      Semi. Index (SOX)
                    </Typography>
                  </Box>

                  <Box className={classes.alignCenter}>
                    <ModelPredictionsForMarketWidget
                      predictions={soxxData}
                      firstPriceBlock={classes.firstPriceBlock}
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MarketWidget;
