import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import getSentiment from "src/utils/prediction/getSentiment";

const usePredictions = (inputData: any = []) => {
  const stocksData = useSelector(
    (reduxState: RootState) => reduxState.stocks.data
  );
  const sectorsData = useSelector(
    (reduxState: RootState) => reduxState.stocks.sectorsData
  );
  const marketsData = useSelector(
    (reduxState: RootState) => reduxState.stocks.marketsData
  );

  const updateWithModelData = useCallback(
    (item) => {
      const stock = stocksData.find(
        (stockItem) => stockItem.symbol === item.symbol
      );
      const sectorBriefing = sectorsData.find(
        (stockItem) => stockItem.symbol === stock?.sectorSymbol
      );
      const marketBriefing = marketsData.find(
        (marketItem) => marketItem.symbol === "SPY"
      );

      const sectorData = stocksData.find(
        (sectorItem) => item.symbol === sectorItem.symbol
      );

      return {
        ...item,
        ...sectorData,
        sentiment: getSentiment(item?.previousClose, item?.predictedCloseValue),
        shortSentiment: getSentiment(
          item?.previousShortClose,
          item?.predictedShortCloseValue
        ),
        sectorSentiment: getSentiment(
          sectorBriefing?.previousClose,
          sectorBriefing?.predictedCloseValue
        ),
        modelPredictedSectorDirection: sectorBriefing?.predictedDirection,
        modelPredictedMarketDirection: marketBriefing?.predictedDirection,
      };
    },
    [marketsData, sectorsData, stocksData]
  );

  return {
    predictions: inputData?.map(updateWithModelData),
  };
};

export default usePredictions;
