import React, { FC } from "react";
import {
  Container,
  Divider,
  Grid,
  Card,
  makeStyles,
  CardContent,
} from "@material-ui/core";
import Page from "src/components/Page";
import type { Theme } from "src/theme";
import { snackActions } from "src/utils/SnackbarUtils";
import Header from "./Header";
import UserForm from "../UserForm";
import UserDetailCard from "src/components/DetailCards/Sections/UserDetailCard";
import useAuth from "src/hooks/useAuth";
import Axios from "src/utils/axios";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

const DetailsView: FC = () => {
  const classes = useStyles();
  const { user } = useAuth();

  const handleUserUpdate = (values, actions) => {
    Axios.put("/users/update", {
      firstName: values.firstName,
      lastName: values.lastName,
    })
      .then(() => {
        actions.setStatus({ success: true });
        snackActions.success("Successfully updated details");
      })
      .catch(() =>
        snackActions.error("Error while updating user. Please try again!")
      )
      .finally(() => actions.setSubmitting(false));
  };

  const onChangePasswordSubmit = (values, actions) => {
    Axios.post("/password/change", {
      currentPassword: values.currentPassword,
      newPassword: values.password,
    })
      .then(() => {
        actions.setStatus({ success: true });
        snackActions.success("Successfully updated password");
      })
      .catch(() =>
        snackActions.error("Error while updating password. Please try again!")
      )
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <Page className={classes.root} title="User Details">
      <Container maxWidth={false}>
        <Header />
        <Grid className={classes.root} container spacing={3}>
          <Grid item lg={3} md={6} xl={3} xs={12}>
            <UserDetailCard user={user ?? undefined} />
          </Grid>
          <Grid item lg={9} md={6} xl={9} xs={12}>
            <Card>
              <Divider />
              <CardContent>
                <UserForm
                  initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    email: user?.email,
                  }}
                  onSubmit={handleUserUpdate}
                  onChangePasswordSubmit={onChangePasswordSubmit}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default DetailsView;
