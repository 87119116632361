const isNumber = (value) => !isNaN(parseFloat(value)); //eslint-disable-line

const moneyFormatter = (input = "", currency = "USD") => {
  let formattedValue = input;
  if (input === null) {
    formattedValue = input;
  }

  if (isNumber(input)) {
    const checkForNaN = Number(input).toLocaleString("en-US", {
      style: "currency",
      currency,
    });
    formattedValue = checkForNaN === "$NaN" ? input : checkForNaN;
  }

  return formattedValue;
};

export default moneyFormatter;
