import React, { useEffect, useState } from "react";
import type { FC } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Formik } from "formik";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { validationMessage } from "src/utils/validations";
import { Link as RouterLink } from "react-router-dom";
import { useQuery } from "src/hooks/useQuery";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import {
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import Axios from "src/utils/axios";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import { submitError } from "src/components/Forms/FormikHelper";
import Page from "src/components/Page";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import TSULogo from "src/components/TSULogo";

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  links: {
    marginTop: theme.spacing(2),
    "& > * + * ": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const OrangeTextTypography = withStyles({
  root: {
    color: "#FF8500",
  },
})(Typography);

const ResetPassword: FC<Props> = ({ className, ...rest }) => {
  const classes = useStyles();
  const [completed, setCompleted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const query = useQuery();
  const isTSUBranding = query.get("branding") === "tsu";
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessToken");
  }, []);

  return (
    <Page className={classes.root} title="Reset Password">
      {isTSUBranding && <TSULogo />}

      {!completed ? (
        <>
          <Box my={2}>
            <Typography variant="h2">
              Please create a new password for Deep Sky.
            </Typography>
          </Box>
          {isTSUBranding && (
            <>
              <Box mb={2}>
                <Typography>
                  Your Deep Sky account is now independent of your TSU account.
                  Please create a unique password for Deep Sky.
                </Typography>
              </Box>

              <Box mb={2}>
                <OrangeTextTypography variant="h4">
                  This will not affect your TSU account. This password is just
                  for logging into Deep Sky.
                </OrangeTextTypography>
              </Box>
            </>
          )}

          <Box mb={3}>
            <Typography>
              Your password must be at least 8 characters, and contain one
              capital letter, one number or special character.
            </Typography>
          </Box>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              password: "",
              newPassword: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string()
                .min(8)
                .max(200)
                .required(validationMessage("Password")),
              newPassword: Yup.string().oneOf(
                [Yup.ref("password"), null],
                "Passwords must match"
              ),
            })}
            onSubmit={async (values, actions) => {
              try {
                await Axios.post<any>(
                  `password/reset?token=${query.get("token")}`,
                  {
                    email: query.get("email"),
                    password: values.password,
                  },
                  { skipAuthRefresh: true } as any
                );

                setSuccessMessage(
                  "We successfully updated your password! You may login now."
                );
                if (isTSUBranding) {
                  localStorage.setItem("hasUpdatedPassword", "true");
                }
                actions.setStatus({ success: true });
                actions.setSubmitting(false);
                setCompleted(true);
              } catch (err) {
                submitError(err, actions);
                setCompleted(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className={clsx(classes.root, className)}
                {...rest}
              >
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        autoFocus
                        helperText={touched.password && errors.password}
                        label="Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        // type="password"
                        value={values.password}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.newPassword && errors.newPassword
                        )}
                        fullWidth
                        helperText={touched.newPassword && errors.newPassword}
                        label="Confirm Password"
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.newPassword}
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ErrorPanel errors={errors} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Button
                        fullWidth
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Submit
                      </Button>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Button
                        fullWidth
                        color="primary"
                        to="/login"
                        component={RouterLink}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <Box mt={3}>
          <Alert severity="info">{successMessage}</Alert>
          <Box my={2}>
            <Typography variant="h2">
              Your Deep Sky password has been created.
            </Typography>
          </Box>
          <Box mb={3}>
            <Typography>Please log in with your new password.</Typography>
          </Box>
          <Grid item xs={12}>
            <Button
              href="/login"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Go To Login
            </Button>
          </Grid>
        </Box>
      )}
    </Page>
  );
};

ResetPassword.propTypes = {
  className: PropTypes.string,
};

export default ResetPassword;
