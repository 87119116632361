import { FC } from "react";
import { Grid, makeStyles, Typography, Box, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export interface TechnicalAnalysisProps {
  supportingString?: string;
  conflictingString?: string;
  description?: string;
  onJumpToFullAnalysis?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    color: "#40434A",
  },
  container: {
    padding: theme.spacing(3, 0),
  },
  badge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 12px 2px 8px",
    marginBottom: theme.spacing(1),
    maxWidth: 133,
    border: "1px solid #40434A",
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    marginRight: "5px",
  },
  supporting: {
    color: "#40434A",
    backgroundColor: "#fff",
  },
  conflicting: {
    color: "#fff",
    backgroundColor: "#40434A",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const TechnicalAnalysis: FC<TechnicalAnalysisProps> = ({
  supportingString,
  conflictingString,
  description,
  onJumpToFullAnalysis,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        className={classes.container}
        id="joy-ride-technical-analysis"
      >
        <Grid item container xs={12} lg={6}>
          <Typography variant="h2" className={classes.header}>
            Technical Analysis
          </Typography>
          <Typography variant="body1">{description}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box className={`${classes.badge} ${classes.supporting}`}>
            <CheckIcon fontSize="small" className={classes.icon} />
            <Typography variant="body2">SUPPORTING</Typography>
          </Box>
          <Typography variant="body1">{supportingString}</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box className={`${classes.badge} ${classes.conflicting}`}>
            <ClearIcon fontSize="small" className={classes.icon} />
            <Typography variant="body2">CONFLICTING</Typography>
          </Box>
          <Typography variant="body1">{conflictingString}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            startIcon={<ArrowDownwardIcon />}
            onClick={onJumpToFullAnalysis}
          >
            JUMP TO FULL ANALYSIS
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default TechnicalAnalysis;
