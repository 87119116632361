import React from "react";
import type { FC } from 'react';
import ResetPassword from './ResetPassword';
import Crest from '../Crest';

const ResetPasswordView: FC = () => {
  return (
    <Crest>
      <ResetPassword />
    </Crest>
  );
};

export default ResetPasswordView;
