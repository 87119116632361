import React from "react";
import Header from "../helpers/Header";
import { Typography, Box, Button } from "@material-ui/core";
import PaymentSummary from "../helpers/PaymentSummary";

const Summary = () => {
  return (
    <Box
      pl={3} pr={3}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height={1}
    >
      <Box>
        <Header
          title="Purchase confirmed. Welcome to Deep Sky!"
          description="Thank you for your payment. You have full access to Deep Sky and will be logged in immediately following this dialogue."
        />
        <br />
        <br />
        <PaymentSummary
          total="$999 USD"
          subscriptionInfo="1 Year Subscription - expires July 6, 2022"
        />
        <br />
        <br />
        <Typography variant="subtitle1">
          Your subscription is set to auto-renew on July 7, 2022. To change this
          setting go to your user profile and choose Subscription Auto renew
          OFF.
        </Typography>
      </Box>
      <Box>
        <Button fullWidth color="primary" type="submit" variant="contained">
          Log in to Deep Sky
        </Button>
      </Box>
    </Box>
  );
};

export default Summary;
