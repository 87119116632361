import { FC, useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Box,
  Divider,
  Select,
  MenuItem,
  Hidden,
} from "@material-ui/core";
import { Sentiments } from "src/types/Sentiments";
import PriceBlock from "src/components/PriceBlock";
import getFormattedScore from "src/utils/prediction/getFormattedScore";
import getForexPredictionForBlocks from "src/utils/prediction/getForexPredictionForBlocks";
import getRangePrediction from "src/utils/prediction/getRangePrediction";
import SentimentText from "src/components/SentimentText";
import ForexPrediction from "src/models/ForexPrediction";
import { useNavigate, useParams } from "react-router";

export interface DeepSkyAnalysisProps {
  className?: string;
  score?: number | null;
  scoreText?: string;
  isShortForm?: boolean;
  sentiment?: Sentiments;
  hasPriceForecastSelector?: boolean;
  periodForecast?: number[];
  predictions?: ForexPrediction[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    display: "flex",
    color: "#40434A",
  },
  container: {
    padding: theme.spacing(3, 0),
  },
  deepSkyScoreContainer: {
    display: "flex",
    alignItems: "center",
  },
  sentimentContainer: {
    paddingTop: theme.spacing(0.6),
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up(400)]: { paddingLeft: theme.spacing(0) },
  },
  periodForecastContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: "none",
    [theme.breakpoints.up("md")]: { flexDirection: "row", flex: 1 },
  },
  periodContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(700)]: {
      minWidth: "150px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(0),
    },
  },
  priceBlockContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  scoreBox: {
    border: "1px solid #40434A",
    borderRadius: "4px",
    padding: "5px",
    marginRight: "8px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  confidence: {
    color: "#707680",
  },
  divider: {
    margin: theme.spacing(0, 1.5),
    maxHeight: "85px",
    [theme.breakpoints.up("md")]: { visibility: "visible" },
  },
  select: {
    width: "135px",
    height: "22px",
    backgroundColor: "#fff",
  },
}));

const DeepSkyAnalysis: FC<DeepSkyAnalysisProps> = ({
  score = 0,
  scoreText = "",
  isShortForm = false,
  sentiment,
  hasPriceForecastSelector,
  periodForecast,
  predictions,
}) => {
  const classes = useStyles({ sentiment });
  const modelPredictions = getForexPredictionForBlocks(
    predictions,
    isShortForm
  );
  const [forecastInterval, setForecastInterval] = useState("short");
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    isShortForm ? setForecastInterval("short") : setForecastInterval("long");
  }, [isShortForm]);

  const handleChange = (e) => {
    const symbol =
      `${params["currencyFrom"]}/${params["currencyTo"]}` || "EUR/USD";
    setForecastInterval(e.target.value);
    if (e.target.value === "short") {
      navigate(`/dashboard/forex/short-briefing/${symbol}`);
    } else {
      navigate(`/dashboard/forex/briefing/${symbol}`);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h2">Deep Sky Analysis</Typography>
        </Grid>
        <Grid
          item
          className={classes.deepSkyScoreContainer}
          id="joy-ride-deepsky-analysis"
        >
          {score && (
            <Box display="flex" alignItems="center">
              <Box className={classes.scoreBox}>
                <Typography variant="h1">
                  {getFormattedScore(score ?? 0)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h4">Sky Score</Typography>
                {scoreText && (
                  <Typography variant="h4" className={classes.confidence}>
                    {scoreText}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Hidden xsDown>
          {score && (
            <Divider orientation="vertical" className={classes.divider} />
          )}
        </Hidden>
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <Box className={classes.sentimentContainer}>
            <SentimentText sentiment={sentiment} headerText="Forex Outlook" />
          </Box>
        </Grid>
        <Hidden smDown>
          {score && (
            <Divider orientation="vertical" className={classes.divider} />
          )}
        </Hidden>

        <Grid
          id="joy-ride-deepsky-forecast"
          item
          className={classes.periodForecastContainer}
        >
          {hasPriceForecastSelector ? (
            <Grid item className={classes.periodContainer}>
              <Box id="joy-ride-select-forecast">
                <Typography variant="h4">Price Forecast</Typography>
                <Select
                  className={classes.select}
                  variant="outlined"
                  labelId="price-forecast-label"
                  id="price-forecast"
                  value={forecastInterval}
                  label="Price Forecast"
                  onChange={handleChange}
                  defaultValue={"short"}
                >
                  <MenuItem value={"short"}>Short Interval</MenuItem>
                  <MenuItem value={"long"}>Long Interval</MenuItem>
                </Select>
              </Box>
            </Grid>
          ) : (
            <Box>
              <Typography variant="h4">Period Forecast</Typography>
              <Typography variant="body1">
                {periodForecast?.join(", ")}
              </Typography>
            </Box>
          )}
          <Grid item className={classes.priceBlockContainer}>
            {modelPredictions.slice(0, 5).map((item, index) => {
              return (
                <PriceBlock
                  key={item?.label}
                  label={item?.label}
                  minPrice={
                    index < 2
                      ? getRangePrediction.getMinimumValue(
                          item?.predictedCloseValue,
                          item?.mape
                        )
                      : undefined
                  }
                  maxPrice={
                    index < 2
                      ? getRangePrediction.getMaximumValue(
                          item?.predictedCloseValue,
                          item?.mape
                        )
                      : undefined
                  }
                  sentiment={item?.sentiment}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeepSkyAnalysis;
