import PropTypes from "prop-types";
import React from "react";
import { Table } from "@devexpress/dx-react-grid-material-ui";
import { IconButton, Typography, makeStyles, Badge } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  header: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const HeaderCell = ({
  column,
  children,
  selectedPriceFiltersCount,
  handleClick,
}) => {
  const isPreviousCloseColumn = column.name === "previousClose";
  const classes = useStyles();

  return (
    <>
      <Table.Cell
        style={
          isPreviousCloseColumn ? { display: "flex", alignItems: "center" } : {}
        }
      >
        {isPreviousCloseColumn && (
          <IconButton onClick={handleClick} size="medium">
            <Badge badgeContent={selectedPriceFiltersCount} color="primary">
              <FilterList />
            </Badge>
          </IconButton>
        )}
        <Typography variant="subtitle2" className={classes.header}>
          {children}
        </Typography>
      </Table.Cell>
    </>
  );
};

HeaderCell.propTypes = {
  selectedPriceFiltersCount: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  column: PropTypes.shape({
    name: PropTypes.string,
  }),
  handleClick: PropTypes.func,
};

export default HeaderCell;
