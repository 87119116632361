import { TableEditRow } from "@devexpress/dx-react-grid-material-ui";
import { MenuItem, Select } from "@material-ui/core";

const SelectCell = (props) => {
  const { name } = props?.column;
  const { value, onValueChange } = props ?? {};

  if (name === "active") {
    return (
      <TableEditRow.Cell {...props}>
        <Select
          value={value}
          onChange={(e) => onValueChange(e.target.value === "true")}
          defaultValue="false"
        >
          <MenuItem value="true">Yes</MenuItem>
          <MenuItem value="false">No</MenuItem>
        </Select>
      </TableEditRow.Cell>
    );
  }

  return <TableEditRow.Cell {...props} />;
};

export default SelectCell;
