import { removeEmpty, serializeObj } from "src/utils/object";
import { getCombineState } from "src/utils/prediction/getCombinePredictionState";
import { getFormattedScoreText } from "src/utils/prediction/getFormattedScore";
import getRangePrediction from "src/utils/prediction/getRangePrediction";
import { parsePrediction } from "src/utils/prediction/predictionParser";
interface ICombineDirection {
  supporting: number;
  conflicting: number;
  neutral: number;
}

const FOREX_SCORE_BUFFER = 100;

export default class ForexPrediction {
  readonly id?: number;
  symbol: string = "";
  predictedFor: string = "";
  predictedCloseValue: number = 0;
  mape: number = 0;
  open: number | null = null;
  close: number | null = null;
  high: number | null = null;
  low: number | null = null;
  volume: number | null = null;
  adjustedClose: number | null = null;
  previousClose: number | null = null;
  directionScore: number | null = null;
  pricePredictionScore: number | null = null;
  modelDirectionScore: number | null = null;
  modelPricePredictionScore: number | null = null;
  marketOpposeScore: number | null = null;
  marketScore: number | null = null;
  marketDirection: string = "";
  bbDirection: string = "";
  bbDirectionIndicator: string = "";
  emaDirection: string = "";
  emaDirectionIndicator: string = "";
  macdDirection: string = "";
  macdDirectionIndicator: string = "";
  rsiDirection: string = "";
  originalDirection: string = "";
  predictedDirection: string = "";
  rangePrediction?: string | null = null;
  combinedDirection: ICombineDirection = {
    supporting: 0,
    conflicting: 0,
    neutral: 0,
  };
  itemType?: string | null = null;
  type: string | null = null;
  rsiDirectionIndicator: string = "";
  smaDirection: string = "";
  smaDirectionIndicator: string = "";
  stochasticDirection: string = "";
  stochasticDirectionIndicator: string = "";
  averageScoreText: string = "";
  averageScore: number = 0;
  created_at: string = "";
  createdAt: string = "";
  updated_at: string = "";
  updatedAt: string = "";
  active?: boolean;
  description?: string;
  name?: string;
  isAccurate?: boolean;

  constructor(init?: Partial<ForexPrediction>) {
    const parsedPrediction = parsePrediction(init);
    const predictedDirection = parsedPrediction?.predictedDirection;

    this.originalDirection = parsedPrediction?.originalDirection;
    this.predictedDirection = predictedDirection;
    this.combinedDirection = getCombineState(init, predictedDirection);
    this.rangePrediction = getRangePrediction({
      predictedCloseValue: init?.predictedCloseValue,
      mape: init?.mape,
      isForex: true
    });
    this.itemType = init?.type;
    this.averageScore = (init?.directionScore ?? 0) + FOREX_SCORE_BUFFER;
    this.averageScoreText = getFormattedScoreText(this.averageScore ?? 0);
    Object.assign(this, init);
  }

  serialize() {
    return removeEmpty(serializeObj(this));
  }

  clone(): ForexPrediction {
    return new ForexPrediction(serializeObj(this));
  }
}
