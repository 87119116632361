import { useCallback } from "react";
import {
  addToWatchListAction,
  removeFromWatchListAction,
} from "src/slices/predictions";
import { RootState, useSelector } from "src/store";
import { useDispatch } from "src/store";
import Axios from "src/utils/axios";

const useWatchList = () => {
  const watchList = useSelector(
    (reduxState: RootState) => reduxState.predictions.watchList
  );

  const dispatch = useDispatch();

  const addToWatchList = useCallback(
    async (symbol) => {
      await Axios.post("/watchlist", { symbol });
      dispatch(addToWatchListAction(symbol));
    },
    [dispatch]
  );

  const removeFromWatchList = useCallback(
    async (symbol) => {
      await Axios.delete(`/watchlist/?symbol=${symbol}`);
      dispatch(removeFromWatchListAction(symbol));
    },
    [dispatch]
  );

  const isInWatchList = useCallback(
    (symbol) => watchList.data.some((item) => item.symbol === symbol),
    [watchList]
  );

  return {
    addToWatchList,
    removeFromWatchList,
    isInWatchList,
  };
};

export default useWatchList;
