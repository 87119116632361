import PriceBlock from "src/components/PriceBlock";
import { Box, makeStyles } from "@material-ui/core";
import getPredictionForBlocks from "src/utils/prediction/getPredictionForBlocks";

const useStyles = makeStyles((theme) => ({
  firstPriceBlock: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
      maxWidth: "76px",
      minWidth: "38px",
    },
  },
}));

const ModelPredictions = ({ predictions }) => {
  const classes = useStyles();
  const modelPredictions = getPredictionForBlocks(predictions);

  return (
    <Box display="flex" width={1} justifyContent="center" alignItems="center">
      {modelPredictions.map((item, index) => (
        <PriceBlock
          key={item?.label}
          label={item?.label}
          className={index === 0 ? classes.firstPriceBlock : ""}
          sentiment={item.sentiment}
        />
      ))}
    </Box>
  );
};

export default ModelPredictions;
