import {
  phoneRegEx,
  validationMessage,
  passwordRegEx
} from 'src/utils/validations';
import * as Yup from 'yup';

const validationSchema =  Yup.object().shape({
  firstName: Yup.string()
    .max(255)
    .required(validationMessage("First Name")),
  lastName: Yup.string()
    .max(255)
    .required(validationMessage("Last Name")),
  userName: Yup.string().max(255).required(validationMessage("Username")),
  email: Yup.string()
    .email("Must be a Valid Email")
    .max(255)
    .required(validationMessage("Email Address")),
  password: Yup.string()
    .required("Please enter your new password")
    .matches(
      passwordRegEx,
      "Password needs to be 8 – 14 characters with at least 1 letter, 1 number and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required("Please confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  ldapid: Yup.string().when("ldapRequired", {
    is: true,
    then: Yup.string().required(
      "LDAP is required when email has either domian bvna.com or bureauvertias.com"
    ),
  }),
  agree: Yup.boolean()
    .required()
    .oneOf([true], "You must agree to the Terms & Conditions"),
  phoneNumber: Yup.string()
    .required(validationMessage("Phone Number"))
    .matches(
      phoneRegEx,
      "The phone must have a correct USA phone format"
    ),
})

export default validationSchema