import ForexPrediction from "src/models/ForexPrediction";
import Prediction from "src/models/Prediction";
import { format } from "src/utils/date";
import getCurrentDate from "src/utils/prediction/getCurrentDate";
import getSentiment from "./getSentiment";

const getPeriodText = (index) => {
  if (index === 1) return `${format(getCurrentDate(), "MMM DD")}`;
  return `+${index}`;
};

const getPredictionForBlocks = (
  data: (Prediction | ForexPrediction)[] = []
) => {
  const currentDay = data?.[0];
  const showPeriods = [1, 2, 5, 10, 15];
  if (data.length > 0) {
    data.push(data[data.length - 1]);
  }

  return data
    ?.map((item, index) => ({
      predictedCloseValue: item?.predictedCloseValue,
      mape: item?.mape,
      id: item.id,
      period: index + 1,
      sentiment: getSentiment(
        currentDay.previousClose,
        item.predictedCloseValue
      ),
    }))
    .filter((item) => showPeriods.includes(item.period))
    .map((item) => ({ ...item, label: getPeriodText(item.period) }));
};

export default getPredictionForBlocks;
