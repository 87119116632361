import logo from 'src/assets/svgs/logo.svg';
import smallLogo from 'src/assets/svgs/small-logo.svg';
export interface LogoProps {
  [key: string]: any;
}

const Logo = (props) => {
  return <img alt='Logo' src={logo} {...props}  />;
};

const Small = (props) => {
  return <img alt='Logo' src={smallLogo} {...props}  />;
};

Logo.Small = Small;
export default Logo;
