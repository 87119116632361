import PropTypes from "prop-types";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { ChangeSet, EditingState } from "@devexpress/dx-react-grid";
import {
  Grid,
  Toolbar,
  SearchPanel,
  VirtualTable,
  PagingPanel,
  TableHeaderRow,
  TableEditRow,
  TableEditColumn,
} from "@devexpress/dx-react-grid-material-ui";
import {
  SearchState,
  DataTypeProvider,
  PagingState,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import { useCallback } from 'react';
import { FC, useEffect, useState } from "react";
import Axios from "src/utils/axios";
import debounce from "src/utils/debounce";
import EditCell from "./EditCell";
import SelectCell from "./SelectCell";

const DataProvider = ({ component, ...rest }) => {
  // @ts-ignore
  return <DataTypeProvider formatterComponent={component} {...rest} />;
};

DataProvider.propTypes = {
  component: PropTypes.shape({})
}


interface IRow {
  id: number;
  symbol: string;
  name: string;
  description: string;
  default: boolean;
  active: boolean;
  trending: boolean;
  sectorName: string;
  sectorSymbol: string;
  type: string;
  created_at: string;
  updated_at: string;
}

const columns = [
  { name: "id", title: "ID" },
  { name: "symbol", title: "Symbol", required: true },
  { name: "name", title: "Name", required: true },
  { name: "active", title: "Active" },
  { name: "sectorName", title: "Sector Name" },
  { name: "sectorSymbol", title: "Sector Symbol" },
  { name: "type", title: "Type" },
];

const validate = (rows, columns) =>
  Object.entries(rows).reduce(
    (acc, [rowId, row]: any) => ({
      ...acc,
      [rowId]: columns.some(
        (column) => column.required && row[column.name] === ""
      ),
    }),
    {}
  );

const getRowId = (row) => row.id;

const StocksTable: FC<Props> = () => {
  const [rows, setRows] = useState<IRow[]>([]);
  const [errors, setErrors] = useState({});
  const [editingStateColumnExtensions] = useState([
    { columnName: "id", editingEnabled: false },
  ]);

  const [pageSizes] = useState([25, 50, 100]);

  const getStocks = useCallback(async () => {
    await Axios.get("/stocks?all=true").then((res) => {
      const { data: stocks } = res;
      setRows(stocks);
    });
  }, []);

  useEffect(() => {
    getStocks();
  }, [getStocks]);

  const commitChanges = ({ added, changed }: ChangeSet): void => {
    if (added) {
      const body = added.map((item) => item);
      Axios.post("/stocks/admin/add", body).then(() => getStocks());
    }
    if (changed) {
      const body = {
        id: Object.keys(changed)[0],
        ...Object.values(changed)[0],
      };
      Axios.put("/stocks/admin/update", body).then(() => getStocks());
    }
  };

  const onEdited = debounce(
    (edited) => setErrors(validate(edited, columns)),
    250
  );

  return (
    <Paper>
      <Grid rows={rows} columns={columns} getRowId={getRowId}>
        <PagingState defaultCurrentPage={0} defaultPageSize={50} />
        <EditingState
          onCommitChanges={commitChanges}
          columnExtensions={editingStateColumnExtensions}
          onRowChangesChange={onEdited}
        />
        <DataProvider
          for={["active"]}
          component={({ value }) => value ? "Yes" : "No"}
        />
        <SortingState
          defaultSorting={[{ columnName: "name", direction: "desc" }]}
        />
        <IntegratedSorting />
        <SearchState defaultValue="" />
        <IntegratedFiltering />
        <IntegratedPaging />
        <VirtualTable />
        <TableHeaderRow />
        <Toolbar />
        <SearchPanel />
        <PagingPanel pageSizes={pageSizes} />
        <TableEditRow cellComponent={SelectCell} />
        <TableEditColumn
          showAddCommand
          showEditCommand
          cellComponent={(props) => <EditCell {...props} errors={errors} />}
        />
      </Grid>
    </Paper>
  );
};
const Memoiezed = React.memo(StocksTable)
export default Memoiezed;
