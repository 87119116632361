import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import colorLegendSVG from "src/assets/color-legend.svg";
import BullIcon from "src/assets/icons/Bull";
import BearIcon from "src/assets/icons/Bear";
import VerticalLegendIcon from "src/assets/icons/VerticalLegendIcon";

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #DBD7D2",
    padding: "12px",
    borderRadius: 4,
  },
  legendBar: {
    width: "100%",
    objectFit: "cover",
    height: "10px",
  },

  verticalRoot: {
    padding: "12px",
    border: "1px solid #DBD7D2",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column"
  },
  verticalRootWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const ColorLegend = () => {
  const styles = useStyles();

  return (
    <Box p={2}>
      <Box className={styles.root}>
        <Typography variant="body1" color="textPrimary">
          Color Indicators
        </Typography>
        <img
          src={colorLegendSVG}
          alt="color legend"
          className={styles.legendBar}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="caption" color="textPrimary">
            Bearish
          </Typography>
          <Typography variant="caption" color="textPrimary">
            Neutral
          </Typography>
          <Typography variant="caption" color="textPrimary">
            Bullish
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const Vertical = () => {
  const styles = useStyles();

  return (
    <Box p={2} className={styles.verticalRootWrapper}>
      <Box className={styles.verticalRoot}>
        <BullIcon height={24} width={24} style={{ marginBottom: 6 }} />
        <VerticalLegendIcon height={82} />
        <BearIcon height={24} width={24} style={{ marginTop: 6 }} />
      </Box>
    </Box>
  );
};

ColorLegend.Vertical = Vertical
export default ColorLegend;
