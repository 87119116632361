import { useRoutes } from 'react-router-dom';
import routes from 'src/routes';

const App = () => {
  const content = useRoutes(routes);

  return <>{content}</>;
};

export default App;
