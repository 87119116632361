// @ts-ignore
import React, { useState } from "react";
import { FC } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { passwordRegEx } from "src/utils/validations";

interface Props {
  [key: string]: any;
}

const ChangePasswordForm: FC<Props> = ({ onSubmit, onCancel, ...rest }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(
    false
  );

  return (
    <Formik
      {...rest}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      initialValues={{
        currentPassword: "",
        password: "",
        confirmPassword: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        currentPassword: Yup.string().required(),
        password: Yup.string()
          .required("Please enter your new password")
          .matches(
            passwordRegEx,
            "Password needs to be 8 – 14 characters with at least 1 letter, 1 number and 1 special character."
          ),
        confirmPassword: Yup.string()
          .required("Please confirm password")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <Typography variant="h3">Change password</Typography>
            <Box mt={3}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.currentPassword && errors.currentPassword)}
                    fullWidth
                    helperText={touched.currentPassword && errors.currentPassword}
                    label="Current Password"
                    name="currentPassword"
                    value={values.currentPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="New Password"
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                    fullWidth
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    label="Confirm Password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: 10 }}
                    type="submit"
                  >
                    Confirm Password
                  </Button>
                  <Button onClick={onCancel}>Cancel</Button>
                </Grid>
              </Grid>
            </Box>
          </>
        </form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
