import { Box, Typography } from "@material-ui/core";
import { Step } from "react-joyride";
import React, { FC } from "react";

export type JoyRideContentProps = {
  title: string;
  body1: string;
  body2?: string;
};

const skipButtonStyle = {
  color: "#005E87",
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: "-0.05px",
};

const JoyRideContent: FC<JoyRideContentProps> = ({ title, body1, body2 }) => {
  return (
    <Box>
      <Typography variant="h3" style={{ marginBottom: "16px" }}>
        {title}
      </Typography>
      <Typography variant="body1">{body1}</Typography>
      {body2 && (
        <Typography variant="body1" style={{ marginTop: "16px" }}>
          {body2}
        </Typography>
      )}
    </Box>
  );
};

const dashboard: Step[] = [
  {
    content: (
      <JoyRideContent
        title="Welcome to Deep Sky!"
        body1="Let's do a quick walk-through of some features that make Deep Sky so easy to use. If you prefer, you can skip this for now and jump right into using Deep Sky."
      />
    ),
    locale: {
      skip: (
        <strong aria-label="skip" style={skipButtonStyle}>
          Skip
        </strong>
      ),
    },
    placement: "center",
    target: "body",
  },
  {
    target: "#joy-ride-nav",
    content: (
      <JoyRideContent
        title="Navigation"
        body1="Here, you'll find quick access to the intelligent stock watchlist (where you are right now), forex, your personal watchlist, and your Profile settings."
      />
    ),
    placement: "right",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-nav-mobile",
    content: (
      <JoyRideContent
        title="Navigation"
        body1="Here, you'll find quick access to the intelligent stock watchlist (where you are right now), forex, your personal watchlist, and your Profile settings."
      />
    ),
    placement: "bottom",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-color-indicator",
    content: (
      <JoyRideContent
        title="Bears vs. Bulls"
        body1="Deep Sky’s predictions are represented by orange for bearish
        predictions, blue for bullish predictions, and grey for sideways or
        neutral predictions."
      />
    ),
    placement: "right",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-search",
    content: (
      <JoyRideContent
        title="Start Your Search"
        body1="Deep Sky builds predictions for over 500 of the most popular stocks and currency pairs. You can search the database by using this search box. If you can't find what you're looking for, don't worry - we're adding securities all the time!"
      />
    ),
    placement: "bottom",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-search-mobile",
    content: (
      <JoyRideContent
        title="Start Your Search"
        body1="Deep Sky builds predictions for over 500 of the most popular stocks and currency pairs. You can search the database by using this search box. If you can't find what you're looking for, don't worry - we're adding securities all the time!"
      />
    ),
    placement: "bottom",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-watchlist-card",
    content: (
      <JoyRideContent
        title="Here’s a Stock We’re Tracking"
        body1="In this card, we see the direction Deep Sky predicts the stock and sector are heading and the range Deep Sky thinks the price will fall into in the next day."
        body2='The Sky Score is a special calculation of how confident Deep Sky is in its predictions. A score above 600 is considered good. The Technical Indicator overview shows how many technical indicators align with Deep Sky&apos;s 1-day prediction (more is better).
        Click the "VIEW BRIEFING" link to open a new tab with full Deep Sky and technical analysis.
        '
      />
    ),
    placement: "right",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-market-forecast",
    content: (
      <JoyRideContent
        title="How Are the Markets?"
        body1="This section gives a quick view of the various markets and a prediction of how they will perform over the next 15 periods. This can be helpful to get an overall sense of market trends."
      />
    ),
    placement: "left",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-watchlist",
    content: (
      <JoyRideContent
        title="Add to Your Watchlist"
        body1="You can select stocks to track by clicking the star icon in the corner of the card. Any stocks you track will be added to your watchlist and will be visible on your dashboard and in your Watchlist."
      />
    ),
    placement: "top",
    disableBeacon: false,
  },
];

const briefing: Step[] = [
  {
    content: (
      <JoyRideContent
        title="Here’s Your Updated Briefing"
        body1="Welcome to your new stock briefing! We’ve redesigned the briefing to give you all the info you need quickly."
      />
    ),
    locale: {
      skip: (
        <strong aria-label="skip" style={skipButtonStyle}>
          Skip
        </strong>
      ),
    },
    placement: "center",
    target: "body",
  },
  {
    target: "#joy-ride-deepsky-analysis",
    content: (
      <JoyRideContent
        title="Outlook and Confidence"
        body1="The Sky Score is out of 1000 and indicates how confident Deep Sky is in it’s predictions. A score above 600 is considered good. Higher is better."
      />
    ),
    placement: "right",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-deepsky-forecast",
    content: (
      <JoyRideContent
        title="The Deep Sky Forecast"
        body1="The AI makes specific price predictions for the next 2 trading days, and directional predictions for 5, 10, and 15 days. The included price range for the first two periods is determined by considering the recent AI accuracy and error margin."
      />
    ),
    placement: "left",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-technical-analysis",
    content: (
      <JoyRideContent
        title="Factor in Technical Analysis"
        body1="Always look at technical indicators to see if Deep Sky predictions are being supported. The more technical indicators that fall under the Supporting column, the better the chances that what Deep Sky is predicting is likely to occur."
      />
    ),
    placement: "top",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-add-to-watchlist",
    content: (
      <JoyRideContent
        title="Track Your Favorites"
        body1="Add stocks to your Watchlist by clicking the star icon! Keep track of stocks that are performing reliably and have high Sky Scores. Refresh your Watchlist periodically with stocks that are making big moves."
      />
    ),
    placement: "left",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-market-and-sector",
    content: (
      <JoyRideContent
        title="Markets and Sectors"
        body1="Cross reference with general market and sector predictions to see if the stock you’re viewing is flowing with the current or against it. When everything lines up - that’s a good sign that it’s time to make your move."
      />
    ),
    placement: "bottom",
    disableBeacon: false,
  },
];

const forex: Step[] = [
  {
    content: (
      <JoyRideContent
        title="Forex Currency Pairs"
        body1='Major and minor currency pairs are shown in these cards. Click the "VIEW BRIEFING" link to open a new tab with full Deep Sky and technical analysis.'
      />
    ),
    locale: {
      skip: (
        <strong aria-label="skip" style={skipButtonStyle}>
          Skip
        </strong>
      ),
    },
    placement: "right",
    target: "#joy-ride-forex-card",
    disableBeacon: true,
    floaterProps: { disableAnimation: true },
  },
  {
    target: "#joy-ride-add-to-watchlist",
    content: (
      <JoyRideContent
        title="Add to Watchlist"
        body1="Just like stocks, forex currency pairs can be added to your watchlist."
      />
    ),
    placement: "right",
    disableBeacon: false,
  },
  {
    target: "#joy-ride-refresh-timer",
    content: (
      <JoyRideContent
        title="Forecast Refresh Timer"
        body1="Deep Sky artificial intelligence updates forex predictions every 15 minutes. Once the timer reaches zero, briefings and quick view cards will be refreshed with new data."
      />
    ),
    placement: "bottom",
    disableBeacon: false,
  },
];

const forexBriefing: Step[] = [
  {
    locale: {
      skip: (
        <strong aria-label="skip" style={skipButtonStyle}>
          Skip
        </strong>
      ),
    },
    target: "#joy-ride-select-forecast",
    content: (
      <JoyRideContent
        title="Select Forecast Interval"
        body1="You can select either short or long forecast intervals. The short interval has predictions ranging from 15 minutes to 4 hours. The long interval ranges from 1 to 15 days. Both the Deep Sky predictions and technical indicators will use the interval selected.
        "
      />
    ),
    placement: "bottom",
    disableBeacon: true,
    floaterProps: { disableAnimation: true },
  },
];

const dashboardSteps = dashboard;
const briefingSteps = briefing;
const forexSteps = forex;
const forexBriefingSteps = forexBriefing;

const brochureSteps = [
  briefing[0],
  dashboard[3],
  dashboard[4],
  dashboard[5],
  briefing[2],
  briefing[3],
];

export {
  dashboardSteps,
  briefingSteps,
  forexSteps,
  forexBriefingSteps,
  brochureSteps,
};
