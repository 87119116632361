import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import useTimer from "src/hooks/useTimer";

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    margin: theme.spacing(2, 0),
  },
  title: {
    textAlign: "center",
  },
  link: {
    color: "#FF8500",
    cursor: "pointer",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    color: "#fff",
    backgroundColor: theme.palette.primary.light,
    width: 100,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const TimerText = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { timer } = useTimer();

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      id="joy-ride-refresh-timer"
    >
      <Typography variant="body1">
        Forex Forecast Refresh in <b>{timer.format("m:ss")}</b>
      </Typography>
      <Typography variant="caption" style={{ lineHeight: 0.8 }}>
        <Link className={classes.link} onClick={handleClick}>
          What's this?
        </Link>
      </Typography>
      <Dialog
        className={classes.dialog}
        open={open}
        onClose={handleClick}
        maxWidth={"xs"}
      >
        <DialogTitle>
          <Typography className={classes.title} variant="h3">
            Market Refresh Countdown
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography variant="body1">
            Deep Sky updates forex predictions every 15 minutes. Once the timer
            reaches zero, the forex briefings and quick view cards will be
            refreshed with new data.
          </Typography>
          <Button className={classes.button} onClick={handleClick}>
            OK
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default TimerText;
