import { Box, Grid, Typography, Hidden, makeStyles } from "@material-ui/core";
import StockCard from "src/components/StockCard/StockCard";
import usePredictions from "src/hooks/usePredictions";
import { UseBooleanActions } from "src/hooks/useBoolean";
import Prediction from "src/models/Prediction";
import EmptyWatchList from "src/components/EmptyWatchlist";
import MarketForecastCard from "src/components/MarketForecast";
import ForexCard from "src/components/ForexCard/ForexCard";
import useForexPredictions from "src/hooks/useForexPredictions";
import mergePredictions from "src/utils/mergePredictions";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    maxWidth: "100%",
    flexBasis: "100%",

    [theme.breakpoints.up(700)]: {
      maxWidth: "50%",
      flexBasis: "50%",
    },
    [theme.breakpoints.up(1650)]: {
      maxWidth: "33.33%",
      flexBasis: "33.33%",
    },
  },
}));

interface IList {
  title: string;
  isLoading: boolean;
  data: Prediction[];
  isListViewSelected?: UseBooleanActions;

  showMoreButtonLabel?: string;
  onShowMoreClick?: () => void;
}

const marketNames = [
  "S&P 500 (SPY)",
  "DOW 30 (DJI)",
  "NASDAQ (QQQ)",
  "Mid Cap (IJH)",
  "Small Cap (IWM)",
  "SMI (SOX)",
];

const List = ({ title, isLoading, data }: IList) => {
  const { predictions } = usePredictions(data);
  const allForexPredictions = useForexPredictions();
  const { predictions: forexPredictions } = usePredictions(allForexPredictions);
  const mergedPredictions = mergePredictions(predictions, forexPredictions);

  const classes = useStyles();
  const predictionsWithLoader = isLoading
    ? new Array(6).fill(1)
    : mergedPredictions;

  return (
    <Grid container>
      <Hidden mdUp>
        <Grid container item md={3}>
          <Box width={1} mb={3}>
            <MarketForecastCard
              isLoading={isLoading}
              marketNames={marketNames}
            />
          </Box>
        </Grid>
      </Hidden>

      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          <Box
            width={1}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={1}
            minHeight="48px"
          >
            <Typography variant="h2">{title}</Typography>
          </Box>
          <>
            <Grid container item spacing={2}>
              {predictionsWithLoader.map((stockData, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  className={classes.card}
                  id={index === 0 ? "joy-ride-watchlist-card" : ""}
                >
                  {stockData.itemType === "stock" ? (
                    <StockCard stockData={stockData} isLoading={isLoading} />
                  ) : (
                    <ForexCard forexData={stockData} isLoading={isLoading} />
                  )}
                </Grid>
              ))}
            </Grid>
            {data?.length === 0 && (
              <Grid item id="joy-ride-watchlist">
                <Box mt={2}>
                  <EmptyWatchList />
                </Box>
              </Grid>
            )}
          </>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}>
            <Box width={1}>
              <MarketForecastCard
                isLoading={isLoading}
                marketNames={marketNames}
              />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default List;
