//@ts-nocheck
import type { FC, ReactNode } from "react";
import clsx from "clsx";
import {
  useLocation,
  matchPath,
  NavLink as RouterLink,
} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Fade,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Button,
} from "@material-ui/core";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import { useSections } from "./sections";
import FeedbackBox from "src/components/FeedbackBox";
import SubscribeBox from "src/components/SubscribeBox";
import ColorLegend from "src/components/ColorLegend";

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  openDesktop: boolean;
  onDesktopNavToggle: () => void;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  disabled?: boolean;
  exact?: boolean;
  access?: string;
  onClick?: () => {};
}

function renderNavItems({
  items,
  openDesktop,
  openMobile,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  openDesktop: boolean;
  openMobile: boolean;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
            openDesktop,
            openMobile,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  openDesktop,
  openMobile,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
  openDesktop: boolean;
  openMobile: boolean;
}) {
  const key = item.title + depth;

  if (item?.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        href={item?.href}
        icon={item.icon}
        key={key}
        info={item.info}
        openDesktop={openDesktop}
        open={Boolean(open)}
        title={item.title}
        exact={item.exact || false}
        onClick={item.onClick}
        newIcon={item.newIcon}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        disabled={item?.disabled}
        href={item?.href}
        icon={item.icon}
        openDesktop={openDesktop}
        openMobile={openMobile}
        key={key}
        info={item.info}
        exact={item.exact || true}
        title={item.title}
        onClick={item.onClick}
        newIcon={item.newIcon}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
    willChange: "width",
    transition: "width 0.2s 0s ease;",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  fileUploadIcon: {
    height: 60,
    width: 60,
  },
  fileUploadContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #808080",
    borderRadius: "3px",
  },
  drawerOpen: {
    width: 256,
  },
  drawerClose: {
    overflowX: "hidden",
    width: theme.spacing(10),
  },
  active: {
    color: theme.palette.secondary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.secondary.main,
    },
  },
}));

const NavBar: FC<NavBarProps> = ({
  onMobileClose,
  openMobile,
  openDesktop,
  onDesktopNavToggle,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const sections = useSections();

  // useEffect(() => {
  //   if (openMobile && onMobileClose) {
  //     onMobileClose();
  //   }
  // }, [location.pathname, onMobileClose, openMobile]);

  const OpenDesktopContent = (
    <Box height="100%" display="flex" flexDirection="column" id="leftNav">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} display="flex" justifyContent="center" className="brochure">
          <Button
            fullWidth
            size="large"
            type="submit"
            component={RouterLink}
            to={"/dashboard"}
          >
            {openDesktop ? <Logo width="100%" /> : <Logo.Small width={100} />}
          </Button>
        </Box>
        <Divider />

        <Box p={2} id="joy-ride-nav">
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                openDesktop,
                openMobile,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Fade
          id="joy-ride-color-indicator"
          in={openDesktop}
          timeout={{ enter: 800 }}
        >
          <div style={{ height: !openDesktop ? 0 : "auto" }}>
            <ColorLegend />
          </div>
        </Fade>
        {/* <Fade in={!openDesktop} timeout={{ enter: 1000 }}>
          <div>
            <ColorLegend.Vertical />
          </div>
        </Fade> */}
        {location.pathname.includes("dashboard") ? (
          <>
            <Fade in={openDesktop} timeout={{ enter: 1000 }}>
              <div>
                <FeedbackBox />
              </div>
            </Fade>
            <Fade in={!openDesktop} timeout={{ enter: 1000 }}>
              <div>
                <FeedbackBox.Mobile />
              </div>
            </Fade>
          </>
        ) : (
          <>
            <Fade in={openDesktop} timeout={{ enter: 1000 }}>
              <div>
                <SubscribeBox />
              </div>
            </Fade>
            <Fade in={!openDesktop} timeout={{ enter: 1000 }}>
              <div>
                <SubscribeBox.Mobile />
              </div>
            </Fade>
          </>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {OpenDesktopContent}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          open
          variant="permanent"
          className={clsx({
            [classes.drawerOpen]: openDesktop,
            [classes.drawerClose]: !openDesktop,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: openDesktop,
              [classes.drawerClose]: !openDesktop,
            }),
          }}
        >
          {OpenDesktopContent}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
