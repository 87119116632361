export const config = {
  APP_NAME: "Deep Sky",
  APP_SUPPORT_EMAIL:
    process.env.REACT_APP_BV_EMAIL || "support@deepskytrading.com",
  APP_SUPPORT_PHONE_NUMBER: "",
  APP_HOME: "/dashboard",
  APP_STRIPE_KEY: "pk_test_9Wu6zfEMg4wpchHr4mYOJTOC00PngFV57n",
  APP_REGISTER_URL: "https://www.joindeepsky.com/10-for-10",
  APP_BASE_API_URL: `${process.env.REACT_APP_BASE_API_URL}/api/v1/`,
  // APP_BASE_API_URL: "http://localhost:5001/v1/",
  APP_GA_MEASUREMENT_ID: "UA-164758746-1",
  APP_PUBLISH_DATE: process.env.REACT_APP_PUBLISH_DATE,
};
