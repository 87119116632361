import { combineReducers } from "@reduxjs/toolkit";
import { reducer as userReducer } from "src/slices/user";
import { reducer as stocksReducer } from "src/slices/stocks";
import { reducer as predictionsReducer } from "src/slices/predictions";

const appReducer = combineReducers({
  users: userReducer,
  stocks: stocksReducer,
  predictions: predictionsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
