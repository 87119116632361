import User from "src/models/User";
import Axios from "src/utils/axios";

type Token = {
  token: string;
}
interface IUserAuthenicationResponse {
  access: Token
  refresh: Token
}

interface IUserResponse {
  data: User
}

export const authenicate = async (email, password) => {
  return Axios.post<IUserAuthenicationResponse>('/sessions', { email, password }, { skipAuthRefresh: true }  as any)
};

export const getMyAccountInfo = async (): Promise<IUserResponse> => {
  return Axios.get('/users/me')
};
