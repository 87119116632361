import Prediction from "src/models/Prediction";

export interface IForexMarketsData {
  SPY?: Prediction[];
  DIA?: Prediction[];
  QQQ?: Prediction[];
  OIL?: Prediction[];
  GLD?: Prediction[];
  UUP?: Prediction[];
}

const getForexMarketsData = (
  marketPredictions: Prediction[]
): IForexMarketsData => {
  const uniqueSymbols = [
    ...new Set(marketPredictions.map((item) => item.symbol)),
  ];
  const result = {};

  uniqueSymbols.forEach((symbol) => {
    result[symbol] = marketPredictions.filter(
      (prediction) => prediction.symbol === symbol
    );
  });

  return result;
};

export default getForexMarketsData;
