import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

export interface DownLotsIconProps {
  [key: string]: any;
}

const DownLotsIcon: FC<DownLotsIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <path d="M8.88975 7.62435L14.0529 0.5H16.833L8.83301 11.5L0.833008 0.5H3.61316L8.88975 7.62435Z" />
      <path d="M8.88975 12.6244L14.0529 5.5H16.833L8.83301 16.5L0.833008 5.5H3.61316L8.88975 12.6244Z" />
    </SvgIcon>
  );
};

export default DownLotsIcon;
