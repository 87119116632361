import React from 'react';
import type { FC } from 'react';
import Lottie from 'src/components/Lottie';
import lottieAnimation from 'src/assets/lotties/deepsky-loader.json';

const LoadingScreen: FC<{ style?: any }> = ({ style }) => {
  return (
    <div>
      <Lottie
        data={lottieAnimation}
        style={{ width: '96px', margin: 'auto', ...style }}
      />
    </div>
  );
};

export default LoadingScreen;
