import PriceBlock from "./PriceBlock";
import getPredictionForBlocks from "src/utils/prediction/getPredictionForBlocks";

const ModelPredictionsForMarketWidget = ({ predictions, firstPriceBlock }) => {
  const modelPredictions = getPredictionForBlocks(predictions);

  return (
    <>
      {modelPredictions.map((item, index) => (
        <PriceBlock
          key={item?.label}
          date={item?.label}
          empty={index !== 0}
          className={index === 0 ? firstPriceBlock : ""}
          sentiment={item.sentiment}
        />
      ))}
    </>
  );
};

export default ModelPredictionsForMarketWidget;
