import type { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "src/contexts/AuthContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import GlobalStyles from "src/components/GlobalStyles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import { createTheme } from "src/theme";
import { THEMES } from "./constants";
import { StylesProvider } from "@material-ui/styles";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "src/utils/SnackbarUtils";
import ErrorBoundary from "./components/ErrorBoundary";

const theme = createTheme({
  theme: THEMES.LIGHT,
});

const Root: FC<Props> = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <CssBaseline />
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <GlobalStyles />
          <BrowserRouter>
            <ErrorBoundary>
              <SnackbarProvider dense maxSnack={3}>
                <SnackbarUtilsConfigurator />
                <AuthProvider>
                  {children}
                </AuthProvider>
              </SnackbarProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  );
};

export default Root;
