import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

export interface UpIconProps {
  [key: string]: any;
}

const UpIcon: FC<UpIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 17 17" {...props}>
      <path d="M8.05674 6.87565L13.2199 14H16L8 3L0 14H2.78015L8.05674 6.87565Z" />
    </SvgIcon>
  );
};

export default UpIcon;
