import type { FC } from 'react';
import Lottie from 'src/components/Lottie';
import lottieAnimation from 'src/assets/lotties/deepsky-loader.json';

export interface LoaderProps {
  [key: string]: any;
  style?: any;
}

const Loader: FC<LoaderProps> = ({ style }) => {
  return (
    <div>
      <Lottie
        data={lottieAnimation}
        style={{ width: '100px', margin: 'auto', ...style }}
      />
    </div>
  );
};

export default Loader;
