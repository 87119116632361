const Indicators = {
  sma: {
    label: "Simple Moving Averages",
    value: "sma",
    tradingViewConfig: {
      studies: [
        {
          id: "MASimple@tv-basicstudies",
          version: 60,
          inputs: {
            length: 200,
          },
        },
        {
          id: "MASimple@tv-basicstudies",
          version: 60,
          inputs: {
            length: 100,
          },
        },
      ],
    },
  },
  ema: {
    label: "Exponental Moving Averages",
    value: "ema",
    tradingViewConfig: {
      studies: [
        {
          id: "MAExp@tv-basicstudies",
          version: 60,
          inputs: {
            length: 10,
          },
        },
        {
          id: "MAExp@tv-basicstudies",
          version: 60,
          inputs: {
            length: 20,
          },
        },
        {
          id: "MAExp@tv-basicstudies",
          inputs: {
            length: 50,
          },
        },
      ],
    },
  },
  macd: {
    label: "MACD",
    value: "macd",
    tradingViewConfig: {
      studies: [
        {
          id: "MACD@tv-basicstudies",
          version: 60,
          inputs: {
            fastLength: 12,
            slowLength: 26,
            signalSmoothing: 9,
          },
        },
      ],
    },
  },
  bb: {
    label: "Bollinger Bands",
    value: "bb",
    tradingViewConfig: {
      studies: [
        {
          id: "BB@tv-basicstudies",
        },
      ],
    },
  },
  stochastic: {
    label: "Stochastic",
    value: "stochastic",
    tradingViewConfig: {
      studies: [
        {
          id: "Stochastic@tv-basicstudies",
          inputs: {
            k: 14,
            d: 3,
            smooth: 3,
          },
        },
      ],
    },
  },
  rsi: {
    label: "RSI",
    value: "rsi",
    tradingViewConfig: {
      studies: [
        {
          id: "RSI@tv-basicstudies",
          inputs: {
            length: 10,
          },
        },
      ],
    },
  },
};

export default Indicators;
