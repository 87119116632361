import { FC, useState } from "react";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";
import { HelpOutline as HelpOutlineIcon } from "@material-ui/icons";
import { Search as SearchIcon } from "@material-ui/icons";
import type { Theme } from "src/theme";
import Account from "./Account";
import Settings from "./Settings";
import Search from "./Search";
import RefreshTimer from "src/components/RefreshTimer/RefreshTimer";
import { useLocation, useNavigate } from "react-router";
interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
  onDesktopNavToggle?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 100,
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  onDesktopNavToggle,
  ...rest
}) => {
  const classes = useStyles();
  const [mobileSearch, setMobileSearch] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const toggleMobileSearch = () => {
    setMobileSearch((prev) => !prev);
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            id="joy-ride-nav-mobile"
          >
            <SvgIcon fontSize="large">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <IconButton
            color="inherit"
            onClick={onDesktopNavToggle}
            style={{ position: "relative", left: "-16px" }}
          >
            <SvgIcon fontSize="large">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Box ml={2} flexGrow={1} />

        <Hidden mdUp>
          {!mobileSearch && <RefreshTimer />}
          {mobileSearch && <Search />}
          <IconButton
            color="inherit"
            onClick={toggleMobileSearch}
            id="joy-ride-search-mobile"
          >
            <SearchIcon />
          </IconButton>
        </Hidden>
        <Hidden smDown>
          <RefreshTimer />
          <Box ml={2} />
          <Box id="joy-ride-search">
            <Search />
          </Box>
          <Box ml={2} />
          <Tooltip title="Tour">
            <IconButton
              color="inherit"
              onClick={() => navigate(`${window.location.pathname}?tour=true`)}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          {process.env.NODE_ENV !== "production" && <Settings />}
          {location.pathname.includes("dashboard") && (
            <Box ml={2}>
              <Account />
            </Box>
          )}
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
