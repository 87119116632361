import axios from "axios";
import store from "src/store";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { config } from "src/config";
import { snackActions } from "./SnackbarUtils";

const baseUrl = config.APP_BASE_API_URL;
const Axios = axios.create({ baseURL: baseUrl });

// Function that will be called to refresh authorization
const rehydrateAccessToken = (failedRequest) => {
  const body = { refreshToken: localStorage.getItem("refreshToken") };

  return new Promise((resolve, reject) => {
    axios
      .post(`${baseUrl}sessions/refresh-tokens`, body)
      .then(({ data }: any) => {
        Promise.all([
          localStorage.setItem("refreshToken", data.refresh.token),
          localStorage.setItem("accessToken", data.access.token),
        ]).then(() => {
          failedRequest.response.config.headers[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("accessToken")}`;
          resolve("Successfully rehydrateAccessToken");
        });
      })
      .catch(() => {
        console.log("session time-out");
        store.dispatch({ type: "USER_LOGOUT" });
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        snackActions.error(
          "Your session has ended. To continue working please re-log back in."
        );
        reject();
      });
  });
};

const getAccessToken = () => localStorage.getItem("accessToken");
Axios.interceptors.request.use((request) => {
  if (getAccessToken() !== null) {
    request.headers.Authorization = `Bearer ${getAccessToken()}`;
  }
  return request;
});

//@ts-ignore
Axios.CancelToken = axios.CancelToken;
//@ts-ignore
Axios.isCancel = axios.isCancel;
createAuthRefreshInterceptor(Axios, rehydrateAccessToken);
export default Axios;
