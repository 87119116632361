import { FC, ReactNode, useEffect } from "react";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

interface AdminGuardProps {
  children?: ReactNode;
}

const AdminGuard: FC<AdminGuardProps> = ({ children }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.role !== "admin") {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;
